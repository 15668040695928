import React from 'react';
import {
  GitBranchIcon,
  GitCommitIcon,
  SellIcon,
} from 'backstage-plugin-icons-react';
import { makeStyles } from '@material-ui/core';
import semver from 'semver';
import { Link } from '@backstage/core-components';

const useDeploymentAccordionGitInfoStyles = makeStyles(theme => ({
  root: {
    flex: 1.3,
    overflow: 'hidden',
  },
  commitText: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icons: {
    width: theme.typography.pxToRem(16),
    marginRight: theme.spacing(1),
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  gitText: {
    fontFamily: 'monospace',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: theme.spacing(0.5),
  },
  branchOrReleaseText: {
    color: theme.palette.text.primary,
  },
}));

interface DeploymentAccordionGitInfoProps {
  repoUrl?: string;
  branchOrTag?: string;
  commitId?: string;
  commitMessage?: string;
}

export const DeploymentAccordionGitInfo = ({
  repoUrl,
  branchOrTag,
  commitId,
  commitMessage,
}: DeploymentAccordionGitInfoProps) => {
  const classes = useDeploymentAccordionGitInfoStyles();

  const isSemver = semver.valid(branchOrTag);

  const repoBaseUrl = repoUrl?.replace('url:', '');

  return (
    <div className={classes.root}>
      <div className={classes.flexCenter}>
        {isSemver ? (
          <>
            <SellIcon className={classes.icons} />
            <Link
              to={`${repoBaseUrl}/releases/tag/${branchOrTag}`}
              className={classes.branchOrReleaseText}
            >
              <span className={classes.gitText}>{branchOrTag}</span>
            </Link>
          </>
        ) : (
          <>
            <GitBranchIcon className={classes.icons} />
            <Link
              to={`${repoBaseUrl}/tree/${branchOrTag}`}
              className={classes.branchOrReleaseText}
            >
              <span className={classes.gitText}>{branchOrTag}</span>
            </Link>
          </>
        )}
      </div>
      <div className={classes.flexCenter}>
        <GitCommitIcon className={classes.icons} />
        <Link
          to={`${repoBaseUrl}/commit/${commitId}`}
          target="_blank"
          rel="noreferrer"
        >
          <span className={classes.commitText}>
            <span className={classes.gitText}>{commitId?.slice(0, 7)}</span>
            <span>{commitMessage?.split('\n')[0]}</span>
          </span>
        </Link>
      </div>
    </div>
  );
};
