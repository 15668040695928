import React from 'react';
import { useNavigate } from 'react-router';
import BugReportIcon from '@material-ui/icons/BugReport';
import {
  ArgoIcon,
  BuildIcon,
  OpenInNewIcon,
} from 'backstage-plugin-icons-react';
import { MoreActionsVert } from '@internal/backstage-plugin-adeo-core-components-react';
import UpdateIcon from '@material-ui/icons/Update';
import { configApiRef, fetchApiRef, useApi } from '@backstage/core-plugin-api';
import { SubscriptionEntity } from '../../../../../../../../../types/SubscriptionEntity';
import { useEntityRoute } from '../../../../../../../../../hooks/useEntityRoute';
import { ManualAction } from '../../../../../../../hooks/useManualActions';
import { useSnackbarStore } from '../../../../../../../../snackbar/stores/use-snackbar-store';
import DeleteForever from '@material-ui/icons/DeleteForever';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';

export type DeploymentAccordionActionsProps = {
  productId: string;
  subscription: SubscriptionEntity;
  nonResolvedManualActions?: ManualAction[];
  resolvedManualActions?: ManualAction[];
  onInspectEntity: () => void;
  onResolveManualActions: () => void;
  onForceUpdate: () => Promise<void>;
};

const useDeploymentAccordionActionsStyles = makeStyles(theme => ({
  confirmDelete: {
    color: theme.palette.error.main,
  },
}));

export const DeploymentAccordionActions = ({
  productId,
  subscription,
  onInspectEntity,
  nonResolvedManualActions,
  resolvedManualActions,
  onResolveManualActions,
  onForceUpdate,
}: DeploymentAccordionActionsProps) => {
  const { fetch } = useApi(fetchApiRef);
  const config = useApi(configApiRef);
  const addSnackbar = useSnackbarStore(state => state.addSnackbar);
  const classes = useDeploymentAccordionActionsStyles();

  const [openUnsubscribeDialog, setOpenUnsubscribeDialog] =
    React.useState(false);

  const handleOpenUnsubscribeDialog = () => {
    setOpenUnsubscribeDialog(true);
  };

  const handleCloseUnsubscribeDialog = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation();
    setOpenUnsubscribeDialog(false);
  };

  const handleUnsubscribe = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const response = await fetch(
      `${config.getString('backend.baseUrl')}/api/custom-endpoints/subscriptions/${subscription.metadata.name}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ownerId: (subscription.spec?.owner as string)?.split('/').pop(),
        }),
      },
    );

    if (!response.ok) {
      addSnackbar(<>Failed to delete subscription.</>, 'error');
      return;
    }

    addSnackbar(
      <>Successfully deleted subscription. Refreshing page.</>,
      'success',
    );

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const navigate = useNavigate();
  const subscriptionRoute = useEntityRoute(subscription);

  const handleResolveManualActions = (event: React.MouseEvent<HTMLElement>) => {
    onResolveManualActions();
    event.stopPropagation();
    event.preventDefault();
  };

  const handleInspectEntity = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    onInspectEntity();
  };

  const goToSubscription = (event: React.MouseEvent<HTMLElement>) => {
    if (subscriptionRoute) {
      navigate(subscriptionRoute);
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const forceUpdate = async () => {
    const response = await fetch(
      `${config.getString('backend.baseUrl')}/api/custom-endpoints/subscriptions/${subscription.metadata.name}/force-update`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ownerId: (subscription.spec?.owner as string)?.split('/').pop(),
        }),
      },
    );

    if (!response.ok) {
      addSnackbar(<>Failed to force update.</>, 'error');
      return;
    }

    addSnackbar(
      <>Successfully requested force update. Refreshing deployment status.</>,
      'success',
    );
    setTimeout(() => {
      onForceUpdate();
    }, 2000);
  };

  const goToArgo = () => {
    const labels = encodeURIComponent(
      `aap.adeo.cloud/product-id=${productId},aap.adeo.cloud/entity-type=product`,
    );
    window.open(
      `${config.getString('argocd.baseUrl')}/applications?labels=${labels}`,
      '_blank',
      'noreferrer',
    );
  };

  const resolvedMenuItem =
    resolvedManualActions && resolvedManualActions.length > 0
      ? {
          Icon: BuildIcon,
          text: 'Manage manual actions',
          action: handleResolveManualActions,
        }
      : {
          Icon: BuildIcon,
          text: 'Manage manual actions',
        };

  return (
    <>
      <MoreActionsVert
        items={[
          nonResolvedManualActions && nonResolvedManualActions.length > 0
            ? {
                Icon: BuildIcon,
                text: 'Resolve manual actions',
                action: handleResolveManualActions,
              }
            : resolvedMenuItem,
          {
            Icon: BugReportIcon,
            text: 'Inspect subscription entity',
            action: handleInspectEntity,
          },
          {
            Icon: OpenInNewIcon,
            text: 'Go to subscription',
            action: goToSubscription,
          },
          {
            Icon: ArgoIcon,
            text: 'See in ArgoCD',
            action: goToArgo,
          },
          {
            Icon: UpdateIcon,
            text: 'Force update',
            action: forceUpdate,
          },
          {
            Icon: DeleteForever,
            text: 'Unsubscribe',
            action: handleOpenUnsubscribeDialog,
          },
        ]}
      />
      <Dialog
        open={openUnsubscribeDialog}
        onClose={handleCloseUnsubscribeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete subscription</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this subscription ? This action will
            remove all deployed components and resources.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseUnsubscribeDialog}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={handleUnsubscribe}
            color="primary"
            className={classes.confirmDelete}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
