import React from 'react';
import { Entity } from '@backstage/catalog-model/index';
import { useEntityRoute } from '../../../../../../hooks/useEntityRoute';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { IconButton, makeStyles } from '@material-ui/core';

import { Table } from '@internal/backstage-plugin-adeo-core-components-react';
import { ArrowRightAltIcon } from 'backstage-plugin-icons-react';
import { MetricsCellRenderer } from '../../../../../package-discovery/ListView/CellRenderer';
import { RenderBaseCell } from './RenderBaseCell';
import { useNavigate } from 'react-router';

export type PackageEntityProductsTableProps = {
  products: Entity[];
};

const NavigateProductButton = ({ product }: { product: Entity }) => {
  const productRoute = useEntityRoute(product);
  const navigate = useNavigate();
  return (
    <IconButton
      aria-label="go to product"
      color="primary"
      size="small"
      onClick={() => {
        if (productRoute) {
          navigate(productRoute);
        }
      }}
    >
      <ArrowRightAltIcon />
    </IconButton>
  );
};

const usePackageEntityProductsTableStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
  bold: {
    fontWeight: 'bold',
  },
  verticalCenter: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
}));

export const PackageEntityProductsTable = ({
  products,
}: PackageEntityProductsTableProps) => {
  const classes = usePackageEntityProductsTableStyles();

  const rows = products.map(product => ({
    id: product.metadata.uid,
    package: product.metadata.title,
    owner: product.spec?.owner ?? 'N/A',
    namespace: product.metadata.namespace,
    description: product.metadata.description ?? 'N/A',
    entity: product,
  }));

  const columns = [
    {
      field: 'package',
      headerName: 'Package',
      flex: 1.5,
      renderCell: (params: GridRenderCellParams) => (
        <RenderBaseCell value={params.value} isBold />
      ),
    },
    {
      field: 'owner',
      headerName: 'Owner',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className={classes.verticalCenter}>
          <MetricsCellRenderer entity={params.row.entity} metric="owner" />
        </div>
      ),
    },
    {
      field: 'namespace',
      headerName: 'Namespace',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <RenderBaseCell value={params.value} isBold={false} />
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 2,
      renderCell: (params: GridRenderCellParams) => (
        <RenderBaseCell value={params.value} isBold={false} />
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      type: 'actions',
      getActions: (params: GridRenderCellParams) => [
        <NavigateProductButton
          key="go-to-product"
          product={params.row.entity}
        />,
      ],
    },
  ] as GridColDef[];

  return (
    <div className={classes.root}>
      <Table
        columns={columns}
        rows={rows}
        autoHeight
        disableColumnMenu
        disableRowSelectionOnClick
        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 70}
      />
    </div>
  );
};
