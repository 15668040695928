import { ComponentManifest } from '../../../../../../../../../../../types/applied-product-manifest/AppliedProductManifest';
import { ResourceTypes } from '../../../../../../../../../../../types/applied-product-manifest/CommonComponentManifest';
import { TabContent } from '../../SectionContent';
import { useDeploymentsStore } from '../../../../store/deployments-store';
import { getComponentOrResourceAppliedManifest } from '../../manifest-utils';

const getCommonSecrets = (componentManifest: ComponentManifest) => {
  const commonSecrets = componentManifest?.secrets?.env?.fromCommonKeys;

  return commonSecrets?.map(env => env.name) ?? [];
};

type KafkaTopicSecrets = {
  [resourceName: string]: Record<string, string[]>;
};

type StandardResourceSecrets = {
  [resourceName: string]: string[];
};

export type ComponentResourcesSecrets =
  | StandardResourceSecrets
  | KafkaTopicSecrets;

/**
 * Get resources secrets for a component
 * For almost all resources types, resources secrets will be : { resourceName: ['VAR1', 'VAR2'] }
 * For kafkaTopic type, resource secrets will be : { resourceName: { cluster: ['VAR1'], registry: ['VAR2'], topic: ['VAR3'] } }
 * This is used to display different sections inside the secrets of one resource
 * Usage can be found in SectionContentWithHeaderSecretRenderer.tsx
 */
const getResourcesSecrets = (componentManifest: ComponentManifest) => {
  const resourcesSecrets = componentManifest?.secrets?.env?.fromResources;

  return resourcesSecrets
    ? (
        Object.keys(resourcesSecrets) as ResourceTypes[]
      ).reduce<ComponentResourcesSecrets>((acc, resourceFamilyKey) => {
        const resourceFamily = resourcesSecrets[resourceFamilyKey];

        if (!resourceFamily) return acc;

        Object.keys(resourceFamily).forEach(resourceName => {
          if (resourceFamilyKey === 'kafkatopics') {
            // Skip useless fields
            if (resourceName === 'cluster' || resourceName === 'schemaRegistry')
              return;

            acc[resourceName] = {
              cluster: resourceFamily.cluster?.map(secret => secret.name),
              registry: resourceFamily.schemaRegistry?.map(
                secret => secret.name,
              ),
              topic: resourceFamily[resourceName]?.map(secret => secret.name),
            };
            return;
          }

          acc[resourceName] = resourceFamily[resourceName].map(
            secret => secret.name,
          );
        });

        return acc;
      }, {})
    : {};
};

export const useComponentSecrets = ():
  | { error: Error }
  | { data: TabContent<string[] | ComponentResourcesSecrets> } => {
  const entity = useDeploymentsStore(state => state.entity);
  const subscription = useDeploymentsStore(state => state.subscription);
  const productVersion = useDeploymentsStore(state => state.productVersion);

  if (!entity || !subscription || !productVersion)
    return {
      error: new Error(
        'Could not find component secrets because either component, subscription or productVersion is missing',
      ),
    };

  const componentManifestOrError =
    getComponentOrResourceAppliedManifest<ComponentManifest>(
      entity,
      subscription,
      productVersion,
    );

  if ('error' in componentManifestOrError) return componentManifestOrError;

  return {
    data: {
      common: { data: getCommonSecrets(componentManifestOrError) },
      resources: { data: getResourcesSecrets(componentManifestOrError) },
    },
  };
};
