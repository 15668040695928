import React, { useRef, Dispatch, SetStateAction } from 'react';
import { TextField, IconButton, makeStyles } from '@material-ui/core';
import {
  type Control,
  type FieldErrors,
  Controller,
  useFormContext,
} from 'react-hook-form';
import { EditIcon, UndoIcon } from 'backstage-plugin-icons-react';
import { ManualActionInput } from './ManualActionsInput';
import { type ManualAction } from '../../../../../../../hooks/useManualActions';

type ManualActionsResolvedFieldsProps = {
  fields: ManualAction['stepTemplate']['fields'];
  control: Control;
  formErrors: FieldErrors;
  editedFields: Record<string, boolean>;
  setEditedFields: Dispatch<SetStateAction<Record<string, boolean>>>;
  disabled?: boolean;
};

const useManualActionsResolvedFieldsStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },

  field: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

export const ManualActionsResolvedFields = (
  props: ManualActionsResolvedFieldsProps,
) => {
  const {
    fields,
    control,
    editedFields,
    setEditedFields,
    formErrors,
    disabled,
  } = props;
  const classes = useManualActionsResolvedFieldsStyles();
  const inputRefs = useRef<Record<string, HTMLInputElement | null>>({});
  const { setValue } = useFormContext();

  const handleEdit = (fieldKey: string) => {
    setEditedFields({ ...editedFields, [fieldKey]: true });
    setTimeout(() => {
      inputRefs.current[fieldKey]?.focus();
    }, 0);
  };

  const handleRevert = (fieldKey: string) => {
    setEditedFields({ ...editedFields, [fieldKey]: false });
    setValue(fieldKey, '', {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  return (
    <div className={classes.root}>
      {fields.map(fieldProperty => (
        <div className={classes.field} key={fieldProperty.key}>
          {editedFields[fieldProperty.key] ? (
            <Controller
              name={fieldProperty.key}
              control={control}
              render={({ field }) => (
                <ManualActionInput
                  field={field}
                  fieldProperty={fieldProperty}
                  disabled={!editedFields[fieldProperty.key] || !!disabled}
                  errorText={formErrors[fieldProperty.key]?.message?.toString()}
                  refCallback={ref => {
                    inputRefs.current[fieldProperty.key] = ref;
                    field.ref(ref);
                  }}
                />
              )}
            />
          ) : (
            <TextField
              key={fieldProperty.key}
              label={fieldProperty.key}
              id={fieldProperty.key}
              size="small"
              value="********************************"
              variant="outlined"
              fullWidth
              disabled
            />
          )}

          {editedFields[fieldProperty.key] ? (
            <IconButton
              size="medium"
              onClick={() => handleRevert(fieldProperty.key)}
            >
              <UndoIcon />
            </IconButton>
          ) : (
            <IconButton
              size="medium"
              onClick={() => handleEdit(fieldProperty.key)}
            >
              <EditIcon />
            </IconButton>
          )}
        </div>
      ))}
    </div>
  );
};
