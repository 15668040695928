import { configApiRef, fetchApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef, useAsyncEntity } from '@backstage/plugin-catalog-react';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import {
  OLD_TANGRAM_ID_ANNOTATION,
  TANGRAM_ID_ANNOTATION,
} from 'custom-annotations';
import React, { useState } from 'react';
import { LineStartCircleIcon } from 'backstage-plugin-icons-react';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { callWithRetries } from './callWithRetries';
import { useSnackbarStore } from '../../../../../../../snackbar/stores/use-snackbar-store';

const useInitializeManifestButtonStyles = makeStyles(theme => ({
  circularProgress: {
    color: theme.palette.text.disabled,
  },
}));

export const InitializeManifestButton = () => {
  const classes = useInitializeManifestButtonStyles();

  const addSnackbar = useSnackbarStore(state => state.addSnackbar);
  const [isLoading, setIsLoading] = useState<boolean>();

  const config = useApi(configApiRef);
  const { fetch } = useApi(fetchApiRef);
  const catalogApi = useApi(catalogApiRef);

  const { entity, refresh } = useAsyncEntity();

  const onClick = async () => {
    setIsLoading(true);

    const tangramId =
      entity?.metadata.annotations?.[TANGRAM_ID_ANNOTATION] ||
      entity?.metadata.annotations?.[OLD_TANGRAM_ID_ANNOTATION];
    const name = entity?.metadata.name;
    const namespace = entity?.metadata.namespace;

    const response = await fetch(
      `${config.getString('backend.baseUrl')}/api/custom-endpoints/products/${tangramId}/init`,
      {
        method: 'POST',
        body: JSON.stringify({
          name,
          namespace,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (!response.ok) {
      const body = await response.json();

      setIsLoading(false);
      addSnackbar(
        <div>Something went wrong. {body.error.toString()}</div>,
        'error',
      );
      return;
    }

    try {
      await callWithRetries(
        async () => {
          const updatedEntity = await catalogApi.getEntityByRef(
            stringifyEntityRef(entity!),
          );

          if (
            (updatedEntity?.spec?.lifecycle as string)?.toLowerCase() ===
            'initialized'
          ) {
            return true;
          }

          throw new Error('Product is not initialized');
        },
        2000,
        3,
      );

      refresh!();

      addSnackbar(<div>Product successfully initialized.</div>, 'success');
    } catch (e) {
      addSnackbar(
        <div>
          Something went wrong. Error: Failed to initialize manifest. Try again
          later.
        </div>,
        'error',
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={
          isLoading ? (
            <CircularProgress size={20} className={classes.circularProgress} />
          ) : (
            <LineStartCircleIcon />
          )
        }
        onClick={onClick}
        disabled={isLoading || !entity}
      >
        Initialize
      </Button>
    </>
  );
};
