import { StateCreator } from 'zustand';
import { SubscriptionsSlice } from './subscriptions-slice';

const HEALTH_STATUSES = [
  'Healthy',
  'Progressing',
  'Suspended',
  'Degraded',
  'Missing',
  'Stand by',
  'Unknown',
];
const SYNC_STATUSES = [
  'Synced',
  'Syncing',
  'OutOfSync',
  'SyncFailed',
  'Stand by',
  'Unknown',
];

type State = {
  searchQuery: string;
  healthStatus: string[];
  healthStatusFilter: string[];
  syncStatus: string[];
  syncStatusFilter: string[];
  projects: string[];
  projectFilter: string[];
};

export const initialState: State = {
  searchQuery: '',
  healthStatus: HEALTH_STATUSES,
  healthStatusFilter: HEALTH_STATUSES,
  syncStatus: SYNC_STATUSES,
  syncStatusFilter: SYNC_STATUSES,
  projects: [],
  projectFilter: [],
};

type Actions = {
  setSearchQuery: (query: string) => void;
  setHealthStatusesFilter: (statuses: string[]) => void;
  setSyncStatusesFilter: (statuses: string[]) => void;
  setProjects: (projects: string[]) => void;
  setProjectFilter: (projects: string[]) => void;
  resetFilters: () => void;
};

export type DeploymentsFiltersSlice = State & Actions;

export const createDeploymentsFiltersSlice: StateCreator<
  SubscriptionsSlice & DeploymentsFiltersSlice,
  [],
  [],
  DeploymentsFiltersSlice
> = (set, get) => ({
  ...initialState,
  setSearchQuery: (query: string) => {
    set({ searchQuery: query });
    get().filterSubscriptionAndVersions();
  },
  setHealthStatusesFilter: (statuses: string[]) =>
    set({ healthStatusFilter: statuses }),
  setSyncStatusesFilter: (statuses: string[]) =>
    set({ syncStatusFilter: statuses }),
  setProjects: (projects: string[]) => {
    const uniqueProjects = Array.from(new Set(projects));

    set({ projects: uniqueProjects, projectFilter: uniqueProjects });

    get().filterSubscriptionAndVersions();
  },
  setProjectFilter: (projects: string[]) => {
    set(() => ({ projectFilter: projects }));
    get().filterSubscriptionAndVersions();
  },
  resetFilters: () => set(initialState),
});
