import {
  Box,
  CircularProgress,
  ClickAwayListener,
  debounce,
  InputAdornment,
  ListSubheader,
  makeStyles,
  Paper,
  Popper,
  TextField,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { useDelayedLoading } from '../../hooks/useDelayedLoading';
import InboxIcon from '@material-ui/icons/Inbox';
import { groupBy } from 'lodash';
import {
  Package2Icon,
  Inventory2Icon,
  ArrowRightAltIcon,
} from 'backstage-plugin-icons-react';
import { Entity } from '@backstage/catalog-model';
import { Link } from '@backstage/core-components';

const useHomePageSearchBarStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(5),
  },
  search: {
    flex: 1,
    position: 'relative',
  },
  popper: {
    width: '100%',
    marginTop: theme.spacing(0.5),
    zIndex: '999',
  },
  paper: {
    maxHeight: '300px',
    overflowY: 'auto',
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: theme.palette.text.secondary,
    padding: `${theme.spacing(1)}px 0`,
  },
  loadingState: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    padding: `${theme.spacing(1)}px 0`,
  },
  searchResultLine: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer',
    },
  },
  input: {
    background: theme.palette.background.paper,
  },
  button: {
    width: '100%',
    height: '100%',
    maxWidth: '136px',
  },
  resultList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  listSubHeader: {
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
  },
  searchIcon: {
    color: theme.palette.text.hint,
  },
}));

type SearchResultByType = {
  title: string;
  icon: React.ElementType;
  data: Entity[];
};

const SECTION_ORDER = ['product', 'package'];

export const HomePageSearchBar = () => {
  const classes = useHomePageSearchBarStyles();

  const [searchQuery, setSearchQuery] = React.useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdate = useCallback(
    debounce((value: string) => {
      setSearchQuery(value);
    }, 500),
    [],
  );

  const catalogApi = useApi(catalogApiRef);

  const { data: options, isFetching } = useQuery<
    Record<string, SearchResultByType>
  >({
    queryKey: ['searchPackageOrProduct', searchQuery],
    queryFn: async () => {
      const { items } = await catalogApi.queryEntities({
        filter: {
          ['spec.type']: ['product', 'package'],
          kind: 'Component',
        },
        limit: 100,
        orderFields: [{ field: 'metadata.title', order: 'asc' }],
        fullTextFilter: {
          term: searchQuery,
          fields: ['metadata.title'],
        },
      });

      const byType = groupBy(items, item => item.spec?.type);

      return {
        product: {
          title: 'Products',
          icon: Package2Icon,
          data: byType.product ?? [],
        },
        package: {
          title: 'Packages',
          icon: Inventory2Icon,
          data: byType.package ?? [],
        },
      };
    },
    enabled: !!searchQuery,
  });

  const loading = useDelayedLoading(isFetching, 500);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);

  const handleFocus = () => {
    setOpen(true);
  };
  const handleClickAway = () => {
    setOpen(false);
  };

  const id = open ? 'simple-popper' : undefined;

  const renderOptions = () => {
    if (loading) {
      return (
        <div className={classes.loadingState}>
          <Typography>Loading</Typography> <CircularProgress size={14} />
        </div>
      );
    }

    if (!options?.product?.data?.length && !options?.package?.data?.length) {
      return (
        <div className={classes.emptyState}>
          <InboxIcon />
          <Typography>No results</Typography>
        </div>
      );
    }

    return (
      <div>
        {SECTION_ORDER.map(type => {
          const option = options[type];
          const Icon = option?.icon;

          return option?.data?.length ? (
            <div key={type}>
              <ListSubheader className={classes.listSubHeader}>
                <Icon fontSize="small" /> {option?.title}
              </ListSubheader>
              <ul className={classes.resultList}>
                {option?.data.map(entity => (
                  <li key={entity.metadata.uid}>
                    <Link
                      to={`/catalog/${entity.metadata.namespace}/${entity.kind}/${entity.metadata.name}`}
                      className={classes.searchResultLine}
                    >
                      <Typography variant="body1">
                        {entity.metadata.title || entity.metadata.name}
                      </Typography>
                      <ArrowRightAltIcon />
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ) : null;
        })}
      </div>
    );
  };

  return (
    <Box className={classes.container}>
      <Box display="flex">
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box className={classes.search}>
            <TextField
              label="Search a package or a product"
              id="search-package-product"
              variant="outlined"
              fullWidth
              size="small"
              onChange={event => debouncedUpdate(event.target.value)}
              InputProps={{
                ref: setAnchorEl,
                className: classes.input,
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className={classes.searchIcon} />
                  </InputAdornment>
                ),
              }}
              onFocus={handleFocus}
            />
            {open && anchorEl && searchQuery && (
              <Popper
                open={open}
                id={id}
                anchorEl={anchorEl}
                disablePortal
                placement="bottom-start"
                className={classes.popper}
              >
                <Paper className={classes.paper}>{renderOptions()}</Paper>
              </Popper>
            )}
          </Box>
        </ClickAwayListener>
      </Box>
    </Box>
  );
};
