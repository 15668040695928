import { getEntityRelations } from '@backstage/plugin-catalog-react';
import { RELATION_DEPENDENCY_OF } from '@backstage/catalog-model';
import { Package2Icon } from 'backstage-plugin-icons-react';
import { type MetricsMapFunction } from './useMetrics';
import { createMetrics } from '../../utils/createMetrics';

export const getParentProductMetrics: MetricsMapFunction = (
  entity,
  relatedEntities,
  getEntityRoute,
) => {
  if (entity.spec?.type !== 'productVersion')
    return {
      metrics: [
        {
          label: 'Product',
          error: new Error(
            'You cannot display a package for a non-product-version entity',
          ),
        },
      ],
    };
  const dependencieReferencies = getEntityRelations(
    entity,
    RELATION_DEPENDENCY_OF,
    {
      kind: 'component',
    },
  );

  if (dependencieReferencies.length === 0)
    return {
      metrics: [
        {
          label: 'Product',
          error: new Error(
            `No product found for the entity ${entity.metadata.name}`,
          ),
        },
      ],
    };

  const dependencies = relatedEntities.filter(relatedEntity =>
    dependencieReferencies.some(
      dependency => dependency.name === relatedEntity.metadata.name,
    ),
  );

  const productParent = dependencies?.find(
    dependency => dependency.spec?.type === 'product',
  );

  if (!productParent)
    return {
      metrics: [
        {
          label: 'Product',
          error: new Error(
            `No product entity found for the entity ${entity.metadata.name}`,
          ),
        },
      ],
    };

  const metric = createMetrics(
    productParent.metadata.title ?? productParent.metadata.name,
    'Product',
    {
      Icon: Package2Icon,
      link: getEntityRoute(productParent),
    },
  );
  return { metrics: [metric] };
};
