import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useAsync } from 'react-use';

export const useMyGroups = () => {
  const identityApi = useApi(identityApiRef);
  const catalogApi = useApi(catalogApiRef);

  const { value: groups, loading } = useAsync(async () => {
    const { userEntityRef } = await identityApi.getBackstageIdentity();

    const { items } = await catalogApi.getEntities({
      filter: {
        kind: 'Group',
        ['relations.hasMember']: [userEntityRef],
        ['spec.type']: 'team',
      },
    });

    return items;
  });

  return { groups, loading };
};
