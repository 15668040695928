import React from 'react';
import { EntityLayout } from '../../../../entity-layout/EntityLayout';
import { ProductRefEntityOverview } from './overview/ProductRefEntityOverview';
import { ProductDeployments } from './deployments/ProductDeployments';

export const ProductRefEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <ProductRefEntityOverview />
    </EntityLayout.Route>
    <EntityLayout.Route path="/deployments" title="Deployments">
      <ProductDeployments />
    </EntityLayout.Route>
  </EntityLayout>
);
