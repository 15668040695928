import {
  Entity,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import {
  catalogApiRef,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { useEffect, useState } from 'react';
import {
  DELETED_SUBSCRIPTION_LIFECYCLE,
  SubscriptionEntity,
} from '../../../types/SubscriptionEntity';

export type SubscriptionAndVersion = {
  error?: Error;
  subscription?: SubscriptionEntity;
  productVersion?: Entity;
};

/**
 * This hook is used to get the subscriptions of a product and return them with their associated product version
 */
export const useProductSubscriptionsAndVersions = (
  product: Entity,
): SubscriptionAndVersion[] => {
  const catalogApi = useApi(catalogApiRef);

  const [subscriptionsAndVersions, setSubscriptionsAndVersions] = useState<
    SubscriptionAndVersion[]
  >([]);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      // Get a list of entities that this product is a dependency of (should include subscriptions)
      const dependencyOfRelationsRefs = getEntityRelations(
        product,
        RELATION_DEPENDENCY_OF,
      );

      // Get a list of entities that this product depends on (should include product versions)
      const dependsOnRelationsRefs = getEntityRelations(
        product,
        RELATION_DEPENDS_ON,
      );

      const { items: fetchedRelations } = await catalogApi.getEntitiesByRefs({
        entityRefs: [
          ...dependencyOfRelationsRefs,
          ...dependsOnRelationsRefs,
        ].map(stringifyEntityRef),
      });

      // Filter out any entities that are not subscriptions or product versions
      const [subscriptions, versions] = fetchedRelations.reduce(
        (acc: [Entity[], Entity[]], relationEntity) => {
          if (!relationEntity) return acc;

          const [subscriptionsEntities, versionEntities] = acc;

          if (
            relationEntity.spec?.type === 'subscription' &&
            relationEntity.spec?.lifecycle !== DELETED_SUBSCRIPTION_LIFECYCLE
          ) {
            return [
              [...subscriptionsEntities, relationEntity],
              versionEntities,
            ];
          } else if (relationEntity.spec?.type === 'productVersion') {
            return [
              subscriptionsEntities,
              [...versionEntities, relationEntity],
            ];
          }

          return acc;
        },
        [[], []],
      );

      // Map each subscription to include the version it depends on
      setSubscriptionsAndVersions(
        subscriptions.map(subscription => {
          const productVersion = versions.find(version =>
            getEntityRelations(version, RELATION_DEPENDENCY_OF)
              .map(stringifyEntityRef)
              .includes(stringifyEntityRef(subscription)),
          );

          if (!productVersion) {
            return {
              error: new Error(
                `Product version not found for subscription ${subscription.metadata.name}`,
              ),
            };
          }

          return {
            subscription: subscription as SubscriptionEntity,
            productVersion,
          };
        }),
      );
    };

    fetchSubscriptions();
  }, [product, catalogApi]);

  return subscriptionsAndVersions;
};
