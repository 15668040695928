import {
  catalogApiRef,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import {
  Entity,
  RELATION_DEPENDS_ON,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Table,
  IconText,
  getEntityIcon,
} from '@internal/backstage-plugin-adeo-core-components-react';
import { DeploymentStatus as DeploymentStatusComponent } from '../../../../../../../../common/DeploymentStatus';
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { Alert } from '@material-ui/lab';
import { Typography, Button, makeStyles } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  ARGO_APP_NAME_ANNOTATION,
  OLD_ARGO_APP_NAME_ANNOTATION,
} from 'custom-annotations';
import { DeploymentStatus } from '../useDeploymentStatus';
import { useDeploymentsStore } from '../../store/deployments-store';
import { SubscriptionEntity } from '../../../../../../../../../types/SubscriptionEntity';
import { ManualAction } from '../../../../../../../hooks/useManualActions';

const useDeploymentAccordionStyles = makeStyles(theme => ({
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1.3)}px !important`,
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingTop: theme.spacing(2),
    minWidth: 0, // table width is not responsive without this
  },
  alertMessage: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bold: {
    fontWeight: 700,
  },
}));

interface DeploymentAccordionContentProps {
  subscription: SubscriptionEntity;
  productVersion: Entity;
  statuses?: Record<string, DeploymentStatus>;
  nonResolvedManualActions?: ManualAction[];
  onResolveManualActions: () => void;
}

export const DeploymentAccordionContent = ({
  subscription,
  productVersion,
  statuses,
  nonResolvedManualActions,
  onResolveManualActions,
}: DeploymentAccordionContentProps) => {
  const classes = useDeploymentAccordionStyles();
  const openDrawer = useDeploymentsStore(state => state.openDrawer);

  const productVersionDependsOnRelationRefs = useMemo(
    () => getEntityRelations(productVersion, RELATION_DEPENDS_ON),
    [productVersion],
  );
  const [componentsAndResources, setComponentsAndResources] = useState<
    Entity[]
  >([]);

  const catalogApi = useApi(catalogApiRef);

  useEffect(() => {
    const getComponentsAndResource = async () => {
      const { items } = await catalogApi.getEntitiesByRefs({
        entityRefs: productVersionDependsOnRelationRefs.map(stringifyEntityRef),
        filter: { kind: ['Component', 'Resource'] },
      });

      setComponentsAndResources(
        items.filter((item): item is Entity => Boolean(item)),
      );
    };

    getComponentsAndResource();
  }, [productVersionDependsOnRelationRefs, catalogApi]);

  const getCellClassName = useCallback(() => classes.tableCell, [classes]);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'type',
        headerName: 'Type',
        flex: 0.4,
        valueGetter: (_value, params) => {
          return params.entity.spec.type;
        },

        renderCell: (params: GridRenderCellParams) => (
          <IconText
            Icon={getEntityIcon(params.row.entity)}
            text={params.row.entity.spec?.type}
          />
        ),

        sortComparator: (v1, v2) => {
          return v1.localeCompare(v2);
        },
      },
      { field: 'title', headerName: 'Name', flex: 0.5 },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.5,
        sortable: false,
        renderCell: params => {
          if (nonResolvedManualActions && nonResolvedManualActions.length > 0) {
            return (
              <DeploymentStatusComponent
                nonResolvedManualActions={nonResolvedManualActions}
                withoutErrorMessages
              />
            );
          }

          const annotations = params.row.entity.metadata.annotations;
          const status =
            statuses?.[annotations[ARGO_APP_NAME_ANNOTATION]] ||
            statuses?.[annotations[OLD_ARGO_APP_NAME_ANNOTATION]];

          return <DeploymentStatusComponent status={status} />;
        },
      },
      { field: 'description', headerName: 'Description', flex: 1 },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 0.2,
        type: 'actions',
        getActions: params => [
          <GridActionsCellItem
            key="see-details"
            icon={<VisibilityIcon />}
            color="primary"
            label="See details"
            onClick={() => {
              openDrawer(params.row.entity, subscription, productVersion);
            }}
          />,
        ],
      },
    ],
    [
      openDrawer,
      statuses,
      subscription,
      productVersion,
      nonResolvedManualActions,
    ],
  );

  const rows = useMemo(
    () =>
      componentsAndResources?.map(entity => ({
        id: entity.metadata.uid,
        kind: entity.kind,
        title: entity.metadata.title,
        status: 'In Progress',
        description: entity.metadata.description,
        entity,
      })) ?? [],
    [componentsAndResources],
  );

  return (
    <div className={classes.container}>
      {nonResolvedManualActions && nonResolvedManualActions.length > 0 && (
        <Alert
          severity="error"
          action={
            <Button
              color="inherit"
              size="small"
              onClick={onResolveManualActions}
            >
              Resolve
            </Button>
          }
        >
          <Typography variant="body2">
            <span className={classes.bold}>
              {nonResolvedManualActions.length === 1
                ? '1 manual action'
                : `${nonResolvedManualActions.length} manual actions`}
            </span>
            <span> required</span>
          </Typography>
        </Alert>
      )}

      <Table
        columns={columns}
        rows={rows}
        rowHeight={70}
        getCellClassName={getCellClassName}
        disableColumnSorting
        disableColumnResize
        disableColumnMenu
        disableRowSelectionOnClick
      />
    </div>
  );
};
