// https://github.com/remix-run/react-router/issues/9991#issuecomment-1550086638
// Update search params without triggering a navigation that will trigger rerender
export const safeSetSearchParams = (key: string, value: string) => {
  const currentSearchParams = new URLSearchParams(window.location.search);

  currentSearchParams.set(key, value);

  const newUrl = [window.location.pathname, currentSearchParams.toString()]
    .filter(Boolean)
    .join('?');

  window.history.replaceState(null, '', newUrl);
};

export const safeDeleteSearchParams = (toDelete: string) => {
  const currentSearchParams = new URLSearchParams(window.location.search);

  currentSearchParams.delete(toDelete);

  const newUrl = [window.location.pathname, currentSearchParams.toString()]
    .filter(Boolean)
    .join('?');

  window.history.replaceState(null, '', newUrl);
};
