import { Entity } from '@backstage/catalog-model';
import { useMetrics } from '../../../hooks/metrics/useMetrics';
import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { useSubscriptionProductVersion } from '../hooks/useSubscriptionProductVersion';
import { EntityPresentationMetricsSkeleton } from '@internal/backstage-plugin-adeo-core-components-react';

const useComponentsAndResourcesStyles = makeStyles(theme => ({
  metric: {
    lineHeight: 'initial',
    marginRight: theme.spacing(1.5),
  },
}));

const ComponentsAndResources = ({
  productVersion,
}: {
  productVersion: Entity;
}) => {
  const classes = useComponentsAndResourcesStyles();

  const { loading: loadingMetrics, metrics } = useMetrics(productVersion, [
    'components',
    'resources',
  ]);

  return loadingMetrics ? (
    <EntityPresentationMetricsSkeleton />
  ) : (
    <Box display="flex" flexDirection="row">
      {metrics.map(metric => (
        <Box
          display="flex"
          alignItems="center"
          className={classes.metric}
          key={metric.label}
        >
          <metric.Icon />
          {metric.value}
        </Box>
      ))}
    </Box>
  );
};

export const ComponentsAndResourcesCell = ({
  subscription,
}: {
  subscription: Entity;
}) => {
  const { data: productVersion } = useSubscriptionProductVersion(subscription);

  return (
    productVersion && <ComponentsAndResources productVersion={productVersion} />
  );
};
