import {
  IconButton,
  Popover,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

import MoreVert from '@material-ui/icons/MoreVert';
import React, { useState } from 'react';
import { IconComponent } from '@backstage/core-plugin-api';

type ItemAction = (e: React.MouseEvent<HTMLLIElement>) => void;

type MoreActionsVertProps = {
  items: {
    Icon: IconComponent;
    text: React.ReactNode;
    action?: ItemAction;
  }[];
};

export const MoreActionsVert = ({ items }: MoreActionsVertProps) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setPopoverOpen(!popoverOpen);
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setPopoverOpen(false);
  };

  const preventClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const itemActionWrapper: (action: ItemAction) => ItemAction =
    action => event => {
      action(event);
      handleMenuClose(event);
    };

  return (
    <div>
      <IconButton
        onClick={handleMenuClick}
        aria-label="deployment-actions-menu"
        aria-controls="deployment-actions-menu"
        aria-expanded={popoverOpen}
        aria-haspopup="true"
        ref={buttonRef}
      >
        <MoreVert />
      </IconButton>

      <Popover
        open={popoverOpen}
        onClose={handleMenuClose}
        onClick={preventClose}
        anchorEl={buttonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        aria-labelledby="deployments-long-menu"
      >
        <MenuList autoFocusItem={popoverOpen}>
          {items.map((item, index) => (
            <MenuItem
              onClick={item.action ? itemActionWrapper(item.action) : undefined}
              disabled={!item.action}
              key={index}
            >
              <ListItemIcon>
                <item.Icon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </div>
  );
};
