import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { LinksGridList } from './LinksGridList';
import { InfoCard, InfoCardVariants } from '@backstage/core-components';
import { IconComponent, useApp } from '@backstage/core-plugin-api';
import LanguageIcon from '@material-ui/icons/Language';
import { ColumnBreakpoints, RouteLinks } from './types';
import { EntityLinksEmptyState } from './EntityLinksEmptyState';

type linksCardProps = {
  title?: string;
  types?: (string | null)[];
  cols?: ColumnBreakpoints | number;
  variant?: InfoCardVariants;
};

export function LinksCard(props: linksCardProps) {
  const { entity } = useEntity();

  const resolveCardTitle = () => {
    if (props.title) {
      return { label: props.title };
    }
    return { label: 'Links' };
  };
  const titre = resolveCardTitle();

  const isFilteringLinksWithMissingType = props.types?.includes(null);

  const linksToDisplay = entity.metadata?.links?.filter(
    link =>
      (link.type && props.types?.includes(link.type)) ||
      !props.types ||
      (isFilteringLinksWithMissingType && !link.type),
  ) as RouteLinks[];

  const linksToDisplayMainFirst = linksToDisplay?.toSorted(
    (a, b) => Number(b.isMain) - Number(a.isMain),
  );

  const app = useApp();
  const iconResolver = (key?: string): IconComponent =>
    key ? (app.getSystemIcon(key) ?? LanguageIcon) : LanguageIcon;

  return (
    <InfoCard title={titre.label} variant={props.variant}>
      {!linksToDisplayMainFirst || linksToDisplayMainFirst.length === 0 ? (
        <EntityLinksEmptyState />
      ) : (
        <LinksGridList
          cols={props.cols}
          items={linksToDisplayMainFirst.map(({ url, title, icon }) => ({
            text: title ?? url,
            href: url,
            Icon: iconResolver(icon),
          }))}
        />
      )}
    </InfoCard>
  );
}
