import { Entity } from '@backstage/catalog-model';
import {
  ApiIcon,
  DatabaseIcon,
  DeployedCodeIcon,
  Inventory2Icon,
  MemoryIcon,
  Package2Icon,
  PersonIcon,
  GroupIcon,
  KafkaIcon,
} from 'backstage-plugin-icons-react';

export const getEntityIcon = (entity: Entity) => {
  switch (entity.kind) {
    case 'Component':
      switch (entity.spec?.type) {
        case 'api':
          return ApiIcon;
        case 'package':
          return Inventory2Icon;
        case 'product':
          return Package2Icon;
        default:
          return DeployedCodeIcon;
      }
    case 'Resource':
      switch (entity.spec?.type) {
        case 'kafkaTopic':
          return KafkaIcon;
        default:
          return DatabaseIcon;
      }
    case 'Group':
      return GroupIcon;
    case 'User':
      return PersonIcon;
    default:
      return MemoryIcon;
  }
};
