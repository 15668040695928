import React from 'react';
import {
  makeStyles,
  Typography,
  IconButton,
  DialogTitle,
  type DialogTitleProps,
  Chip,
} from '@material-ui/core';
import { every } from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import { ManualAction } from '../../../../../../../hooks/useManualActions';
import { SubscriptionEntity } from '../../../../../../../../../types/SubscriptionEntity';
import { SUBSCRIPTION_PROJECT_ANNOTATION } from 'custom-annotations';

type ManualActionsDialogTitleProps = {
  manualActions: ManualAction[];
  subscription: SubscriptionEntity;
  onClose: () => void;
} & DialogTitleProps;

const useManualActionsDialogTitleStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    alignItems: 'flex-start',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  subscription: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  subscriptionTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  subscriptionSubTitle: {
    color: theme.palette.text.secondary,
  },
  chip: {
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  bold: {
    fontWeight: 'bold',
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
}));

export const ManualActionsDialogTitle = (
  props: ManualActionsDialogTitleProps,
) => {
  const { manualActions, onClose, subscription, ...other } = props;
  const classes = useManualActionsDialogTitleStyles();

  const isAllResolved = every(
    manualActions,
    manualAction => manualAction.status === 'SUCCESS',
  );

  const nonResolvedManualActionsCount = manualActions.filter(
    manualAction => manualAction.status !== 'SUCCESS',
  ).length;

  const projectName =
    subscription.metadata.annotations?.[SUBSCRIPTION_PROJECT_ANNOTATION];

  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <div className={classes.subscription}>
        <Typography variant="h4">
          {isAllResolved ? 'Manage manual actions' : 'Resolve manual actions'}
        </Typography>
        <div className={classes.subscriptionTitle}>
          <Typography variant="h6">{subscription.metadata.title}</Typography>
          <Chip
            size="small"
            color="primary"
            variant="outlined"
            className={classes.chip}
            label={
              <>
                deployed in <span className={classes.bold}>{projectName}</span>
              </>
            }
          />
        </div>

        <Typography variant="body2" className={classes.subscriptionSubTitle}>
          <span className={classes.bold}>
            {nonResolvedManualActionsCount} manual actions
          </span>{' '}
          required
        </Typography>
      </div>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
        data-testid="close-dialog"
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};
