import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ComponentSecrets } from '../common/secrets/ComponentSecrets/ComponentSecrets';
import { ComponentConfig } from '../common/ComponentConfig/ComponentConfig';

const useApiDeploymentContentStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
  },
}));

export const WorkerDeploymentContent = () => {
  const classes = useApiDeploymentContentStyles();

  return (
    <div className={classes.root} data-testid="worker-drawer-content">
      <ComponentConfig />
      <ComponentSecrets />
    </div>
  );
};
