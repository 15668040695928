import React, { useMemo } from 'react';
import semver from 'semver';
import { LinkButton } from '@backstage/core-components';
import { RELATION_DEPENDS_ON, type Entity } from '@backstage/catalog-model';
import { getEntityRelations } from '@backstage/plugin-catalog-react';
import { makeStyles, Typography, IconButton } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { GridRenderCellParams, type GridColDef } from '@mui/x-data-grid';

import {
  Table,
  EmptyState,
  useThemeMode,
} from '@internal/backstage-plugin-adeo-core-components-react';
import {
  DatabaseIcon,
  DeployedCodeIcon,
  GithubIcon,
} from 'backstage-plugin-icons-react';

import { useProductVersions } from '../useProductVersions';
import { ProductVersionDrawer } from './version-drawer/ProductVersionDrawer';
import { useProductVersionDrawer } from './useProductVersionDrawer';
import { ActionButton } from './ProductRefEntityOverview';
import { ProductVersionsTableSkeleton } from './ProductVersionsTableSkeleton';
import { ProductVersionsTableRowsTitle } from './ProductVersionsTableRowsTitle';
import { ProductVersionsTableRowsChip } from './ProductVersionsTableRowsChip';
import noProductLight from '../../../../../../../assets/empty-states/no-product-light.svg';
import noProductDark from '../../../../../../../assets/empty-states/no-product-dark.svg';
import { ProductVersionFilter } from './product-version-selector-and-search/ProductVersionSelectorAndSearch';

const compareVersions = (a: string, b: string) => {
  return semver.rcompare(a, b);
};

const isSemver = (version: string) => semver.valid(version);

const groupByType = (entities: any[]) => {
  const grouped: { [key: string]: any[] } = {};
  const order: string[] = [];

  entities.forEach(entity => {
    const version =
      entity.metadata.annotations?.['aap.adeo.cloud/version-ref-name'] || '';
    const [type] = version.split('/');

    if (!grouped[type]) {
      grouped[type] = [];
      order.push(type);
    }
    grouped[type].push(entity);
  });

  return { grouped, order };
};

const sortEntities = (entities: any[]) => {
  const semverEntities = entities.filter(entity =>
    isSemver(
      entity.metadata.annotations?.['aap.adeo.cloud/version-ref-name'] || '',
    ),
  );

  const branchEntities = entities.filter(
    entity =>
      !isSemver(
        entity.metadata.annotations?.['aap.adeo.cloud/version-ref-name'] || '',
      ),
  );

  semverEntities.sort((a, b) => {
    const aVersion =
      a.metadata.annotations?.['aap.adeo.cloud/version-ref-name'] || '';
    const bVersion =
      b.metadata.annotations?.['aap.adeo.cloud/version-ref-name'] || '';
    return compareVersions(aVersion, bVersion);
  });

  const { grouped, order } = groupByType(branchEntities);
  const sortedBranchEntities = order.flatMap(type => grouped[type]);

  return [...semverEntities, ...sortedBranchEntities];
};

type ProductVersionsTableProps = {
  entity: Entity;
  filter?: ProductVersionFilter;
  searchQuery?: string;
};

const useProductVersionsTableStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
  bold: {
    fontWeight: 700,
  },

  cellIconText: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    gap: theme.spacing(1),
  },
}));

export const ProductVersionsTable = (props: ProductVersionsTableProps) => {
  const { entity, filter, searchQuery } = props;
  const theme = useThemeMode();
  const classes = useProductVersionsTableStyles();
  const { entities, loading, error } = useProductVersions(entity);

  const { onCloseProductVersionDrawer, onOpenProductVersionDrawer } =
    useProductVersionDrawer(entities ?? []);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'version',
        headerName: 'Product version',
        flex: 3,
        renderCell: (params: GridRenderCellParams) => (
          <ProductVersionsTableRowsTitle
            entity={params.row.entity}
            product={params.row.product}
          />
        ),
      },
      {
        field: 'components',
        headerName: 'Components',
        flex: 1,
        renderCell: (params: GridRenderCellParams) => (
          <Typography color="textPrimary" className={classes.cellIconText}>
            <DeployedCodeIcon fontSize="small" />
            <Typography component="span" variant="body2">
              {params.value}
            </Typography>
          </Typography>
        ),
      },
      {
        field: 'resources',
        headerName: 'Resources',
        flex: 1,
        renderCell: (params: GridRenderCellParams) => (
          <Typography color="textPrimary" className={classes.cellIconText}>
            <DatabaseIcon fontSize="small" />
            <Typography component="span" variant="body2">
              {params.value}
            </Typography>
          </Typography>
        ),
      },
      {
        field: 'deployments',
        headerName: 'Deployments',
        flex: 2,
        renderCell: (params: GridRenderCellParams) => (
          <ProductVersionsTableRowsChip entity={params.row.entity} />
        ),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 0.5,
        headerAlign: 'center',
        align: 'center',
        type: 'actions',
        getActions: params => [
          <IconButton
            key="view"
            aria-label="view product version"
            color="primary"
            size="small"
            onClick={() => {
              onOpenProductVersionDrawer(params.row.entity);
            }}
          >
            <VisibilityIcon />
          </IconButton>,
        ],
      },
    ],
    [classes, onOpenProductVersionDrawer],
  );

  const filteredSearchEntities = useMemo(() => {
    if (!entities) return [];
    if (searchQuery) {
      return entities.filter(ent =>
        ent.metadata.title?.toLowerCase().includes(searchQuery.toLowerCase()),
      );
    }
    return entities;
  }, [entities, searchQuery]);

  const filteredTypedAndsortedEntities = useMemo(() => {
    if (!filteredSearchEntities) return [];
    if (filter === 'feature-branch') {
      return sortEntities(
        filteredSearchEntities.filter(
          ent =>
            !isSemver(
              ent.metadata.annotations?.['aap.adeo.cloud/version-ref-name'] ??
                '',
            ),
        ),
      );
    }
    if (filter === 'release') {
      return sortEntities(
        filteredSearchEntities.filter(ent =>
          isSemver(
            ent.metadata.annotations?.['aap.adeo.cloud/version-ref-name'] ?? '',
          ),
        ),
      );
    }
    return sortEntities(filteredSearchEntities);
  }, [filteredSearchEntities, filter]);

  const rows = filteredTypedAndsortedEntities?.map(ent => {
    const numberOfComponents = getEntityRelations(ent, RELATION_DEPENDS_ON, {
      kind: 'Component',
    }).length;
    const numberOfResources = getEntityRelations(ent, RELATION_DEPENDS_ON, {
      kind: 'Resource',
    }).length;
    return {
      id: ent.metadata.uid,
      version: ent.metadata.title,
      components: numberOfComponents,
      resources: numberOfResources,
      entity: ent,
      product: entity,
    };
  });

  if (loading) return <ProductVersionsTableSkeleton />;

  if (error)
    return (
      <Alert severity="error">
        Oups, something went wrong when we retrieve the product versions{' '}
        {error?.message}
      </Alert>
    );

  if (rows?.length === 0) {
    if (entity.spec?.lifecycle === 'declared') {
      return (
        <EmptyState
          title="No product versions found"
          description="This product is declared but not yet initialized. To initialize the product, click the button below."
          illustration={
            <img
              src={theme === 'light' ? noProductLight : noProductDark}
              alt="No product versions found"
            />
          }
          action={<ActionButton entity={entity} />}
        />
      );
    }

    const illustration = (
      <img
        src={theme === 'light' ? noProductLight : noProductDark}
        alt="No product versions found"
      />
    );

    const sourceLocation =
      entity.metadata?.annotations?.['backstage.io/source-location']?.replace(
        'url:',
        '',
      ) ?? undefined;

    return (
      <EmptyState
        title="No product versions found"
        description="This product currently has no product versions. To create a new version, navigate to the Product Definition Repository and update the product definition. Once you've commit the changes, the new product version will appear here."
        illustration={illustration}
        action={
          <LinkButton
            variant="contained"
            color="primary"
            startIcon={<GithubIcon />}
            to={sourceLocation ?? '#'}
          >
            product definition
          </LinkButton>
        }
      />
    );
  }

  return (
    <>
      <div className={classes.root}>
        <Table
          columns={columns}
          rows={rows}
          autoHeight
          disableColumnMenu
          disableRowSelectionOnClick
          getRowHeight={() => 'auto'}
          getEstimatedRowHeight={() => 70}
        />
      </div>
      <ProductVersionDrawer
        onClose={onCloseProductVersionDrawer}
        product={entity}
      />
    </>
  );
};
