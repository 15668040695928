import {
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { stringifyEntityRef } from '@backstage/catalog-model';
import React from 'react';
import { ProductVersionAndSubscription } from '../useProductVersionAndSubscription';
import { DeployProductFormInputs } from '../DeployProductDialog';

const useProductVersionFieldStyles = makeStyles(theme => ({
  input: {
    marginTop: theme.spacing(2),
  },
}));

interface ProductVersionFieldProps {
  productVersionsAndSubscription: ProductVersionAndSubscription[];
}

export const ProductVersionField = ({
  productVersionsAndSubscription,
}: ProductVersionFieldProps) => {
  const classes = useProductVersionFieldStyles();

  const {
    control,
    formState: { errors },
  } = useFormContext<DeployProductFormInputs>();

  return (
    <FormControl
      variant="outlined"
      fullWidth
      className={classes.input}
      error={!!errors.stringifiedVersion}
    >
      <InputLabel id="version-label">
        Choose the product version to deploy
      </InputLabel>
      <Controller
        name="stringifiedVersion"
        control={control}
        render={({ field }) => (
          <>
            <Select
              labelId="version-label"
              label="Choose the product version to deploy"
              {...field}
            >
              {productVersionsAndSubscription?.map(({ productVersion }) => (
                <MenuItem
                  value={JSON.stringify(productVersion)}
                  key={stringifyEntityRef(productVersion)}
                >
                  {productVersion.metadata.title}
                </MenuItem>
              ))}
            </Select>
            {errors.stringifiedVersion && (
              <FormHelperText>
                {errors.stringifiedVersion.message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </FormControl>
  );
};
