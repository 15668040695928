import React from 'react';
import { Box, Divider, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useEntityPresentationMetricsSkeletonStyles = makeStyles(() => ({
  resetLineHeight: {
    lineHeight: 'initial',
  },
}));

export const EntityPresentationMetricsSkeleton = () => {
  const classes = useEntityPresentationMetricsSkeletonStyles();

  return (
    <Box
      display="flex"
      data-testid="metrics-skeleton"
      className={classes.resetLineHeight}
    >
      <Box>
        <Skeleton width={40} />
        <Skeleton width={88} />
      </Box>
      <Divider orientation="vertical" style={{ margin: '0 1rem' }} />
      <Box>
        <Skeleton width={60} />
        <Skeleton width={25} />
      </Box>
    </Box>
  );
};
