import React, { forwardRef, useContext, useMemo } from 'react';
import { IconComponent } from '@backstage/core-plugin-api';
import { Link } from '@backstage/core-components';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { EntityPresentationContext } from '../EntityPresentationContext';
import { Tooltip } from '../../Tooltip';

/**
 * Metrics type for {@link EntityPresentationMetricsItem}.
 *
 * @public
 */
export type Metric =
  | {
      label: string;
      value: string;
      Icon?: IconComponent;
      link?: string | null;
      tooltipContent?: string | React.ReactNode;
    }
  | {
      label: string;
      error: Error;
    };

/**
 * Props for {@link EntityPresentationMetricsItem}.
 *
 * @public
 */

export type EntityPresentationMetricsItemProps = {
  hideLabel?: boolean;
  overflowEllipsis?: boolean;
} & Metric &
  React.HTMLAttributes<HTMLDivElement>;

const useEntityPresentationMetricsItemStyles = makeStyles<
  Theme,
  { overflowEllipsis: boolean }
>(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    overflow: ({ overflowEllipsis }) => (overflowEllipsis ? 'hidden' : 'unset'),
  },
  contentValue: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  label: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: 0.5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  labelSmall: {
    fontSize: '10px',
  },
  labelMedium: {
    fontSize: '12px',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
  },
  value: {
    whiteSpace: props => (props.overflowEllipsis ? 'nowrap' : 'unset'),
    overflow: props => (props.overflowEllipsis ? 'hidden' : 'unset'),
    textOverflow: props => (props.overflowEllipsis ? 'ellipsis' : 'unset'),
  },
  tooltipLink: {
    textDecoration: 'underline',
  },
}));

/**
 * Displays a metric for an entity presentation.
 *
 * @remarks
 *
 * This component displays a metric with a label and a value. this component can also display an
 * icon. If a link is provided, the value will be wrapped in a {@link Link}. This component is
 * intended to be used within an {@link EntityPresentationMetrics}.
 *
 * @public
 */
export const EntityPresentationMetricsItem = forwardRef<
  HTMLDivElement,
  EntityPresentationMetricsItemProps
>((props, ref) => {
  const { size } = useContext(EntityPresentationContext);
  const classes = useEntityPresentationMetricsItemStyles({
    overflowEllipsis: !!props.overflowEllipsis,
  });

  const labelClasses = useMemo(() => {
    return `${classes.label} ${size === 'small' ? classes.labelSmall : classes.labelMedium}`;
  }, [classes, size]);

  if ('error' in props) {
    return (
      <div ref={ref} className={classes.root} {...props}>
        {!props.hideLabel && (
          <Typography className={labelClasses}>{props.label}</Typography>
        )}
        <Typography color="error" className={classes.value}>
          {props.error.message}
        </Typography>
      </div>
    );
  }

  const { label, value, Icon, link, hideLabel, tooltipContent, ...restProps } =
    props;

  const contentValue = (
    <div className={classes.contentValue}>
      {Icon && (
        <div className={classes.icon}>
          <Icon fontSize="inherit" />
        </div>
      )}
      <Typography
        variant={size === 'small' ? 'body2' : 'body1'}
        className={classes.value}
      >
        {value}
      </Typography>
      {tooltipContent && (
        <Tooltip
          title={tooltipContent}
          interactive
          arrow
          className={classes.tooltipLink}
        >
          <div className={classes.icon}>
            <HelpOutlineIcon fontSize="inherit" data-testid="help-icon" />
          </div>
        </Tooltip>
      )}
    </div>
  );

  return (
    <div ref={ref} className={classes.root} {...restProps}>
      {!hideLabel && <Typography className={labelClasses}>{label}</Typography>}
      {link ? <Link to={link}>{contentValue}</Link> : contentValue}
    </div>
  );
});

EntityPresentationMetricsItem.displayName = 'EntityPresentationMetricsItem';
