import { Page, Content, HelpIcon } from '@backstage/core-components';
import { HomePageSearchBar } from './HomePageSearchBar';
import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { usePianoSendEvent } from '../../hooks/usePianoSendEvent';
import { useUserInfo } from '../../utils/useUserInfo';
import {
  HOME_PAGE_GETTING_STARTED_KEY,
  HOME_PAGE_HELP_DRAWER_WIDTH,
  HomePageGettingStarted,
} from './HomePageGettingStarted';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import { HomePageOwnershipOverview } from './ownership-overview/HomePageOwnershipOverview';
// import { SubscriptionsOverview } from './SubscriptionsOverview';
import { DeploymentsOverview } from './deployments-overview/DeploymentsOverview';

const useHomePageStyles = makeStyles(theme => ({
  welcomeText: {
    color: theme.palette.text.secondary,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -HOME_PAGE_HELP_DRAWER_WIDTH,
    overflow: 'hidden',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  contentContainer: {
    display: 'flex',
  },
  welcomeContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const HomePage = () => {
  const classes = useHomePageStyles();
  usePianoSendEvent('page.display', 'home');

  const { value: userInfo } = useUserInfo();

  const [isDrawerOpen, setIsDrawerOpen] = useState(
    JSON.parse(localStorage.getItem(HOME_PAGE_GETTING_STARTED_KEY) || 'true'),
  );

  const onOpenDrawer = () => {
    localStorage.setItem(HOME_PAGE_GETTING_STARTED_KEY, 'true');
    setIsDrawerOpen(true);
  };

  return (
    <Page themeId="home">
      <Content className={classes.contentContainer}>
        <Box
          className={clsx(classes.content, {
            [classes.contentShift]: isDrawerOpen,
          })}
        >
          <Typography variant="h3">Hello, {userInfo?.displayName}</Typography>
          <div className={classes.welcomeContainer}>
            <Typography variant="body1" className={classes.welcomeText}>
              Welcome to the Adeo Application Platform.
            </Typography>
            <IconButton onClick={onOpenDrawer} size="small">
              <HelpIcon />
            </IconButton>
          </div>
          <HomePageSearchBar />
          <HomePageOwnershipOverview />
          {/* <SubscriptionsOverview/>*/}
          <DeploymentsOverview />
        </Box>
        <HomePageGettingStarted
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        />
      </Content>
    </Page>
  );
};
