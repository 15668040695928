import { makeStyles, Typography } from '@material-ui/core';
import React, { PropsWithChildren, ReactElement } from 'react';
import { EmptyState } from '@internal/backstage-plugin-adeo-core-components-react';

const useOwnershipSectionStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    gap: theme.spacing(2),
    width: '100%',
    marginTop: theme.spacing(2),
  },
  section: {
    padding: theme.spacing(2),
    flex: 1,
  },
  sectionPart: {
    display: 'flex',
  },
  sectionCaption: {
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },
  sectionContent: {
    marginLeft: theme.spacing(2),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface OwnershipSectionProps {
  illustration: ReactElement<SVGElement>;
  title: string;
}

export const OwnershipSection = ({
  illustration,
  title,
  children,
}: PropsWithChildren<OwnershipSectionProps>) => {
  const classes = useOwnershipSectionStyles();

  return (
    <div className={classes.sectionPart}>
      <EmptyState
        illustration={illustration}
        illustrationWidth={80}
        options={{ noMargin: true }}
      />
      <div className={classes.sectionContent}>
        <Typography variant="caption" className={classes.sectionCaption}>
          {title}
        </Typography>
        {children}
      </div>
    </div>
  );
};
