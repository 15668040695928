import { Button, DialogActions, makeStyles } from '@material-ui/core';
import React from 'react';

const useDeployProductDialogActionsStyles = makeStyles(theme => ({
  submitButton: {
    textTransform: 'uppercase',
  },
  actions: {
    padding: `${theme.spacing(3)}px`,
  },
}));

interface DeployProductDialogActionsProps {
  onCancel: () => void;
  disableSubmit: boolean;
  submitButtonIcon: React.ReactNode;
  submitButtonText: string;
}

export const DeployProductDialogActions = ({
  onCancel,
  disableSubmit,
  submitButtonIcon,
  submitButtonText,
}: DeployProductDialogActionsProps) => {
  const classes = useDeployProductDialogActionsStyles();

  return (
    <DialogActions className={classes.actions}>
      <Button onClick={onCancel} color="primary">
        Cancel
      </Button>
      <Button
        color="primary"
        variant="contained"
        type="submit"
        className={classes.submitButton}
        disabled={disableSubmit}
        startIcon={submitButtonIcon}
      >
        {submitButtonText}
      </Button>
    </DialogActions>
  );
};
