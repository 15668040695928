import { Drawer, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from '@backstage/core-components';

export const HOME_PAGE_HELP_DRAWER_WIDTH = 400;

export const HOME_PAGE_GETTING_STARTED_KEY = 'showGettingStarted';

const useHomePageHelpStyles = makeStyles(theme => ({
  drawer: {
    width: HOME_PAGE_HELP_DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: HOME_PAGE_HELP_DRAWER_WIDTH,
    padding: `${theme.spacing(5)}px ${theme.spacing(3)}px`,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  closeButton: {
    alignSelf: 'flex-start',
    margin: 'auto',
  },
  header: {
    display: 'flex',
  },
  section: {
    marginTop: theme.spacing(5),
  },
  link: {
    textDecoration: 'underline',
    fontSize: theme.typography.pxToRem(16),
  },
}));

const data = [
  {
    title: 'Developer documentation',
    description:
      'Your one-stop shop for technical documentation, API references, and code examples.',
    link: 'https://docs.packaging.adeo.cloud/latest/developers/overview/',
  },
  {
    title: 'Quickstart Guides',
    description:
      'Learn how to quickly set up and deploy your first application.',
    link: 'https://docs.packaging.adeo.cloud/latest/developers/fundamentals/quickstart/',
  },
  {
    title: 'Troubleshooting Guide',
    description: 'Find solutions to common issues and errors.',
    link: '',
  },
  {
    title: 'Support Tickets',
    description: 'Submit a support ticket for urgent issues.',
    link: '',
  },
];

interface HomePageHelpProps {
  open: boolean;
  onClose: () => void;
}

export const HomePageGettingStarted = ({
  open,
  onClose,
}: HomePageHelpProps) => {
  const classes = useHomePageHelpStyles();

  const closeDrawer = () => {
    localStorage.setItem(HOME_PAGE_GETTING_STARTED_KEY, 'false');
    onClose();
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.header}>
        <Typography variant="h5">Get Started with Our Platform</Typography>
        <IconButton
          onClick={closeDrawer}
          className={classes.closeButton}
          data-testid="close-getting-started"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <Typography variant="body2">
        Need help getting started? Our comprehensive documentation will guide
        you through every step.
      </Typography>
      {data.map(item => (
        <div className={classes.section} key={item.title}>
          <Link to={item.link} className={classes.link}>
            {item.title}
          </Link>
          <Typography variant="body2">{item.description}</Typography>
        </div>
      ))}
    </Drawer>
  );
};
