import { Divider, makeStyles } from '@material-ui/core';
import React from 'react';
import { MyPackagesSection } from './MyPackagesSection';
import { MyProductsSection } from './MyProductsSection';
import { MySubscriptionsSection } from './MySubscriptionsSection';
import { MyDeploymentsSection } from './MyDeploymentsSection';

const useHomePageOwnershipOverviewStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    gap: theme.spacing(2),
    width: '100%',
    marginTop: theme.spacing(2),
  },
  section: {
    background: theme.palette.background.paper,
    padding: theme.spacing(2),
    flex: 1,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export const HomePageOwnershipOverview = () => {
  const classes = useHomePageOwnershipOverviewStyles();

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <MyPackagesSection />
        <Divider className={classes.divider} />
        <MySubscriptionsSection />
      </div>
      <div className={classes.section}>
        <MyProductsSection />
        <Divider className={classes.divider} />
        <MyDeploymentsSection />
      </div>
    </div>
  );
};
