import { useApi, identityApiRef } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';

export const useUserInfo = () => {
  const userApi = useApi(identityApiRef);

  return useAsync(async () => {
    const [backstageEntity, credential, profileInfo] = await Promise.all([
      userApi.getBackstageIdentity(),
      userApi.getCredentials(),
      userApi.getProfileInfo(),
    ]);

    return {
      ownershipEntityRefs: backstageEntity.ownershipEntityRefs,
      type: backstageEntity.ownershipEntityRefs,
      userEntityRef: backstageEntity.userEntityRef,
      ldap: backstageEntity.userEntityRef.split('/')[1] ?? 'ldapError',
      displayName: profileInfo.displayName,
      email: profileInfo.email,
      token: credential.token,
    };
  });
};
