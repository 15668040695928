import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  Paper,
  Popper,
} from '@material-ui/core';
import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { SOURCE_CODE_URL_ANNOTATION } from 'custom-annotations';
import { DisabledDeployTooltipText } from './DisabledDeployTooltipText';
import { isProductReady } from '../../../utils';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuList from '@material-ui/core/MenuList';
import { DeployProductDialog } from '../../deploy-product-modal/DeployProductDialog';
import { useProductVersions } from '../../../useProductVersions';
import { Tooltip } from '@internal/backstage-plugin-adeo-core-components-react';

interface DeployButtonProps {
  disableDeploy: boolean;
}

const options = ['Deploy in development'];

const DeployButton = ({ disableDeploy }: DeployButtonProps) => {
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement | null>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = () => {
    setOpenModal(true);
  };

  const handleMenuItemClick = (
    _: React.MouseEvent<HTMLLIElement>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="deployment button group"
        disabled={disableDeploy}
      >
        <Button onClick={handleClick} aria-label="deploy">
          {options[selectedIndex]}
        </Button>
        <Button
          color="primary"
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select targeted project"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={event => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <DeployProductDialog
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
};

export const DeployProductButton = () => {
  const { entity } = useEntity();
  const manifestUrl = entity.metadata.annotations?.[
    SOURCE_CODE_URL_ANNOTATION
  ]?.replace('url:', '');

  const { entities, loading, error } = useProductVersions(entity);

  const disableDeploy =
    !isProductReady(entity) || loading || !!error || !entities?.length;

  return disableDeploy ? (
    <Tooltip
      title={<DisabledDeployTooltipText manifestUrl={manifestUrl} />}
      interactive
      arrow
    >
      <div>
        <DeployButton disableDeploy={disableDeploy} />
      </div>
    </Tooltip>
  ) : (
    <DeployButton disableDeploy={disableDeploy} />
  );
};
