import React from 'react';
import {
  DialogContent,
  makeStyles,
  type DialogContentProps,
} from '@material-ui/core';

import { type ManualAction } from '../../../../../../../hooks/useManualActions';
import { ManualActionsDialogItem } from './ManualActionsDialogItem';

const useManualActionsDialogContentStyles = makeStyles(theme => ({
  manualActionsContainer: {
    flex: 1,
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

type ManualActionsDialogContentProps = {
  manualActions: ManualAction[];
} & DialogContentProps;

export const ManualActionsDialogContent = (
  props: ManualActionsDialogContentProps,
) => {
  const { manualActions, ...other } = props;
  const classes = useManualActionsDialogContentStyles();

  return (
    <DialogContent {...other}>
      <div className={classes.manualActionsContainer}>
        {manualActions.map((manualAction, index) => (
          <ManualActionsDialogItem
            key={manualAction.id}
            index={index}
            manualAction={manualAction}
            isLast={index === manualActions.length - 1}
          />
        ))}
      </div>
    </DialogContent>
  );
};
