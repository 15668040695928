import React from 'react';
import {
  IconButton,
  InputAdornment,
  TextField,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { GridViewIcon, ListViewIcon } from 'backstage-plugin-icons-react';
import { type ViewMode } from '../../hooks/useViewMode';

type ResourceSearchAndViewSwitcherProps = {
  resourceType: string;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  viewMode: ViewMode;
  setViewMode: (view: ViewMode) => void;
};

const useResourceSearchAndViewSwitcherStyles = makeStyles<
  Theme,
  { viewMode: ViewMode }
>(theme => ({
  searchContainer: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  searchFormControl: {
    flex: 1,
  },
  searchInput: {
    background: theme.palette.background.paper,
  },
  searchIcon: {
    color: theme.palette.text.hint,
  },
  switchViewContainer: {
    background: theme.palette.background.paper,
    display: 'flex',
  },
  switchViewButton: {
    borderRadius: 0,
    borderColor: `${theme.palette.divider} !important`,
    borderStyle: 'solid !important',
    '&:first-child': {
      backgroundColor: props =>
        props.viewMode === 'grid' ? theme.palette.action.selected : `initial`,
      borderWidth: '1px',
      borderTopLeftRadius: theme.spacing(0.5),
      borderBottomLeftRadius: theme.spacing(0.5),
    },
    '&:last-child': {
      backgroundColor: props =>
        props.viewMode === 'list' ? theme.palette.action.selected : `initial`,
      borderWidth: '1px 1px 1px 0',
      borderTopRightRadius: theme.spacing(0.5),
      borderBottomRightRadius: theme.spacing(0.5),
    },
  },
}));

const ResourceSearchAndViewSwitcher = ({
  resourceType,
  searchQuery,
  setSearchQuery,
  viewMode,
  setViewMode,
}: ResourceSearchAndViewSwitcherProps) => {
  const classes = useResourceSearchAndViewSwitcherStyles({ viewMode });

  return (
    <div className={classes.searchContainer}>
      <div className={classes.switchViewContainer}>
        <IconButton
          className={classes.switchViewButton}
          onClick={() => setViewMode('grid')}
          data-testid="grid-view-button"
        >
          <GridViewIcon />
        </IconButton>
        <IconButton
          className={classes.switchViewButton}
          onClick={() => setViewMode('list')}
          data-testid="list-view-button"
        >
          <ListViewIcon />
        </IconButton>
      </div>
      <TextField
        variant="outlined"
        placeholder={`Search a ${resourceType}`}
        className={classes.searchFormControl}
        value={searchQuery}
        InputProps={{
          className: classes.searchInput,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon className={classes.searchIcon} />
            </InputAdornment>
          ),
        }}
        onChange={event => setSearchQuery(event.target.value)}
      />
    </div>
  );
};

export default ResourceSearchAndViewSwitcher;
