import { UseQueryResult } from '@tanstack/react-query';
import { Entity } from '@backstage/catalog-model';
import { useMyDeploymentsStatuses } from './useMyDeploymentsStatuses';

type DeploymentsStatusesCount = {
  data: Record<string, number>;
  isLoading: boolean;
};

const aggregateResults = (
  results: UseQueryResult<Record<string, { health: string }>, Error>[],
  deploymentIds: string[],
) => {
  let count = 0;

  const aggregatedResults = results.reduce<DeploymentsStatusesCount>(
    (acc, current) => {
      const data = current.data as Record<string, { health: string }>;
      const isLoading = current.isLoading;

      if (isLoading) return { ...acc, isLoading: true };

      if (!data) {
        return acc;
      }

      for (const [key, value] of Object.entries(data)) {
        if (deploymentIds.includes(key)) {
          acc.data[value.health] = (acc.data[value.health] || 0) + 1;
          count++;
        }
      }

      return {
        ...acc,
        isLoading: acc.isLoading || isLoading,
      };
    },
    { data: {}, isLoading: false },
  );

  return {
    count,
    aggregatedResults,
  };
};

export const useMyDeploymentsStatusesCount = (deployments?: Entity[]) => {
  return useMyDeploymentsStatuses<DeploymentsStatusesCount>(
    deployments,
    results => {
      const deploymentIds =
        deployments?.map(deployment => deployment.metadata.name) ?? [];

      const { aggregatedResults, count } = aggregateResults(
        results,
        deploymentIds,
      );

      return {
        ...aggregatedResults,
        data: {
          ...aggregatedResults.data,
          ...(count !== deploymentIds.length
            ? { Unknown: deploymentIds.length - count }
            : {}),
        },
      };
    },
  );
};
