import * as ScrollAreaComponent from '@radix-ui/react-scroll-area';
import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

interface ScrollAreaProps {
  childrenBackgroundTone: 'light' | 'dark';
}

const useScrollAreaStyle = makeStyles<Theme, ScrollAreaProps>(theme => ({
  scrollAreaRoot: {
    overflow: 'hidden',
    '--scrollbar-size': '10px',
  },
  scrollAreaViewport: {
    width: '100%',
    height: '100%',
  },
  scrollAreaScrollbar: {
    display: 'flex',
    /* ensures no selection */
    userSelect: 'none',
    /* disable browser handling of all panning and zooming gestures on touch devices */
    touchAction: 'none',
    padding: '2px',
    transition: 'background 160ms ease-out',
    "&[data-orientation='vertical']": {
      width: 'var(--scrollbar-size)',
    },
  },
  scrollAreaThumb: {
    flex: 1,
    borderRadius: 'var(--scrollbar-size)',
    position: 'relative',
    background: ({ childrenBackgroundTone }) =>
      childrenBackgroundTone === 'light'
        ? theme.palette.divider
        : 'rgba(255, 255, 255, .4)',
  },
}));

export const ScrollArea = ({
  children,
  childrenBackgroundTone,
}: PropsWithChildren<{
  childrenBackgroundTone: 'light' | 'dark';
}>) => {
  const classes = useScrollAreaStyle({ childrenBackgroundTone });

  return (
    <ScrollAreaComponent.Root className={classes.scrollAreaRoot} type="scroll">
      <ScrollAreaComponent.Viewport className={classes.scrollAreaViewport}>
        {children}
      </ScrollAreaComponent.Viewport>
      <ScrollAreaComponent.Scrollbar
        className={classes.scrollAreaScrollbar}
        orientation="vertical"
      >
        <ScrollAreaComponent.Thumb className={classes.scrollAreaThumb} />
      </ScrollAreaComponent.Scrollbar>
      <ScrollAreaComponent.Corner />
    </ScrollAreaComponent.Root>
  );
};
