import {
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { stringifyEntityRef } from '@backstage/catalog-model';
import React from 'react';
import { useMyGroups } from '../../../../../../../../../hooks/useMyGroups';
import { DeployProductFormInputs } from '../DeployProductDialog';
import clsx from 'clsx';

const useOwnerFieldStyles = makeStyles(theme => ({
  input: {
    marginTop: theme.spacing(2),
  },
}));

interface OwnerFieldProps {
  existingDeploymentOnDev?: boolean;
}

export const OwnerField = ({ existingDeploymentOnDev }: OwnerFieldProps) => {
  const classes = useOwnerFieldStyles();

  const {
    control,
    formState: { errors },
  } = useFormContext<DeployProductFormInputs>();

  const { groups } = useMyGroups();

  return (
    <FormControl
      variant="outlined"
      fullWidth
      className={classes.input}
      error={!!errors.ownerRef}
    >
      <InputLabel
        id="owner-label"
        className={clsx(!!existingDeploymentOnDev && 'Mui-disabled')}
      >
        Choose the team responsible for the deployment
      </InputLabel>
      <Controller
        name="ownerRef"
        control={control}
        render={({ field }) => (
          <>
            <Select
              labelId="owner-label"
              label="Choose the team responsible for the deployment"
              readOnly={!!existingDeploymentOnDev}
              className={clsx(!!existingDeploymentOnDev && 'Mui-disabled')}
              {...field}
            >
              {groups?.map(group => (
                <MenuItem
                  value={stringifyEntityRef(group)}
                  key={stringifyEntityRef(group)}
                >
                  {
                    (group.spec?.profile as { displayName: string })
                      ?.displayName
                  }
                </MenuItem>
              ))}
            </Select>
            {errors.ownerRef && (
              <FormHelperText>{errors.ownerRef.message}</FormHelperText>
            )}
          </>
        )}
      />
    </FormControl>
  );
};
