import React from 'react';
import { EntitySwitch, isKind } from '@backstage/plugin-catalog';
import { DefaultEntityPage } from './pages/DefaultEntityPage';
import { ComponentPage } from './pages/component/ComponentPage';
import { ApiPage } from './pages/ApiPage';
import { DomainPage } from './pages/DomainPage';
import { SystemPage } from './pages/SystemPage';
import { GroupPage } from './pages/GroupPage';
import { UserPage } from './pages/UserPage';
import { ResourcePage } from './pages/resource/ResourcePage';

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')}>
      {ComponentPage}
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('api')}>{ApiPage}</EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('group')}>{GroupPage}</EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('user')}>{UserPage}</EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('system')}>{SystemPage}</EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('domain')}>{DomainPage}</EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('resource')}>
      {ResourcePage}
    </EntitySwitch.Case>
    <EntitySwitch.Case>{DefaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
