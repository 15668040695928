import { useEffect, useState } from 'react';

/**
 * A hook that delays the loading state for a specified amount of time.
 * This is useful for preventing loading spinners from flashing on the screen
 *
 * @param loading - The loading state to be delayed.
 * @param delayedLoadingTime - The time to delay the loading state for.
 */
export const useDelayedLoading = (
  loading: boolean,
  delayedLoadingTime: number = 200,
) => {
  const [delayedLoading, setDelayedLoading] = useState<boolean>(loading);

  useEffect(() => {
    if (loading) {
      setDelayedLoading(true);
      setTimeout(() => setDelayedLoading(false), delayedLoadingTime);
    }
  }, [loading, delayedLoadingTime]);

  return loading ? true : delayedLoading;
};
