import {
  makeStyles,
  Snackbar,
  SnackbarContent,
  type SnackbarProps,
} from '@material-ui/core';

import React from 'react';

const useCustomSnackbarStyles = makeStyles(theme => ({
  snackbar: {
    transition: 'all 0.25s',
  },

  snackbarContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    maxWidth: '600px',
    '& > :first-child': {
      flex: 1,
    },
  },

  default: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },

  error: {
    backgroundColor: theme.palette.error.main,
  },

  info: {
    backgroundColor: theme.palette.info.main,
  },

  success: {
    backgroundColor: theme.palette.success.main,
  },

  warning: {
    backgroundColor: theme.palette.warning.main,
  },
}));

export type CustomSnackbarProps = {
  severity?: 'default' | 'error' | 'info' | 'success' | 'warning';
  message: React.ReactNode;
} & SnackbarProps;

export const CustomSnackbar = ({
  className,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
  children,
  severity = 'default',
  onClose,
  message,
  action,
  ...restProps
}: CustomSnackbarProps) => {
  const classes = useCustomSnackbarStyles();

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      className={`${classes.snackbar} ${className}`}
      {...restProps}
    >
      <SnackbarContent
        className={`${classes.snackbarContent} ${classes[severity]}`}
        message={message}
        action={action}
      />
    </Snackbar>
  );
};
