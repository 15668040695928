import { useProductVersionDrawerStore } from './version-drawer/product-version-drawer-store';
import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  safeDeleteSearchParams,
  safeSetSearchParams,
} from '../../../../../../../utils/search-params';

export const useProductVersionDrawer = (productVersions: Entity[]) => {
  const setDrawerEntity = useProductVersionDrawerStore(
    state => state.changeEntity,
  );
  const resetDrawer = useProductVersionDrawerStore(state => state.reset);

  const [hasUsedUrl, setHasUsedUrl] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (hasUsedUrl) return;

    const productVersionRef = searchParams.get('productVersion');

    if (productVersionRef) {
      const productVersion = productVersions.find(
        version => stringifyEntityRef(version) === productVersionRef,
      );

      if (productVersion) {
        setDrawerEntity(productVersion);
        setHasUsedUrl(true);
      }
    }
  }, [
    searchParams,
    productVersions,
    setDrawerEntity,
    hasUsedUrl,
    setSearchParams,
  ]);

  const onOpenProductVersionDrawer = useCallback(
    (entityToOpen: Entity) => {
      setDrawerEntity(entityToOpen);
      setHasUsedUrl(true);
      safeSetSearchParams('productVersion', stringifyEntityRef(entityToOpen));
    },
    [setDrawerEntity],
  );

  const onCloseProductVersionDrawer = useCallback(() => {
    safeDeleteSearchParams('productVersion');

    resetDrawer();
  }, [resetDrawer]);

  // on unmount, close the drawer
  useEffect(() => {
    return () => {
      onCloseProductVersionDrawer();
    };
  }, [onCloseProductVersionDrawer]);

  return {
    onCloseProductVersionDrawer,
    onOpenProductVersionDrawer,
  };
};
