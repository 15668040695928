import { Entity } from '@backstage/catalog-model';
import { AppliedProductManifest } from './applied-product-manifest/AppliedProductManifest';
import { AppliedStatus } from './applied-status/AppliedStatus';

export const DELETED_SUBSCRIPTION_LIFECYCLE = 'SUBSCRIPTION_UNSUBSCRIBED';

export type AapExtendedSpec = {
  vaultNamespace: string;
  products: ProductsSpec;
};

export type ProductsSpec = {
  [productId: string]: {
    appliedProductManifest: AppliedProductManifest;
    appliedStatus: AppliedStatus;
  };
};

export type SubscriptionEntity = Entity & {
  spec: Entity['spec'] & {
    type: 'subscription';
    aap: AapExtendedSpec;
  };
};
