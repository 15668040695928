import { configApiRef, fetchApiRef, useApi } from '@backstage/core-plugin-api';
import { useQueries, UseQueryResult } from '@tanstack/react-query';
import { Entity, stringifyEntityRef } from '@backstage/catalog-model';

type StatusesQueryResult = Record<string, { health: string; sync: string }>;

export const useMyDeploymentsStatuses = <CombineResult = any>(
  deployments?: Entity[],
  combineFn?: (
    results: UseQueryResult<StatusesQueryResult, Error>[],
  ) => CombineResult,
) => {
  const { fetch } = useApi(fetchApiRef);

  const configApi = useApi(configApiRef);
  const backendUrl = configApi.getString('backend.baseUrl');

  return useQueries({
    queries:
      deployments?.map(deployment => ({
        queryKey: ['deploymentStatus', stringifyEntityRef(deployment)],
        queryFn: async () => {
          const response = await fetch(
            `${backendUrl}/api/aap-argocd/subscription/${deployment.metadata.name}/status`,
          );

          if (!response.ok) {
            throw new Error(
              `Failed to fetch status from Argo (${response.status} ${response.statusText})`,
            );
          }

          return response.json();
        },
        retry: false,
      })) ?? [],
    combine: combineFn,
  });
};
