import { makeStyles, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

export type EmptyStateProps = {
  title?: string;
  description?: string;
  illustration: ReactElement<SVGElement>;
  illustrationWidth?: number;
  action?: ReactElement;
  options?: {
    noMargin?: boolean;
  };
};

const useEmptyStateStyles = makeStyles<Theme, { illustrationWidth: number }>(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(2),
      maxWidth: 400,
      textAlign: 'center',
      margin: theme.spacing(2),
    },
    noMargin: {
      margin: 0,
    },
    illustration: {
      width: ({ illustrationWidth }) => `${illustrationWidth}px`,
      height: 'auto',
    },
  }),
);

export const EmptyState = ({
  title,
  description,
  illustration,
  illustrationWidth = 200,
  action,
  options,
}: EmptyStateProps) => {
  const classes = useEmptyStateStyles({ illustrationWidth });

  const resizedIllustration = React.cloneElement(illustration, {
    className: classes.illustration,
  });

  return (
    <div className={classes.root}>
      <div
        className={clsx(classes.content, options?.noMargin && classes.noMargin)}
      >
        {resizedIllustration}
        {title && (
          <Typography variant="h5" color="textSecondary">
            {title}
          </Typography>
        )}
        {description && (
          <Typography variant="body1" color="textSecondary">
            {description}
          </Typography>
        )}
        {action}
      </div>
    </div>
  );
};
