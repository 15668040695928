import { Chip, makeStyles, Typography } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import {
  EmptyState,
  Table,
  useThemeMode,
} from '@internal/backstage-plugin-adeo-core-components-react';
import { GridColDef } from '@mui/x-data-grid';
import { useMyDeployments } from '../hooks/useMyDeployments';
import { useMyDeploymentsStatuses } from '../hooks/useMyDeploymentsStatuses';
import { merge } from 'lodash';
import { SUBSCRIPTION_PROJECT_ANNOTATION } from 'custom-annotations';
import { DeploymentStatus } from '../../common/DeploymentStatus';
import { DeploymentOwner } from '../../common/DeploymentOwner';
import { ComponentsAndResourcesCell } from './ComponentsAndResourcesCell';
import { ProductCell } from './ProductCell';
import { DeploymentActions } from './DeploymentActions';
import noDeploymentsLight from '../../../assets/empty-states/no-deployments-light.svg';
import noDeploymentsDark from '../../../assets/empty-states/no-deployments-dark.svg';

const useDeploymentsOverviewStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(5),
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  link: {
    textTransform: 'uppercase',
  },
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1.3)}px !important`,
  },
  tableContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  moreDeploymentsText: {
    color: theme.palette.text.secondary,
  },
  emptyStateContainer: {
    marginTop: theme.spacing(2),
  },
}));

const MAX_DEPLOYMENTS_DISPLAYED = 7;

export const DeploymentsOverview = () => {
  const classes = useDeploymentsOverviewStyles();
  const theme = useThemeMode();

  const { data: deployments } = useMyDeployments();
  const deploymentsStatuses = useMyDeploymentsStatuses<
    Record<
      string,
      {
        health: string;
        sync: string;
      }
    >
  >(deployments, results => {
    return merge({}, ...results.map(result => result.data ?? {}));
  });

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'product',
        headerName: 'Product',
        flex: 1,
        renderCell: params => <ProductCell subscription={params.row.raw} />,
      },
      { field: 'subscription', headerName: 'Subscription', flex: 1 },
      {
        field: 'project',
        headerName: 'Project',
        flex: 1,
        renderCell: params => (
          <Chip
            label={
              params.row.raw.metadata.annotations?.[
                SUBSCRIPTION_PROJECT_ANNOTATION
              ] ?? 'Unknown project'
            }
            color="primary"
            variant="outlined"
            size="small"
          />
        ),
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: params => (
          <DeploymentStatus
            status={deploymentsStatuses[params.row.raw.metadata.name]}
          />
        ),
      },
      {
        field: 'componentsAndResources',
        headerName: 'Comp. / Resource',
        flex: 1,
        renderCell: params => (
          <ComponentsAndResourcesCell subscription={params.row.raw} />
        ),
      },
      {
        field: 'subscribedBy',
        headerName: 'Subscribed by',
        flex: 1,
        renderCell: params => <DeploymentOwner subscription={params.row.raw} />,
      },
      {
        field: 'actions',
        headerName: '',
        type: 'actions',
        flex: 0.2,
        getActions: params => [
          <DeploymentActions
            subscription={params.row.raw}
            key={params.row.id}
          />,
        ],
      },
    ],
    [deploymentsStatuses],
  );

  const rows =
    deployments?.slice(0, MAX_DEPLOYMENTS_DISPLAYED).map(deployment => ({
      id: deployment.metadata.uid,
      subscription: deployment.metadata.title || deployment.metadata.name,
      raw: deployment,
    })) ?? [];

  const getCellClassName = useCallback(() => classes.tableCell, [classes]);

  const moreDeploymentsCount = deployments?.length
    ? deployments.length - MAX_DEPLOYMENTS_DISPLAYED
    : 0;

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h5">Deployments</Typography>
        {!!moreDeploymentsCount && moreDeploymentsCount > 0 && (
          <Typography variant="body2" className={classes.moreDeploymentsText}>
            {moreDeploymentsCount} more{' '}
            {moreDeploymentsCount > 1 ? 'deployments' : 'deployment'}
          </Typography>
        )}
      </div>
      {deployments?.length ? (
        <div className={classes.tableContainer}>
          <Table
            columns={columns}
            rows={rows}
            rowHeight={90}
            getCellClassName={getCellClassName}
            disableColumnSorting
            disableColumnResize
            disableColumnMenu
            disableRowSelectionOnClick
          />
        </div>
      ) : (
        <div className={classes.emptyStateContainer}>
          <EmptyState
            title="No deployments"
            description="There is no deployments, as soon as a deployments is available you will find it here."
            illustrationWidth={100}
            illustration={
              <img
                src={theme === 'light' ? noDeploymentsLight : noDeploymentsDark}
                alt="Deployment illustration"
              />
            }
          />
        </div>
      )}
    </div>
  );
};
