import React from 'react';
import { Section } from '../common/Section';
import { Alert } from '@material-ui/lab';
import { useKafkaTopicResourceSecrets } from './useKafkaTopicResourceSecrets';
import { TabsSection } from '../common/TabsSection';
import { SectionContent, TabContent } from '../common/SectionContent';
import { SectionContentWithHeader } from '../common/secrets/SectionContentWithHeader';
import { ResourceSecretsContentHeader } from '../common/secrets/ResourceSecrets/ResourceSecretsContentHeader';

export const KafkaSecrets = () => {
  const secretsOrError = useKafkaTopicResourceSecrets();

  if ('error' in secretsOrError) {
    return (
      <Section title="Secrets">
        <Alert severity="error">{secretsOrError.error.message}</Alert>
      </Section>
    );
  }

  return (
    <Section title="Secrets">
      <TabsSection
        tabsTitle={[
          { label: 'Cluster', value: 'cluster' },
          { label: 'Registry', value: 'registry' },
          { label: 'Topic', value: 'topic' },
        ]}
        renderContent={currentTab => {
          if (currentTab === 'cluster' || currentTab === 'registry') {
            return (
              <SectionContent
                currentTab={currentTab}
                showLineNumber
                tabsContent={secretsOrError.data as TabContent}
                emptyDataMessage={`No ${currentTab} secrets to display for this kafka topic`}
              />
            );
          } else if (currentTab === 'topic') {
            return (
              <SectionContentWithHeader
                secretData={
                  secretsOrError.data.topic.data as Record<string, string[]>
                }
                emptySecretsMessage="No secrets to display for this component in this resource"
                SecretsContentHeaderComponent={ResourceSecretsContentHeader}
              />
            );
          }

          return null;
        }}
      />
    </Section>
  );
};
