export async function callWithRetries<T>(
  asyncFn: () => Promise<T>,
  initialTimeout: number,
  maxRetries: number = 3,
): Promise<T> {
  let attempts = 0;

  const retry = async (timeout: number): Promise<T> => {
    attempts++;
    try {
      await new Promise(resolve => setTimeout(resolve, timeout));
      return await asyncFn();
    } catch (error) {
      if (attempts >= maxRetries) {
        throw new Error(`Failed after ${attempts} attempts: ${error}`);
      }
      return retry(timeout + 1000);
    }
  };

  return retry(initialTimeout);
}
