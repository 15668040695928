import { Tooltip as MuiTooltip, withStyles } from '@material-ui/core';

export const Tooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.navigation.background,
    color: 'white',
    fontSize: theme.typography.pxToRem(14),
  },
  arrow: {
    color: theme.palette.navigation.background,
  },
}))(MuiTooltip);
