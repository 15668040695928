import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useThemeMode } from '@internal/backstage-plugin-adeo-core-components-react';

const Logo = ({
  isTextDisplayed = false,
  height = '2.5rem',
  isThemeAdaptative = false,
}) => {
  const themeMode = useThemeMode();
  const textColor = isThemeAdaptative && themeMode !== 'dark' ? '#000' : '#fff';

  const useStyles = makeStyles({
    svg: {
      width: 'auto',
      height,
    },
    text: { fill: textColor },
  });
  const classes = useStyles();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={classes.svg}
      viewBox="0
    0
    237.39
    71.94"
    >
      <defs>
        <linearGradient
          id="a"
          x1="6.79"
          y1="40.84"
          x2="51.41"
          y2="66.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#91d5db" />
          <stop offset="1" stopColor="#00919f" />
        </linearGradient>
        <linearGradient
          id="b"
          x1="6.8"
          y1="35.65"
          x2="51.41"
          y2="61.41"
          xlinkHref="#a"
        />
        <linearGradient
          id="c"
          x1="6.8"
          y1="30.65"
          x2="51.41"
          y2="56.41"
          xlinkHref="#a"
        />
        <linearGradient
          id="d"
          x1="3.26"
          y1="13.29"
          x2="55.83"
          y2="43.64"
          xlinkHref="#a"
        />
        <linearGradient
          id="e"
          x1="15.94"
          y1="39.02"
          x2="25.87"
          y2="44.75"
          xlinkHref="#a"
        />
      </defs>
      <path
        fill="url(#a)"
        d="M29.35,71.94a3.65,3.65,0,0,1-.93-.12l-.19-.08-.08,0-.33-.13h0l-.31-.16L1.73,56A3.63,3.63,0,0,1,0,52.88a1,1,0,0,1,2,0,1.64,1.64,0,0,0,.76,1.36L28.33,69.58h.13l.21.19.16.06.13,0,.05,0a1.35,1.35,0,0,0,.69,0l.17-.07.14,0,.18-.17h.16l25.7-15.42a1.66,1.66,0,0,0,.78-1.38,1,1,0,0,1,1-1,1,1,0,0,1,1,1,3.68,3.68,0,0,1-1.76,3L30.89,71.58a2,2,0,0,1-.33.13l-.08,0-.19.08A3.73,3.73,0,0,1,29.35,71.94Z"
      />
      <path
        fill="url(#b)"
        d="M29.35,66.76a3.38,3.38,0,0,1-.94-.13l-.18-.07-.08,0-.33-.13h0l-.31-.17L1.73,50.76A3.62,3.62,0,0,1,0,47.69a1,1,0,0,1,2,0,1.63,1.63,0,0,0,.76,1.36L28.33,64.4h.13l.21.19.16.06.13,0,.05,0a1.61,1.61,0,0,0,.69,0l.17-.07L30,64.6l.18-.18h.16L56.05,49a1.69,1.69,0,0,0,.78-1.38,1,1,0,0,1,1-1,1,1,0,0,1,1,1,3.7,3.7,0,0,1-1.76,3L30.89,66.4l-.33.13-.08,0-.19.07A3.33,3.33,0,0,1,29.35,66.76Z"
      />
      <path
        fill="url(#c)"
        d="M29.35,61.76a3.38,3.38,0,0,1-.94-.13l-.18-.07-.08,0-.33-.13h0l-.31-.17L1.73,45.76A3.62,3.62,0,0,1,0,42.69a1,1,0,0,1,2,0,1.63,1.63,0,0,0,.76,1.36L28.33,59.4h.13l.21.19.16.06.13.05.05,0a1.61,1.61,0,0,0,.69,0l.17-.07.14,0,.18-.18h.16L56.05,44a1.69,1.69,0,0,0,.78-1.38,1,1,0,0,1,1-1,1,1,0,0,1,1,1,3.7,3.7,0,0,1-1.76,3L30.89,61.4l-.33.13-.08,0-.19.07A3.33,3.33,0,0,1,29.35,61.76Z"
      />
      <path
        fill="url(#d)"
        d="M58.29,17.65a1.63,1.63,0,0,0-.1-.25A3.29,3.29,0,0,0,58,17c-.05-.08-.13-.17-.18-.25l-.22-.29a2.43,2.43,0,0,0-.25-.2,1.52,1.52,0,0,0-.27-.22L30.91.37a2.64,2.64,0,0,0-2.69,0L2,16.1a1.13,1.13,0,0,0-.27.22,1,1,0,0,0-.25.2l-.22.29a1.84,1.84,0,0,0-.17.25,2.48,2.48,0,0,0-.17.37.77.77,0,0,0-.1.25,2,2,0,0,0-.1.69V38.58A2.65,2.65,0,0,0,2,40.83L28.17,56.55h0l0,0a3.69,3.69,0,0,0,.42.17l.17.08a2.61,2.61,0,0,0,1.36,0l.17-.08a2.3,2.3,0,0,0,.42-.17l0,0h0L57.1,40.78a2.7,2.7,0,0,0,1.29-2.25V18.34A3,3,0,0,0,58.29,17.65Zm-31.35,32L6,37V23l21,12.59ZM50.66,18.34,29.55,31,8.45,18.32,29.55,5.65Zm2.47,18.71-21,12.59V35.55L53.13,23Z"
      />
      <polygon
        fill="url(#e)"
        points="25.35
    45.64
    25.35
    43.07
    16.46
    38.13
    16.46
    40.7
    25.35
    45.64"
      />
      {isTextDisplayed && (
        <>
          <path
            className={classes.text}
            d="M73.91,19.19H76.5l4,12.42H78.21L76.32,25c-.39-1.32-.76-2.73-1.12-4.1h-.08c-.34,1.38-.71,2.78-1.1,4.1l-1.91,6.61H69.86Zm-1.66,7.32h5.86v1.73H72.25Z"
          />
          <path
            className={classes.text}
            d="M81.32,27c0-3,1.91-4.9,4-4.9a3.44,3.44,0,0,1,2.45,1l-.08-1.51V18.19h2.19V31.61H88l-.17-1H87.8a3.91,3.91,0,0,1-2.65,1.24C82.83,31.84,81.32,30,81.32,27Zm6.32,2V24.63a2.75,2.75,0,0,0-1.9-.77c-1.19,0-2.17,1.12-2.17,3.07s.78,3.1,2.12,3.1A2.48,2.48,0,0,0,87.64,29Z"
          />
          <path
            className={classes.text}
            d="M92,27c0-3,2.11-4.9,4.3-4.9,2.53,0,3.86,1.82,3.86,4.43a5.42,5.42,0,0,1-.1,1.06H94.13a2.65,2.65,0,0,0,2.75,2.6A4,4,0,0,0,99,29.47l.75,1.36a5.79,5.79,0,0,1-3.2,1C94,31.84,92,30,92,27Zm6.26-.9c0-1.43-.63-2.31-1.91-2.31a2.34,2.34,0,0,0-2.23,2.31Z"
          />
          <path
            className={classes.text}
            d="M101.62,27c0-3.11,2.12-4.9,4.44-4.9s4.44,1.79,4.44,4.9-2.11,4.89-4.44,4.89S101.62,30,101.62,27Zm6.63,0c0-1.87-.82-3.11-2.19-3.11s-2.19,1.24-2.19,3.11.82,3.1,2.19,3.1S108.25,28.82,108.25,27Z"
          />
          <path
            className={classes.text}
            d="M73.91,40.37H76.5l4,12.43H78.21l-1.89-6.61c-.39-1.32-.76-2.74-1.12-4.1h-.08c-.34,1.38-.71,2.78-1.1,4.1L72.11,52.8H69.86ZM72.25,47.7h5.86v1.73H72.25Z"
          />
          <path
            className={classes.text}
            d="M81.89,43.47h1.79l.17,1h.06a4.51,4.51,0,0,1,2.81-1.22c2.34,0,3.67,1.87,3.67,4.75,0,3.19-1.9,5-4,5A3.73,3.73,0,0,1,84,52l.07,1.55v2.94H81.89ZM88.15,48c0-1.86-.61-3-2-3a3,3,0,0,0-2,1v4.35a2.92,2.92,0,0,0,1.87.78C87.2,51.22,88.15,50.12,88.15,48Z"
          />
          <path
            className={classes.text}
            d="M92.61,43.47H94.4l.17,1h.06a4.51,4.51,0,0,1,2.81-1.22c2.33,0,3.67,1.87,3.67,4.75,0,3.19-1.91,5-4,5a3.73,3.73,0,0,1-2.4-1l.07,1.55v2.94H92.61ZM98.86,48c0-1.86-.61-3-2-3a3,3,0,0,0-2,1v4.35a2.92,2.92,0,0,0,1.87.78C97.91,51.22,98.86,50.12,98.86,48Z"
          />
          <path
            className={classes.text}
            d="M103.21,50.4v-11h2.19V50.51c0,.54.24.73.47.73a1.21,1.21,0,0,0,.34,0l.28,1.64a3.3,3.3,0,0,1-1.16.18C103.78,53,103.21,52,103.21,50.4Z"
          />
          <path
            className={classes.text}
            d="M108.11,40.59a1.35,1.35,0,1,1,1.35,1.24A1.26,1.26,0,0,1,108.11,40.59Zm.25,2.88h2.19V52.8h-2.19Z"
          />
          <path
            className={classes.text}
            d="M112.72,48.14a4.61,4.61,0,0,1,4.68-4.9,4,4,0,0,1,2.73,1l-1.06,1.42a2.29,2.29,0,0,0-1.55-.69C116,45,115,46.27,115,48.14s1,3.1,2.48,3.1a3.06,3.06,0,0,0,1.93-.81l.89,1.45a4.65,4.65,0,0,1-3,1.14C114.67,53,112.72,51.23,112.72,48.14Z"
          />
          <path
            className={classes.text}
            d="M121.42,50.29c0-2,1.66-3,5.49-3.45,0-1-.43-1.84-1.7-1.84a5.18,5.18,0,0,0-2.62.92l-.8-1.46a7.31,7.31,0,0,1,3.83-1.22c2.33,0,3.48,1.43,3.48,4V52.8h-1.8l-.16-1h-.06a4.5,4.5,0,0,1-2.9,1.26A2.61,2.61,0,0,1,121.42,50.29Zm5.49,0V48.2c-2.53.32-3.37,1-3.37,1.92s.56,1.19,1.34,1.19A2.94,2.94,0,0,0,126.91,50.3Z"
          />
          <path
            className={classes.text}
            d="M131.75,49.65V45.21h-1.33V43.56l1.45-.09.25-2.55h1.82v2.55h2.38v1.74h-2.38v4.44c0,1.1.43,1.64,1.32,1.64a2.92,2.92,0,0,0,1-.22l.37,1.61a6,6,0,0,1-1.91.34C132.55,53,131.75,51.67,131.75,49.65Z"
          />
          <path
            className={classes.text}
            d="M138,40.59a1.36,1.36,0,1,1,1.35,1.24A1.26,1.26,0,0,1,138,40.59Zm.25,2.88h2.19V52.8h-2.19Z"
          />
          <path
            className={classes.text}
            d="M142.65,48.14c0-3.11,2.11-4.9,4.43-4.9s4.44,1.79,4.44,4.9S149.41,53,147.08,53,142.65,51.23,142.65,48.14Zm6.62,0c0-1.87-.82-3.12-2.19-3.12s-2.18,1.25-2.18,3.12.82,3.1,2.18,3.1S149.27,50,149.27,48.14Z"
          />
          <path
            className={classes.text}
            d="M153.69,43.47h1.8l.16,1.26h.06a4.35,4.35,0,0,1,3.07-1.49c2,0,2.87,1.35,2.87,3.71V52.8h-2.19V47.23c0-1.54-.44-2.12-1.45-2.12a3,3,0,0,0-2.13,1.17V52.8h-2.19Z"
          />
          <path
            className={classes.text}
            d="M168.32,40.37h3.92c2.75,0,4.8,1,4.8,3.83s-2,4-4.73,4h-1.78V52.8h-2.21Zm3.81,6.1c1.85,0,2.73-.74,2.73-2.27s-1-2.06-2.81-2.06h-1.52v4.33Z"
          />
          <path
            className={classes.text}
            d="M179.19,50.4v-11h2.19V50.51c0,.54.24.73.47.73a1.21,1.21,0,0,0,.34,0l.27,1.64a3.2,3.2,0,0,1-1.15.18C179.76,53,179.19,52,179.19,50.4Z"
          />
          <path
            className={classes.text}
            d="M183.84,50.29c0-2,1.65-3,5.48-3.45,0-1-.43-1.84-1.69-1.84a5.15,5.15,0,0,0-2.62.92l-.81-1.46A7.34,7.34,0,0,1,188,43.24c2.33,0,3.48,1.43,3.48,4V52.8h-1.79l-.17-1h-.06A4.46,4.46,0,0,1,186.6,53,2.61,2.61,0,0,1,183.84,50.29Zm5.48,0V48.2c-2.53.32-3.37,1-3.37,1.92s.56,1.19,1.34,1.19A2.94,2.94,0,0,0,189.32,50.3Z"
          />
          <path
            className={classes.text}
            d="M194.17,49.65V45.21h-1.33V43.56l1.44-.09.26-2.55h1.81v2.55h2.38v1.74h-2.38v4.44c0,1.1.43,1.64,1.33,1.64a2.81,2.81,0,0,0,1-.22l.37,1.61a6,6,0,0,1-1.91.34C195,53,194.17,51.67,194.17,49.65Z"
          />
          <path
            className={classes.text}
            d="M199.82,43.56l1.36-.09h3.9v1.74h-5.26Zm1.25-1.06c0-1.93.89-3.35,3.09-3.35a4.67,4.67,0,0,1,1.71.33l-.42,1.63a2.67,2.67,0,0,0-1-.22c-.77,0-1.18.49-1.18,1.57V52.8h-2.19Z"
          />
          <path
            className={classes.text}
            d="M205.92,48.14c0-3.11,2.11-4.9,4.43-4.9s4.44,1.79,4.44,4.9S212.68,53,210.35,53,205.92,51.23,205.92,48.14Zm6.62,0c0-1.87-.82-3.12-2.19-3.12s-2.19,1.25-2.19,3.12.82,3.1,2.19,3.1S212.54,50,212.54,48.14Z"
          />
          <path
            className={classes.text}
            d="M217,43.47h1.79l.17,1.66H219a3.19,3.19,0,0,1,2.65-1.89,2.36,2.36,0,0,1,1.06.19l-.38,1.9a3.22,3.22,0,0,0-1-.15c-.74,0-1.64.5-2.2,1.93V52.8H217Z"
          />
          <path
            className={classes.text}
            d="M224.05,43.47h1.79l.17,1.27h.06a4,4,0,0,1,2.86-1.5,2.52,2.52,0,0,1,2.58,1.67,4.22,4.22,0,0,1,3-1.67c2,0,2.88,1.35,2.88,3.71V52.8H235.2V47.23c0-1.54-.45-2.12-1.43-2.12a2.88,2.88,0,0,0-2,1.17V52.8h-2.19V47.23c0-1.54-.45-2.12-1.43-2.12a2.88,2.88,0,0,0-1.95,1.17V52.8h-2.19Z"
          />
        </>
      )}
    </svg>
  );
};

export default Logo;
