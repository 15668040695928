import noDeploymentsLight from '../../../assets/empty-states/no-deployments-light.svg';
import noDeploymentsDark from '../../../assets/empty-states/no-deployments-dark.svg';
import React from 'react';
import { useThemeMode } from '@internal/backstage-plugin-adeo-core-components-react';
import { OwnershipSection } from './OwnershipSection';
import { makeStyles, Typography } from '@material-ui/core';
import { StatusBadge } from '../../catalog/pages/component/product/ref/deployments/StatusBadge';

const useMySubscriptionsSectionStyles = makeStyles(theme => ({
  count: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(18),
  },
  coloredStatusDistribution: {
    background: theme.palette.grey['200'],
    borderRadius: '4px',
    width: '100%',
    height: '6px',
    marginTop: theme.spacing(1),
  },
  contentContainer: {
    marginTop: 'auto',
  },
  statusesCount: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  counter: {
    display: 'flex',
    alignItems: 'center',
  },
  badge: {
    marginRight: theme.spacing(0.5),
  },
}));

export const MySubscriptionsSection = () => {
  const theme = useThemeMode();
  const classes = useMySubscriptionsSectionStyles();

  return (
    <OwnershipSection
      illustration={
        <img
          src={theme === 'light' ? noDeploymentsLight : noDeploymentsDark}
          alt="Deployment illustration"
        />
      }
      title="My subscriptions"
    >
      <div className={classes.contentContainer}>
        <div className={classes.statusesCount}>
          <div className={classes.counter}>
            <StatusBadge status="Unknown" className={classes.badge} />
            <Typography className={classes.count}>0</Typography>
          </div>
          <Typography className={classes.count}>/0</Typography>
        </div>
        <div className={classes.coloredStatusDistribution} />
      </div>
    </OwnershipSection>
  );
};
