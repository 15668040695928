import { TabContent } from '../../SectionContent';
import { useDeploymentsStore } from '../../../../store/deployments-store';
import {
  getAppliedManifest,
  parseComponentOrResourceName,
} from '../../manifest-utils';
import { Entity } from '@backstage/catalog-model';
import { AppliedProductManifest } from '../../../../../../../../../../../types/applied-product-manifest/AppliedProductManifest';
import each from 'lodash.foreach';

const getComponentsUsingResource = (
  resource: Entity,
  productAppliedManifest: AppliedProductManifest['product'],
) => {
  const result: Record<string, string[]> = {};

  each(productAppliedManifest.components, componentFamily => {
    each(componentFamily, (component, componentName) => {
      const fromResources = component.secrets?.env?.fromResources;

      if (!fromResources) return;

      each(fromResources, resourceFamily => {
        each(resourceFamily, (_, resourceName) => {
          if (resourceName === parseComponentOrResourceName(resource)) {
            result[componentName] = resourceFamily[resourceName].map(
              secret => secret.name,
            );
          }
        });
      });
    });
  });

  return result;
};

export const useResourceSecrets = ():
  | { error: Error }
  | { data: TabContent<Record<string, string[]>> } => {
  const entity = useDeploymentsStore(state => state.entity);
  const subscription = useDeploymentsStore(state => state.subscription);
  const productVersion = useDeploymentsStore(state => state.productVersion);

  if (!entity || !subscription || !productVersion)
    return {
      error: new Error(
        'Could not find resource secrets because either resource, subscription or productVersion is missing',
      ),
    };

  const appliedManifest = getAppliedManifest(
    subscription,
    productVersion?.metadata.name,
  );

  if (!appliedManifest)
    return {
      error: new Error(
        `Could not find appliedManifest in subscription ${subscription.metadata.name} for version ${productVersion.metadata.title}`,
      ),
    };

  return {
    data: {
      components: {
        data: getComponentsUsingResource(entity, appliedManifest),
      },
    },
  };
};
