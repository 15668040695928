import noProductLight from '../../../assets/empty-states/no-product-light.svg';
import noProductDark from '../../../assets/empty-states/no-product-dark.svg';
import { Typography } from '@material-ui/core';
import React from 'react';
import { useThemeMode } from '@internal/backstage-plugin-adeo-core-components-react';
import { useUserInfo } from '../../../utils/useUserInfo';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { RELATION_OWNED_BY } from '@backstage/catalog-model';
import { useQuery } from '@tanstack/react-query';
import { OwnershipSection } from './OwnershipSection';
import Skeleton from '@material-ui/lab/Skeleton';
import { useDelayedLoading } from '../../../hooks/useDelayedLoading';

export const MyProductsSection = () => {
  const theme = useThemeMode();
  const catalogApi = useApi(catalogApiRef);

  const { value: user } = useUserInfo();

  const { data: productsCount, isFetching } = useQuery({
    queryKey: ['productsCount', user?.ldap],
    queryFn: async () => {
      const { items } = await catalogApi.getEntities({
        filter: {
          kind: 'Component',
          'spec.type': 'product',
          [`relations.${RELATION_OWNED_BY}`]: user!.ownershipEntityRefs,
        },
      });

      return items.length;
    },
    enabled: !!user,
  });

  const isLoading = useDelayedLoading(isFetching);

  return (
    <OwnershipSection
      illustration={
        <img
          src={theme === 'light' ? noProductLight : noProductDark}
          alt="Product illustration"
        />
      }
      title="My products"
    >
      {isLoading ? (
        <Skeleton width={60} height={40} variant="text" />
      ) : (
        <Typography variant="h3">{productsCount}</Typography>
      )}
    </OwnershipSection>
  );
};
