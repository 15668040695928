import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Avatar, type AvatarUser } from '../Avatar';
import { Divider, Popper } from '@material-ui/core';
import { ScrollArea } from '../ScrollArea';
import { useThemeMode } from '../../utils';

/**
 * Props for {@link AvatarGroup}.
 */
export type AvatarGroupProps = {
  size?: 'small' | 'medium';
  max?: number;
  users: AvatarUser[];
};

const useAvatarGroupStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: 'none',
    padding: 0,
    background: 'none',
    cursor: 'pointer',
    borderRadius: '0',
  },
  element: {
    marginLeft: theme.spacing(-1),
  },
  moreUser: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(-1),
    backgroundColor: theme.palette.grey[300],
    borderRadius: '50%',
    padding: theme.spacing(1),
  },
  moreUserSmall: {
    fontSize: '12px',
    fontWeight: 'bold',
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  moreUserMedium: {
    fontSize: '14px',
    fontWeight: 'bold',
    height: theme.spacing(5),
    width: theme.spacing(5),
  },
  popup: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    borderRadius: '4px',
  },
  scrollableList: {
    maxHeight: '50vh',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    width: theme.spacing(40),
  },
  popupAvatar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

/**
 * Displays a group of avatars.
 *
 * @remarks
 *
 * Displays a group of avatars with a maximum number of avatars to show. If the number of avatars
 * exceeds the maximum, a counter is displayed.
 *
 * @public
 */
export const AvatarGroup = (props: AvatarGroupProps) => {
  const { size = 'medium', max = 3, users } = props;
  const classes = useAvatarGroupStyles();
  const themeMode = useThemeMode();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  if (users.length === 0) {
    return null;
  }

  const displayedUsers = users.slice(0, max);

  const moreUserSizeClasses =
    size === 'small' ? classes.moreUserSmall : classes.moreUserMedium;

  const open = Boolean(anchorEl);

  const handleMouseEnter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const id = open ? 'more-users' : undefined;

  return (
    <button
      className={classes.root}
      aria-describedby={id}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {displayedUsers.map((user, index) => (
        <Avatar
          key={user.email}
          {...user}
          size={size}
          isCompact
          className={(index > 0 && classes.element) || undefined}
          style={{
            position: 'relative',
            zIndex: displayedUsers.length - index,
          }}
        />
      ))}
      <div className={`${classes.moreUser} ${moreUserSizeClasses}`}>
        <Typography variant={size === 'small' ? 'body2' : 'body1'}>
          +{users.length - max}
        </Typography>
      </div>
      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
        <div className={classes.popup}>
          <ScrollArea childrenBackgroundTone={themeMode as 'dark' | 'light'}>
            <div className={classes.scrollableList}>
              {users.map((user, index) => (
                <>
                  <Avatar
                    className={classes.popupAvatar}
                    key={user.email}
                    {...user}
                    size={size}
                  />
                  {index < users.length - 1 && <Divider />}
                </>
              ))}
            </div>
          </ScrollArea>
        </div>
      </Popper>
    </button>
  );
};
