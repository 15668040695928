import { create } from 'zustand';
import { Entity } from '@backstage/catalog-model';

type ProductVersionDrawerStore = {
  selectedProductVersion?: Entity;
  changeEntity: (entity: Entity) => void;
  reset: () => void;
};

const initialState: Pick<ProductVersionDrawerStore, 'selectedProductVersion'> =
  {
    selectedProductVersion: undefined,
  };

export const useProductVersionDrawerStore = create<ProductVersionDrawerStore>(
  set => ({
    ...initialState,
    changeEntity: entity =>
      set({
        selectedProductVersion: entity,
      }),
    reset: () => {
      set(initialState);
    },
  }),
);
