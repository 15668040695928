import React from 'react';
import { Entity } from '@backstage/catalog-model';
import { GridView } from './GridView';
import { Pagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import { ListView } from './ListView/ListView';
import {
  EmptyState,
  useThemeMode,
} from '@internal/backstage-plugin-adeo-core-components-react';
import noPackageLight from '../../assets/empty-states/no-package-light.svg';
import noPackageDark from '../../assets/empty-states/no-package-dark.svg';
import noPackageSearchLight from '../../assets/empty-states/no-package-search-light.svg';
import noPackageSearchDark from '../../assets/empty-states/no-package-search-dark.svg';
import { usePagination } from './usePagination';

const usePackageDiscoveryContentStyles = makeStyles(theme => ({
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
}));

interface PackageDiscoveryContentProps {
  packages: Entity[];
  viewMode: 'grid' | 'list';
}

export const PackageDiscoveryContent = ({
  packages,
  viewMode,
}: PackageDiscoveryContentProps) => {
  const classes = usePackageDiscoveryContentStyles();

  const theme = useThemeMode();

  const { page, data, onPageChange, pageCount } = usePagination(
    packages,
    viewMode,
  );

  const onChange = (_: React.ChangeEvent<unknown>, newPage: number) => {
    onPageChange(newPage);
  };

  if (!packages) {
    const illustration = (
      <img
        src={theme === 'dark' ? noPackageDark : noPackageLight}
        alt="No package"
      />
    );

    return (
      <EmptyState
        title="No packages"
        description="No packages exist at the moment, try to come back later."
        illustration={illustration}
      />
    );
  }

  if (!pageCount) {
    const illustration = (
      <img
        src={theme === 'dark' ? noPackageSearchDark : noPackageSearchLight}
        alt="No package"
      />
    );

    return (
      <EmptyState
        title="No packages found"
        description="We couldn't find any packages matching your search. Please try refining your search terms or explore our curated categories to discover relevant packages."
        illustration={illustration}
      />
    );
  }

  return (
    <>
      {viewMode === 'grid' ? (
        <GridView chunk={data} />
      ) : (
        <ListView chunk={data} />
      )}
      <Pagination
        count={pageCount}
        className={classes.pagination}
        onChange={onChange}
        page={page}
        color="primary"
      />
    </>
  );
};
