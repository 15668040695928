import { SubscriptionAndVersion } from '../../../../../../hooks/useProductSubscriptionsAndVersions';
import { StateCreator } from 'zustand';
import { DeploymentsStore } from './deployments-store';
import { SUBSCRIPTION_PROJECT_ANNOTATION } from 'custom-annotations';
import { DeploymentStatus } from '../DeploymentAccordion/useDeploymentStatus';

type State = {
  subscriptionsAndVersions: SubscriptionAndVersion[];
  filteredSubscriptionsAndVersions: SubscriptionAndVersion[];
  statuses: Record<string, DeploymentStatus>;
};

type Actions = {
  setSubscriptionsAndVersions: (
    subscriptions: SubscriptionAndVersion[],
  ) => void;
  filterSubscriptionAndVersions: () => void;
  setProductStatus: (productStatus: Record<string, DeploymentStatus>) => void;
};

export type SubscriptionsSlice = State & Actions;

export const createSubscriptionsSlice: StateCreator<
  DeploymentsStore,
  [],
  [],
  SubscriptionsSlice
> = (set, get) => ({
  subscriptionsAndVersions: [],
  filteredSubscriptionsAndVersions: [],
  statuses: {},
  setSubscriptionsAndVersions: (subscriptions: SubscriptionAndVersion[]) => {
    const projects = subscriptions
      .map(
        ({ subscription }) =>
          subscription?.metadata.annotations?.[SUBSCRIPTION_PROJECT_ANNOTATION],
      )
      .filter((project): project is string => Boolean(project));

    get().setProjects(projects);

    set(() => ({ subscriptionsAndVersions: subscriptions }));
    get().filterSubscriptionAndVersions();
  },
  filterSubscriptionAndVersions: () =>
    set(state => {
      const subscriptionsWithVersions = state.subscriptionsAndVersions;

      const filteredSubscriptionsAndVersions = subscriptionsWithVersions.reduce<
        SubscriptionAndVersion[]
      >((acc, subscriptionAndVersion) => {
        const matchSearchQuery =
          subscriptionAndVersion.subscription?.metadata.title
            ?.toLowerCase()
            .includes(state.searchQuery.toLowerCase());

        const hasProject = state.projectFilter.includes(
          subscriptionAndVersion.subscription?.metadata.annotations?.[
            SUBSCRIPTION_PROJECT_ANNOTATION
          ] ?? '',
        );

        if (matchSearchQuery && hasProject) {
          acc.push(subscriptionAndVersion);
        }

        return acc;
      }, []);

      return { filteredSubscriptionsAndVersions };
    }),
  setProductStatus: (productStatus: Record<string, DeploymentStatus>) =>
    set(state => ({
      statuses: {
        ...state.statuses,
        ...productStatus,
      },
    })),
});
