import { makeStyles } from '@material-ui/core';
import React from 'react';

const useTooltipTextStyles = makeStyles({
  link: {
    textDecoration: 'underline',
  },
});

export const DisabledDeployTooltipText = ({
  manifestUrl,
}: {
  manifestUrl?: string;
}) => {
  const classes = useTooltipTextStyles();

  return (
    <>
      <span>The product is initialised by this </span>
      <a
        href={manifestUrl}
        target="_blank"
        rel="noreferrer noopener"
        className={classes.link}
      >
        product manifest
      </a>
      .<span> Create a release to deploy it. </span>
      <a
        href="https://docs.packaging.adeo.cloud/latest/developers/product/product_workflow/"
        target="_blank"
        rel="noreferrer noopener"
        className={classes.link}
      >
        Read about the product lifecycle
      </a>
      .
    </>
  );
};
