import React, { useState } from 'react';
import { useEntity, useRelatedEntities } from '@backstage/plugin-catalog-react';
import {
  Button,
  Container,
  Divider,
  Grid,
  makeStyles,
} from '@material-ui/core';
import {
  RELATION_DEPENDS_ON,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { Alert } from '@material-ui/lab';
import { useRouteRef } from '@backstage/core-plugin-api';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { LinkButton, LinkButtonProps } from '@backstage/core-components';
import {
  EntityPresentation,
  EntityPresentationDescription,
  EntityPresentationHeader,
  EntityPresentationMetrics,
} from '@internal/backstage-plugin-adeo-core-components-react';
import { AddTaskIcon } from 'backstage-plugin-icons-react';
import { PackageEntityProductCard } from './PackageEntityProductCard';
import { EntityWarningContent } from '../../../../content/EntityWarningContent';
import { useMetrics } from '../../../../../../hooks/metrics/useMetrics';
import { useGetPackageManualActionsQuery } from '../../../../hooks/useManualActions';
import ResourceSearchAndViewSwitcher from '../../../../../common/ResourceSearchAndViewSwitcher';
import { useViewMode } from '../../../../../../hooks/useViewMode';
import { PackageEntityProductsTable } from './PackageEntityProductsTable';

export const SubscribeButton = (props: Omit<LinkButtonProps, 'to'>) => {
  const { entity } = useEntity();
  const { disabled } = props;

  const scaffolderTemplateRoute = useRouteRef(
    scaffolderPlugin.routes.selectedTemplate,
  );

  const createSubscriptionUrl = `${scaffolderTemplateRoute({
    namespace: 'default',
    templateName: 'subscription-template',
  })}?formData={"package":"${stringifyEntityRef(entity)}"}`;

  if (disabled) {
    return (
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddTaskIcon />}
        {...props}
      >
        Subscribe
      </Button>
    );
  }

  return (
    <LinkButton
      to={createSubscriptionUrl}
      variant="contained"
      color="primary"
      startIcon={<AddTaskIcon />}
      {...props}
    >
      Subscribe
    </LinkButton>
  );
};

const usePackageEntityOverviewStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    gap: theme.spacing(2),
  },
  entityPresentation: {
    position: 'relative',
  },
  actionButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  errorContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export const PackageEntityOverview = () => {
  const classes = usePackageEntityOverviewStyles();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { entity } = useEntity();
  const [viewMode, setViewMode] = useViewMode();

  const manualActions = useGetPackageManualActionsQuery({
    packageId: entity?.metadata.name,
  });

  const { metrics, loading, error } = useMetrics(entity, [
    'owner',
    'namespace',
    'products',
  ]);

  const { entities: dependsOnRelations } = useRelatedEntities(entity, {
    kind: 'component',
    type: RELATION_DEPENDS_ON,
  });

  const type = entity?.spec?.type?.toString() ?? '';

  const filteredDependsOnRelations = dependsOnRelations?.filter(
    dependsOnEntity =>
      dependsOnEntity.metadata.title
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()),
  );

  return (
    <Container maxWidth="lg" className={classes.root}>
      {EntityWarningContent}
      <EntityPresentation size="medium" className={classes.entityPresentation}>
        <div className={classes.actionButton}>
          <SubscribeButton disabled />
        </div>
        <EntityPresentationHeader
          title={entity.metadata.title ?? ''}
          type={type}
        />
        <EntityPresentationDescription
          description={entity.metadata.description ?? ''}
        />
        <Divider />
        {error ? (
          <Alert severity="error" className={classes.errorContainer}>
            {error.message}
          </Alert>
        ) : (
          <EntityPresentationMetrics metrics={metrics} loading={loading} />
        )}
        <Divider />
      </EntityPresentation>

      <ResourceSearchAndViewSwitcher
        resourceType="product"
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        viewMode={viewMode}
        setViewMode={setViewMode}
      />

      {filteredDependsOnRelations &&
        filteredDependsOnRelations.length > 0 &&
        (viewMode === 'grid' ? (
          <Grid container spacing={2}>
            {filteredDependsOnRelations.map(onDemandsEntity => (
              <Grid
                item
                key={onDemandsEntity.metadata.uid}
                xs={12}
                sm={12}
                md={12}
                lg={6}
              >
                <PackageEntityProductCard
                  entity={onDemandsEntity}
                  manualActions={manualActions.data?.data || []}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <PackageEntityProductsTable products={filteredDependsOnRelations} />
        ))}
    </Container>
  );
};

PackageEntityOverview.displayName = 'PackageEntityOverview';
