import React, { useEffect } from 'react';
import { useEntity, useRelatedEntities } from '@backstage/plugin-catalog-react';
import { useNavigate } from 'react-router';
import {
  RELATION_DEPENDENCY_OF,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { useSnackbarStore } from '../../../../../snackbar/stores/use-snackbar-store';

export const ProductVersionEntityPage = () => {
  const navigate = useNavigate();

  const addSnackbar = useSnackbarStore(state => state.addSnackbar);

  const { entity } = useEntity();
  const { entities: relatedEntities } = useRelatedEntities(entity, {
    kind: 'Component',
    type: RELATION_DEPENDENCY_OF,
  });

  useEffect(() => {
    if (!relatedEntities) return;

    const product = relatedEntities.find(e => e.spec?.type === 'product');

    if (product) {
      navigate(
        `/catalog/${product.metadata.namespace}/component/${product.metadata.name}?productVersion=${encodeURIComponent(stringifyEntityRef(entity))}`,
      );
    } else {
      addSnackbar(
        <span>
          Failed to redirect. Product related to this version was not found.
        </span>,
        'error',
      );
    }
  }, [relatedEntities, navigate, entity, addSnackbar]);

  return null;
};
