import { ReactElement } from 'react';
import { create } from 'zustand';
import { type CustomSnackbarProps } from '../custom-snackbar';

type SnackbarStore = {
  snackPack: Array<{
    message: ReactElement<any, any>;
    key: string;
    severity?: CustomSnackbarProps['severity'];
  }>;
  addSnackbar: (
    message: ReactElement<any, any>,
    severity?: CustomSnackbarProps['severity'],
  ) => void;
  removeSnackbar: (key: string) => void;
};

const initialState: Pick<SnackbarStore, 'snackPack'> = {
  snackPack: [],
};

export const useSnackbarStore = create<SnackbarStore>(set => ({
  ...initialState,
  addSnackbar: (message, severity) => {
    set(state => ({
      snackPack: [
        ...state.snackPack,
        {
          message,
          key: new Date().getTime().toString(),
          severity,
        },
      ],
    }));
  },
  removeSnackbar: key => {
    set(state => ({
      snackPack: state.snackPack.filter(snack => snack.key !== key),
    }));
  },
}));
