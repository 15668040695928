import React from 'react';
import { useRouteRef } from '@backstage/core-plugin-api';
import { RELATION_DEPENDENCY_OF, Entity } from '@backstage/catalog-model';
import {
  useEntity,
  useRelatedEntities,
  entityRouteRef,
} from '@backstage/plugin-catalog-react';
import {
  Breadcrumbs,
  BreadcrumbsItems,
} from '@internal/backstage-plugin-adeo-core-components-react';
import {
  HomeIcon,
  Package2Icon,
  DeployedCodeIcon,
  Inventory2Icon,
} from 'backstage-plugin-icons-react';
import { usePianoSendEvent } from '../../../../hooks/usePianoSendEvent';

type PackageBreadcrumbsItemsProps = {
  productEntity: Entity;
  isCompact?: boolean;
  disableNavigation?: boolean;
};

export const PackageBreadcrumbsItems = ({
  productEntity,
  isCompact,
  disableNavigation,
}: PackageBreadcrumbsItemsProps) => {
  const generateEntityRoute = useRouteRef(entityRouteRef);
  const { entities, loading, error } = useRelatedEntities(productEntity, {
    kind: 'Component',
    type: RELATION_DEPENDENCY_OF,
  });

  const packageEntity = entities?.find(ent => ent.spec?.type === 'package');

  const packageRoute = generateEntityRoute({
    kind: 'Component',
    namespace: packageEntity?.metadata.namespace ?? '',
    name: packageEntity?.metadata.name ?? '',
  });

  // Skip waiting route loading if we won't use it anyway
  if ((loading || error) && !disableNavigation) {
    return null;
  }

  return (
    <BreadcrumbsItems
      label={packageEntity?.metadata.title ?? ''}
      to={packageRoute}
      Icon={Inventory2Icon}
      isCompact={isCompact}
      disableNavigation={disableNavigation}
    />
  );
};

export const useProductVersionEntityBreadcrumbData = (
  productVersionEntity: Entity,
) => {
  const { entities, loading, error } = useRelatedEntities(
    productVersionEntity,
    {
      kind: 'Component',
      type: RELATION_DEPENDENCY_OF,
    },
  );

  const productEntity = entities?.find(ent => ent.spec?.type === 'product');

  const generateEntityRoute = useRouteRef(entityRouteRef);

  const productRoute = generateEntityRoute({
    kind: 'Component',
    namespace: productEntity?.metadata.namespace ?? '',
    name: productEntity?.metadata.name ?? '',
  });

  return { loading, error, productEntity, productRoute };
};

interface ControlledProductVersionEntityBreadcrumbProps {
  entity: Entity;
  includeLast: boolean;
  compact: boolean;
  disableNavigation?: boolean;
}

const ControlledProductVersionEntityBreadcrumb = ({
  entity,
  includeLast,
  compact,
  disableNavigation,
}: ControlledProductVersionEntityBreadcrumbProps) => {
  usePianoSendEvent('page.display', 'productVersion');

  const { productEntity, productRoute, loading, error } =
    useProductVersionEntityBreadcrumbData(entity);

  if (loading || error) {
    return null;
  }

  return (
    <Breadcrumbs>
      {...productEntity
        ? [
            <BreadcrumbsItems
              label="Home"
              to="/"
              Icon={HomeIcon}
              isCompact
              disableNavigation={disableNavigation}
            />,
            <PackageBreadcrumbsItems
              productEntity={productEntity}
              isCompact={compact}
              disableNavigation={disableNavigation}
            />,
            <BreadcrumbsItems
              label={productEntity?.metadata.title ?? ''}
              to={productRoute}
              Icon={Package2Icon}
              disableNavigation={disableNavigation}
            />,
            includeLast && (
              <BreadcrumbsItems
                label={entity.metadata.title ?? ''}
                Icon={DeployedCodeIcon}
                disableNavigation={disableNavigation}
                isLast
              />
            ),
          ]
        : []}
    </Breadcrumbs>
  );
};

ControlledProductVersionEntityBreadcrumb.displayName =
  'ControlledProductVersionEntityBreadcrumb';

const ProductVersionEntityBreadcrumb = () => {
  const { entity } = useEntity();

  return (
    <ControlledProductVersionEntityBreadcrumb
      entity={entity}
      includeLast
      compact={false}
    />
  );
};

ProductVersionEntityBreadcrumb.displayName = 'ProductVersionEntityBreadcrumb';

export {
  ProductVersionEntityBreadcrumb,
  ControlledProductVersionEntityBreadcrumb,
};
