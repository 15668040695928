import { TagIcon } from 'backstage-plugin-icons-react';
import { type MetricsMapFunction } from '../useMetrics';
import { createMetrics } from '../../../utils/createMetrics';
import { SubscriptionEntity } from '../../../types/SubscriptionEntity';
import { Entity } from '@backstage/catalog-model';
import { useDeploymentsStore } from '../../../components/catalog/pages/component/product/ref/deployments/store/deployments-store';
import { getComponentOrResourceAppliedManifest } from '../../../components/catalog/pages/component/product/ref/deployments/DeploymentDrawer/common/manifest-utils';
import { ComponentManifest } from '../../../types/applied-product-manifest/AppliedProductManifest';

const getComponentDockerImageTag = (
  entity: Entity,
  relatedSubscription?: SubscriptionEntity,
  relatedProductVersion?: Entity,
) => {
  if (!relatedSubscription || !relatedProductVersion)
    return {
      error: new Error(
        `Subscription or ProductVersion not found for component ${entity.metadata.title}`,
      ),
    };

  const appliedManifestOrError =
    getComponentOrResourceAppliedManifest<ComponentManifest>(
      entity,
      relatedSubscription,
      relatedProductVersion,
    );

  if ('error' in appliedManifestOrError) return appliedManifestOrError;

  return appliedManifestOrError.image?.tag;
};

export const getImageTagMetrics: MetricsMapFunction = (
  entity,
  _relatedEntities,
  _getEntityRoute,
) => {
  const imageTagOrError = getComponentDockerImageTag(
    entity,
    useDeploymentsStore.getState().subscription,
    useDeploymentsStore.getState().productVersion,
  );

  if (typeof imageTagOrError === 'object')
    return { metrics: [], error: imageTagOrError.error };

  if (!imageTagOrError)
    return {
      metrics: [],
      error: new Error(
        `Docker image tag not found for ${entity.metadata.title}`,
      ),
    };

  return {
    metrics: [createMetrics(imageTagOrError, 'Image Tag', { Icon: TagIcon })],
  };
};
