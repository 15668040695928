import { DeployedCodeIcon } from 'backstage-plugin-icons-react';
import { type MetricsMapFunction } from '../useMetrics';
import { createMetrics } from '../../../utils/createMetrics';
import { SubscriptionEntity } from '../../../types/SubscriptionEntity';
import { Entity } from '@backstage/catalog-model';
import { useDeploymentsStore } from '../../../components/catalog/pages/component/product/ref/deployments/store/deployments-store';
import { getComponentOrResourceAppliedManifest } from '../../../components/catalog/pages/component/product/ref/deployments/DeploymentDrawer/common/manifest-utils';
import { MongoDbManifest } from '../../../types/applied-product-manifest/MonogDbManifest';
import { PostgresManifest } from '../../../types/applied-product-manifest/PostgresManifest';

const getResourceVersion = (
  entity: Entity,
  relatedSubscription?: SubscriptionEntity,
  relatedProductVersion?: Entity,
) => {
  if (!relatedSubscription || !relatedProductVersion)
    return {
      error: new Error(
        `Subscription or ProductVersion not found for component ${entity.metadata.title}`,
      ),
    };

  const appliedManifestOrError = getComponentOrResourceAppliedManifest<
    MongoDbManifest | PostgresManifest
  >(entity, relatedSubscription, relatedProductVersion);

  if ('error' in appliedManifestOrError) return appliedManifestOrError;

  switch (entity.spec?.type) {
    case 'mongodb':
      return (appliedManifestOrError as MongoDbManifest).mongoVersion;
    case 'postgresql':
      return (appliedManifestOrError as PostgresManifest).pgVersion;
    default:
      return undefined;
  }
};

export const getResourceVersionMetrics: MetricsMapFunction = (
  entity,
  _relatedEntities,
  _getEntityRoute,
) => {
  const versionOrError = getResourceVersion(
    entity,
    useDeploymentsStore.getState().subscription,
    useDeploymentsStore.getState().productVersion,
  );

  if (typeof versionOrError === 'object')
    return { metrics: [], error: versionOrError.error };

  if (!versionOrError)
    return {
      metrics: [],
      error: new Error(`Version not found for ${entity.metadata.title}`),
    };

  return {
    metrics: [
      createMetrics(versionOrError, 'Version', { Icon: DeployedCodeIcon }),
    ],
  };
};
