import { Package2Icon } from 'backstage-plugin-icons-react';
import { type MetricsMapFunction } from '../useMetrics';
import { createMetrics } from '../../../utils/createMetrics';
import { SubscriptionEntity } from '../../../types/SubscriptionEntity';
import { Entity } from '@backstage/catalog-model';
import { useDeploymentsStore } from '../../../components/catalog/pages/component/product/ref/deployments/store/deployments-store';
import { getComponentOrResourceAppliedStatus } from '../../../components/catalog/pages/component/product/ref/deployments/DeploymentDrawer/common/manifest-utils';
import { MongoDbAppliedStatus } from '../../../types/applied-status/AppliedStatus';

const getDBAPIProject = (
  entity: Entity,
  relatedSubscription?: SubscriptionEntity,
  relatedProductVersion?: Entity,
) => {
  if (!relatedSubscription || !relatedProductVersion)
    return {
      error: new Error(
        `Subscription or ProductVersion not found for component ${entity.metadata.title}`,
      ),
    };

  const appliedStatusOrError =
    getComponentOrResourceAppliedStatus<MongoDbAppliedStatus>(
      entity,
      relatedSubscription,
      relatedProductVersion,
    );

  if ('error' in appliedStatusOrError) return appliedStatusOrError;

  return appliedStatusOrError.dbApiProject;
};

export const getDBAPIProjectMetrics: MetricsMapFunction = (
  entity,
  _relatedEntities,
  _getEntityRoute,
) => {
  const projectOrError = getDBAPIProject(
    entity,
    useDeploymentsStore.getState().subscription,
    useDeploymentsStore.getState().productVersion,
  );

  if (typeof projectOrError === 'object')
    return { metrics: [], error: projectOrError.error };

  if (!projectOrError)
    return {
      metrics: [],
      error: new Error(`DBAPI project not found for ${entity.metadata.title}`),
    };

  return {
    metrics: [
      createMetrics(projectOrError, 'DBAPI Project', { Icon: Package2Icon }),
    ],
  };
};
