import { useProductSubscriptionsAndVersions } from '../../../../../../hooks/useProductSubscriptionsAndVersions';
import { Entity } from '@backstage/catalog-model';
import { useMetrics } from '../../../../../../../../hooks/metrics/useMetrics';
import React, { useMemo } from 'react';
import { Box, Divider, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { GithubIcon } from 'backstage-plugin-icons-react';
import {
  EntityPresentation,
  EntityPresentationHeader,
  EntityPresentationMetrics,
  MoreActionsVert,
  Tag,
} from '@internal/backstage-plugin-adeo-core-components-react';
import BugReportIcon from '@material-ui/icons/BugReport';
import { InspectEntityDialog } from '@backstage/plugin-catalog-react';

interface ProductVersionActionsProps {
  productVersion: Entity;
}

const ProductVersionActions = ({
  productVersion,
}: ProductVersionActionsProps) => {
  const [inspectionDialogOpen, setInspectionDialogOpen] = React.useState(false);

  const handleInspectEntity = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setInspectionDialogOpen(true);
  };

  const onCloseInspectionDialog = () => {
    setInspectionDialogOpen(false);
  };

  return (
    <>
      <MoreActionsVert
        items={[
          {
            Icon: BugReportIcon,
            text: 'Inspect product version entity',
            action: handleInspectEntity,
          },
        ]}
      />

      {productVersion && (
        <InspectEntityDialog
          open={inspectionDialogOpen}
          entity={productVersion}
          onClose={onCloseInspectionDialog}
        />
      )}
    </>
  );
};

const useProductVersionDrawerHeader = makeStyles(theme => ({
  errorContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  entityPresentation: {
    flexGrow: 'initial',
  },
}));

interface ProductVersionDrawerHeaderProps {
  entity: Entity;
}

export const ProductVersionDrawerHeader = ({
  entity,
}: ProductVersionDrawerHeaderProps) => {
  const classes = useProductVersionDrawerHeader();

  const subscriptionsAndVersions = useProductSubscriptionsAndVersions(entity);

  const {
    loading: loadingMetrics,
    error: errorMetrics,
    metrics,
  } = useMetrics(entity, ['components', 'resources']);

  const tags = useMemo(() => {
    const sourceLocation =
      entity.metadata?.annotations?.['backstage.io/source-location'].replace(
        'url:',
        '',
      ) ?? '';

    const subscriptionTag = subscriptionsAndVersions?.map(
      ({ subscription }) => {
        const project =
          subscription?.metadata?.annotations?.['aap.adeo.cloud/project'];
        return {
          label: (
            <>
              Deployed in{' '}
              <Box component="span" fontWeight="fontWeightBold">
                {project}
              </Box>
            </>
          ),
          variant: 'outlined',
          color: 'primary',
        };
      },
    );
    return [
      ...subscriptionTag,
      {
        icon: GithubIcon,
        variant: 'outlined',
        label: sourceLocation.replace('https://github.com/', ''),
        link: sourceLocation,
      },
    ] as Tag[];
  }, [entity, subscriptionsAndVersions]);

  const type = 'Product Version';

  return (
    <EntityPresentation size="medium" className={classes.entityPresentation}>
      <EntityPresentationHeader
        title={entity.metadata.title ?? ''}
        type={type}
        tags={tags}
        actionButton={<ProductVersionActions productVersion={entity} />}
      />
      <Divider />
      {errorMetrics ? (
        <Alert severity="error" className={classes.errorContainer}>
          {errorMetrics.message}
        </Alert>
      ) : (
        <EntityPresentationMetrics metrics={metrics} loading={loadingMetrics} />
      )}
      <Divider />
    </EntityPresentation>
  );
};
