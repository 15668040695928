import { Avatar, makeStyles } from '@material-ui/core';
import React from 'react';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { UserEntity } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { SubscriptionEntity } from '../../types/SubscriptionEntity';
import { SUBSCRIPTION_DEPLOYED_BY_ANNOTATION } from 'custom-annotations';
import { useQuery } from '@tanstack/react-query';
import { Skeleton } from '@material-ui/lab';

const useDeploymentOwnerStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  secondaryText: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  ownerName: {
    fontWeight: 700,
    marginLeft: theme.spacing(0.5),
  },
  avatar: {
    marginLeft: theme.spacing(2),
  },
  skeletonContainer: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));

interface DeploymentOwnerProps {
  subscription: SubscriptionEntity;
}

export const DeploymentOwner = ({ subscription }: DeploymentOwnerProps) => {
  const classes = useDeploymentOwnerStyles();

  const catalogApi = useApi(catalogApiRef);

  const ownerLdap =
    subscription.metadata.annotations?.[SUBSCRIPTION_DEPLOYED_BY_ANNOTATION];

  const { data: deployer, isFetching } = useQuery<UserEntity | undefined>({
    queryKey: ['user', ownerLdap],
    queryFn: async ({ queryKey }) => {
      if (!queryKey[1]) return undefined;

      const { items } = await catalogApi.getEntities({
        filter: {
          kind: 'User',
          'metadata.name': ownerLdap as string,
        },
      });

      if (items.length !== 1) return undefined;

      return items[0] as UserEntity;
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const deployerName = (deployer as UserEntity)?.spec?.profile?.displayName;

  return (
    <div className={classes.root}>
      {isFetching ? (
        <div className={classes.skeletonContainer}>
          <Skeleton variant="text" width={135} />
          <Skeleton variant="circle" width={40} height={40} />
        </div>
      ) : (
        <>
          <span className={classes.secondaryText}>
            <span>Deployed by</span>
            <span className={classes.ownerName}>
              {deployerName ?? 'unknown user'}
            </span>
          </span>
          <Avatar
            className={classes.avatar}
            src={deployer?.spec.profile?.picture}
          >
            {deployerName?.[0] ?? '?'}
          </Avatar>
        </>
      )}
    </div>
  );
};
