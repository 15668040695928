import { SectionContentListItem } from '../SectionContent';
import React from 'react';
import { Alert } from '@material-ui/lab';
import { makeStyles, Typography } from '@material-ui/core';
import isEmpty from 'lodash.isempty';

const useSectionContentWithHeaderSecretRendererStyles = makeStyles(theme => ({
  kafkaSectionTitle: {
    gridColumn: 'span 2',
    marginBottom: theme.spacing(1),
    '&:not(:first-child)': {
      marginTop: theme.spacing(1),
    },
  },
}));

interface SectionContentWithHeaderSecretRendererProps {
  secrets: string[] | Record<string, string[]>;
  error?: Error;
  resourceType: string;
  emptySecretsMessage: string;
}

/**
 * Renders a list of secrets from a array of string or an object with string arrays
 * Object with string arrays is used for kafka topics which requires to render secrets in different sections
 */
export const SectionContentWithHeaderSecretRenderer = ({
  secrets,
  error,
  resourceType,
  emptySecretsMessage,
}: SectionContentWithHeaderSecretRendererProps) => {
  const classes = useSectionContentWithHeaderSecretRendererStyles();

  if (error) {
    return <Alert severity="warning">{error.message}</Alert>;
  }

  if (!isEmpty(secrets)) {
    if (resourceType === 'kafkaTopic') {
      return (
        <>
          <Typography variant="overline" className={classes.kafkaSectionTitle}>
            Cluster secrets
          </Typography>
          {(secrets as Record<string, string[]>).cluster.map(
            (secret, index) => (
              <SectionContentListItem
                showLineNumber
                value={secret}
                index={index}
                key={index}
              />
            ),
          )}
          <Typography variant="overline" className={classes.kafkaSectionTitle}>
            Registry secrets
          </Typography>
          {(secrets as Record<string, string[]>).registry.map(
            (secret, index) => (
              <SectionContentListItem
                showLineNumber
                value={secret}
                index={index}
                key={index}
              />
            ),
          )}
          <Typography variant="overline" className={classes.kafkaSectionTitle}>
            Topic secrets
          </Typography>
          {(secrets as Record<string, string[]>).topic.map((secret, index) => (
            <SectionContentListItem
              showLineNumber
              value={secret}
              index={index}
              key={index}
            />
          ))}
        </>
      );
    }

    return (secrets as string[])?.map((secret, index) => (
      <SectionContentListItem
        showLineNumber
        value={secret}
        index={index}
        key={index}
      />
    ));
  }

  return <Alert severity="info">{emptySecretsMessage}</Alert>;
};
