export const OLD_TANGRAM_ID_ANNOTATION =
  'packaging.adeo.cloud/product-external-id';
export const TANGRAM_ID_ANNOTATION = 'aap.adeo.cloud/product-external-id';
export const SOURCE_CODE_URL_ANNOTATION = 'backstage.io/source-location';
export const GITHUB_REPO_SLUG_ANNOTATION = 'github.com/project-slug';
export const SUBSCRIPTION_TYPE_ANNOTATION = 'aap.adeo.cloud/subscription-type';
export const SUBSCRIPTION_PROJECT_ANNOTATION = 'aap.adeo.cloud/project';
export const ENTITY_VERSION_COMMIT_ID_ANNOTATION = 'aap.adeo.cloud/commit-id';
export const ENTITY_VERSION_COMMIT_MESSAGE_ANNOTATION =
  'aap.adeo.cloud/commit-message';
export const ENTITY_VERSION_REF_NAME_ANNOTATION =
  'aap.adeo.cloud/version-ref-name';
export const OLD_ARGO_APP_NAME_ANNOTATION = 'packaging.adeo.com/component-name';
export const ARGO_APP_NAME_ANNOTATION = 'aap.adeo.cloud/component-name';
export const PACKAGE_VERSION_ID_ANNOTATION =
  'aap.adeo.cloud/package-version-id';
export const SUBSCRIPTION_DEPLOYED_BY_ANNOTATION =
  'aap.adeo.cloud/deployed-by-ldap';
export const COMPONENT_VERSION_ANNOTATION = 'aap.adeo.cloud/docker-image-tag';
