import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { ArrowRightAltIcon } from 'backstage-plugin-icons-react';
import { useSubscriptionProduct } from '../hooks/useSubscriptionProduct';
import { useNavigate } from 'react-router';
import Skeleton from '@material-ui/lab/Skeleton';

export const DeploymentActions = ({
  subscription,
}: {
  subscription: Entity;
}) => {
  const navigate = useNavigate();
  const { data: product, isFetching } = useSubscriptionProduct(subscription);

  const deploymentRoute = product
    ? `/catalog/${product.metadata.namespace}/${product.kind}/${product.metadata.name}/deployments?subscription=${encodeURIComponent(stringifyEntityRef(subscription))}`
    : null;

  const onClick = () => {
    if (deploymentRoute) navigate(deploymentRoute);
  };

  return isFetching ? (
    <Skeleton
      variant="text"
      width={20}
      data-testid="deployment-action-skeleton"
    />
  ) : (
    <GridActionsCellItem
      key="see-deployment"
      icon={<ArrowRightAltIcon />}
      color="primary"
      label="See deployment"
      onClick={onClick}
    />
  );
};
