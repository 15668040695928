import { Button, makeStyles } from '@material-ui/core';
import { Entity } from '@backstage/catalog-model';
import React, { useEffect, useState } from 'react';
import { parseComponentOrResourceName } from '../manifest-utils';
import { EntitySelector } from '../../../../../../../../../common/EntitySelector';
import { Alert, Skeleton } from '@material-ui/lab';
import { useVaultUrl } from './useVaultUrl';
import { useDelayedLoading } from '../../../../../../../../../../hooks/useDelayedLoading';

const useGenericSecretsContentHeaderStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    minHeight: theme.typography.pxToRem(40),
  },
}));

interface GenericSecretsContentHeaderProps {
  onChange: (entity: Entity) => void;
  defaultResourceName?: string;
  selectorEntities?: Entity[];
  noEntitiesMessage: string;
  hasSecrets: boolean;
  isLoading: boolean;
}

const renderVault = (
  { error, data }: { error?: Error; data?: string },
  hasSecrets: boolean,
) => {
  if (data) {
    return (
      <Button
        variant="outlined"
        color="primary"
        onClick={() => window.open(data, '_blank', 'rel="noreferrer noopener"')}
      >
        See secrets in Vault
      </Button>
    );
  } else if (!hasSecrets) {
    return null;
  } else if (error) {
    return <Alert severity="error">{error?.message}</Alert>;
  }

  return null;
};

export const GenericSecretsContentHeader = ({
  onChange,
  defaultResourceName,
  hasSecrets,
  selectorEntities,
  noEntitiesMessage,
  isLoading,
}: GenericSecretsContentHeaderProps) => {
  const classes = useGenericSecretsContentHeaderStyles();

  const [currentEntity, setCurrentEntity] = useState<Entity | undefined>();

  const loading = useDelayedLoading(isLoading);

  useEffect(() => {
    if (selectorEntities?.length) {
      // default resource should be the first one to actually have secrets
      // if none just take the first one
      const newCurrentResource =
        selectorEntities.find(
          resource =>
            parseComponentOrResourceName(resource) === defaultResourceName,
        ) ?? selectorEntities[0];

      setCurrentEntity(newCurrentResource);
      onChange(newCurrentResource);
    }
  }, [selectorEntities, onChange, defaultResourceName]);

  const onEntityChange = (entity: Entity) => {
    setCurrentEntity(entity);
    onChange(entity);
  };

  const vaultPath = useVaultUrl(currentEntity);

  if (!isLoading && !selectorEntities?.length) {
    return <Alert severity="info">{noEntitiesMessage}</Alert>;
  }

  if (loading || !currentEntity) {
    return (
      <div className={classes.root}>
        <Skeleton width={300} height={40} variant="rect" />
        <Skeleton width={190} height={40} variant="rect" />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <EntitySelector
        entities={selectorEntities ?? []}
        value={currentEntity}
        onChange={onEntityChange}
      />
      {renderVault(vaultPath, hasSecrets)}
    </div>
  );
};

export type SecretsContentHeaderProps = Omit<
  GenericSecretsContentHeaderProps,
  'selectorEntities' | 'noEntitiesMessage' | 'isLoading'
>;
