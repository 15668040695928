import React from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import { type FieldValues } from 'react-hook-form';
import { type ManualAction } from '../../../../../../../hooks/useManualActions';

type ManualActionInputProps = {
  field: FieldValues;
  fieldProperty: ManualAction['stepTemplate']['fields'][0];
  disabled: boolean;
  errorText?: string;
  refCallback?: (ref: HTMLInputElement | null) => void;
};

export const ManualActionInput = (props: ManualActionInputProps) => {
  const { field, fieldProperty, disabled, errorText, refCallback } = props;

  if (fieldProperty.type === 'boolean') {
    return (
      <FormControl
        variant="outlined"
        fullWidth
        size="small"
        error={Boolean(errorText)}
      >
        <InputLabel id={`${fieldProperty.key}-label`}>
          {fieldProperty.key}
        </InputLabel>
        <Select
          {...field}
          labelId={`${fieldProperty.key}-label`}
          id={fieldProperty.key}
          label={fieldProperty.key}
          disabled={disabled}
          inputRef={refCallback}
          defaultValue=""
        >
          <MenuItem value="true">True</MenuItem>
          <MenuItem value="false">False</MenuItem>
        </Select>
        {errorText && <FormHelperText>{errorText}</FormHelperText>}
      </FormControl>
    );
  }

  return (
    <TextField
      {...field}
      inputRef={refCallback}
      disabled={disabled}
      key={fieldProperty.key}
      id={fieldProperty.key}
      label={fieldProperty.key}
      type={fieldProperty.type}
      size="small"
      error={Boolean(errorText)}
      helperText={errorText}
      variant="outlined"
      fullWidth
    />
  );
};
