import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useApi } from '@backstage/core-plugin-api';
import { Box, makeStyles, Typography } from '@material-ui/core';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { useSidebarOpenState } from '@backstage/core-components';
import RecentlyVisitedEntitiesApi, {
  RecentlyVisitedEntity,
} from './RecentlyVisitedEntitiesApi';
import { RecentlyVisitedItem } from './RecentlyVisitedItem';
import { ScrollArea } from '@internal/backstage-plugin-adeo-core-components-react';
import { InboxIcon } from 'backstage-plugin-icons-react';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

const RECENTLY_VISITED_TYPE_BLACKLIST = ['productVersion'];

type EnrichedRecentlyVisitedEntity = RecentlyVisitedEntity & {
  entity: Entity;
};

const useRecentlyVisitedStyle = makeStyles(theme => ({
  root: {
    color: theme.palette.navigation.color,
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  scheduleIcon: {
    fontSize: (props: { isOpen: boolean }) => (props.isOpen ? '.9rem' : ''),
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 700,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    background: '#223644',
    justifyContent: (props: { isOpen: boolean }) =>
      props.isOpen ? 'space-between' : 'center',
    flexShrink: 0,
  },
  emptyStateContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
}));

export const RecentlyVisitedEntities = () => {
  const { isOpen } = useSidebarOpenState();
  const classes = useRecentlyVisitedStyle({ isOpen });

  const { pathname } = useLocation();

  const catalogApi = useApi(catalogApiRef);

  const [visits, setVisits] = useState<RecentlyVisitedEntity[]>([]);

  const { data: enrichedVisitedEntities } = useQuery<
    EnrichedRecentlyVisitedEntity[]
  >({
    queryKey: [
      'recentlyVisitedEntities',
      visits?.map(visit => visit.entityRef.toLowerCase()),
    ],
    queryFn: async () => {
      const { items } = await catalogApi.getEntitiesByRefs({
        entityRefs: visits.map(visit => visit.entityRef.toLowerCase()),
        fields: [
          'kind',
          'metadata.name',
          'metadata.namespace',
          'metadata.title',
          'spec.profile.displayName',
          'spec.type',
        ],
      });

      const validEntities = items.filter((item): item is Entity =>
        Boolean(item),
      );

      const enrichedEntities: EnrichedRecentlyVisitedEntity[] =
        validEntities.map(entity => ({
          entity,
          ...(visits.find(
            visit =>
              visit.entityRef.toLowerCase() === stringifyEntityRef(entity),
          ) as RecentlyVisitedEntity),
        }));

      return enrichedEntities;
    },
    enabled: !!visits.length,
    placeholderData: keepPreviousData,
  });

  const navigate = useNavigate();

  // On navigation, save the entity to the list
  useEffect(() => {
    // check if pathname is a catalog entity and not the catalog root
    if (pathname.includes('/catalog/') && pathname !== '/catalog/') {
      const pathnameParts = pathname.split('/');
      const entityRef = `${pathnameParts[3]}:${pathnameParts[2]}/${pathnameParts[4]}`;

      // prevent to add an entity to the list if the entity doesn't exist on backstage
      catalogApi.getEntityByRef(entityRef).then((entity: any) => {
        if (
          entity &&
          !RECENTLY_VISITED_TYPE_BLACKLIST.includes(entity.spec.type)
        ) {
          RecentlyVisitedEntitiesApi.save({
            pathname: pathname,
            entityRef: entityRef,
          });
        }
        setVisits(RecentlyVisitedEntitiesApi.list());
      });
    }
  }, [pathname, catalogApi]);

  // Fetch list in case it's the first render
  useEffect(() => {
    setVisits(RecentlyVisitedEntitiesApi.list());
  }, []);

  const onDelete = (entityRef: string, wasSelected: boolean) => {
    const newVisits = RecentlyVisitedEntitiesApi.list();

    if (wasSelected) {
      const deletedIndex = visits.findIndex(v => v.entityRef === entityRef);

      if (newVisits.length === 0) {
        navigate('/');
      } else if (deletedIndex === newVisits.length) {
        // if deleted element was the last one, navigate to the new last one
        navigate(newVisits[newVisits.length - 1].pathname);
      } else {
        // element is not last and not the only in the list so another should have replaced him at his index
        navigate(newVisits[deletedIndex].pathname);
      }
    }

    setVisits(newVisits);
  };

  return (
    <Box className={classes.root}>
      <Box py={1} px={2} className={classes.titleContainer}>
        {isOpen && (
          <Typography
            variant="caption"
            component="span"
            className={classes.title}
          >
            Recently open
          </Typography>
        )}
        <ScheduleIcon className={classes.scheduleIcon} fontSize="small" />
      </Box>
      {enrichedVisitedEntities?.length ? (
        <ScrollArea childrenBackgroundTone="dark">
          {enrichedVisitedEntities.map(enrichedVisitedEntity => (
            <RecentlyVisitedItem
              enrichedVisitedEntity={enrichedVisitedEntity}
              key={enrichedVisitedEntity.entityRef}
              onDelete={onDelete}
            />
          ))}
        </ScrollArea>
      ) : (
        isOpen && (
          <Box component="span" className={classes.emptyStateContainer} mt={2}>
            <InboxIcon />
            <Box component="span" mt={1}>
              No recent entity
            </Box>
          </Box>
        )
      )}
    </Box>
  );
};
