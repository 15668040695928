import { RELATION_OWNED_BY, type GroupEntity } from '@backstage/catalog-model';
import { getEntityRelations } from '@backstage/plugin-catalog-react';
import Group from '@material-ui/icons/Group';
import { MetricsMapFunction } from './useMetrics';
import { createMetrics } from '../../utils/createMetrics';

export const getOwnerMetrics: MetricsMapFunction = (
  entity,
  relatedEntities,
  getEntityRoute,
) => {
  const ownerReferences = getEntityRelations(entity, RELATION_OWNED_BY);
  if (ownerReferences.length === 0)
    return {
      metrics: [
        {
          label: 'Owner',
          error: new Error(`No owner for the entity ${entity.metadata.name}`),
        },
      ],
    };

  const owner = relatedEntities.find(
    relatedEntity => relatedEntity.metadata.name === ownerReferences[0].name,
  ) as GroupEntity;

  if (!owner)
    return {
      metrics: [
        {
          label: 'Owner',
          error: new Error(
            `No owner entity found for the entity ${entity.metadata.name}`,
          ),
        },
      ],
    };

  if (!owner.spec?.profile?.displayName)
    return {
      metrics: [
        {
          label: 'Owner',
          error: new Error(
            `No owner name found for the entity ${owner.metadata.name}`,
          ),
        },
      ],
    };

  const metric = createMetrics(owner.spec?.profile?.displayName, 'Owner', {
    Icon: Group,
    link: getEntityRoute(owner),
  });

  return { metrics: [metric] };
};
