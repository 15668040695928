import { RELATION_DEPENDS_ON } from '@backstage/catalog-model';
import { getEntityRelations } from '@backstage/plugin-catalog-react';
import { DeployedCodeIcon } from 'backstage-plugin-icons-react';
import { type MetricsMapFunction } from './useMetrics';
import { createMetrics } from '../../utils/createMetrics';

export const getComponentsMetrics: MetricsMapFunction = (
  entity,
  _relatedEntities,
  _getEntityRoute,
) => {
  if (entity.spec?.type !== 'product' && entity.spec?.type !== 'productVersion')
    return {
      metrics: [
        {
          label: 'Components',
          error: new Error(
            'You cannot display components for a non-product or a non-product-version entity',
          ),
        },
      ],
    };

  const componentsReferences = getEntityRelations(entity, RELATION_DEPENDS_ON, {
    kind: 'component',
  });
  const metric = createMetrics(componentsReferences.length, 'Components', {
    Icon: DeployedCodeIcon,
  });

  return { metrics: [metric] };
};
