import { useQuery } from '@tanstack/react-query';
import { RELATION_OWNED_BY } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useUserInfo } from '../../../utils/useUserInfo';
import { DELETED_SUBSCRIPTION_LIFECYCLE } from '../../../types/SubscriptionEntity';

export const useMyDeployments = () => {
  const catalogApi = useApi(catalogApiRef);

  const { value: user } = useUserInfo();

  return useQuery({
    queryKey: ['deployments', user?.ldap],
    queryFn: async () => {
      const { items } = await catalogApi.getEntities({
        filter: {
          kind: 'Component',
          'spec.type': 'subscription',
          [`relations.${RELATION_OWNED_BY}`]: user!.ownershipEntityRefs,
        },
      });

      return items.filter(
        item => item.spec?.lifecycle !== DELETED_SUBSCRIPTION_LIFECYCLE,
      );
    },
    enabled: !!user,
  });
};
