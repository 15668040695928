import React from 'react';
import {
  makeStyles,
  TextField,
  InputAdornment,
  MenuItem,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

export type ProductVersionFilter = 'all' | 'feature-branch' | 'release';

export type ProductVersionSelectorAndSearchProps = {
  filter: ProductVersionFilter;
  onFilterChange: (filter: ProductVersionFilter) => void;
  searchQuery: string;
  onSearchQueryChange: (query: string) => void;
};

type optionType = {
  value: ProductVersionSelectorAndSearchProps['filter'];
  label: string;
};

const useProductVersionSelectorAndSearchStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  selectFormControl: {
    flex: 1,
    background: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
  searchFormControl: {
    flex: 3,
  },
  searchInput: {
    background: theme.palette.background.paper,
  },
  searchIcon: {
    color: theme.palette.text.hint,
  },
}));

const options = [
  { value: 'all', label: 'All product versions / branch' },
  { value: 'feature-branch', label: 'Feature Branch' },
  { value: 'release', label: 'Product versions' },
] as const satisfies optionType[];

export const ProductVersionSelectorAndSearch = (
  props: ProductVersionSelectorAndSearchProps,
) => {
  const { filter, onFilterChange, searchQuery, onSearchQueryChange } = props;
  const classes = useProductVersionSelectorAndSearchStyles();

  return (
    <div className={classes.root}>
      <TextField
        select
        variant="outlined"
        name="filter"
        className={classes.selectFormControl}
        value={filter}
        onChange={event =>
          onFilterChange(
            event.target
              .value as ProductVersionSelectorAndSearchProps['filter'],
          )
        }
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        variant="outlined"
        placeholder="Search a product version or a branch"
        className={classes.searchFormControl}
        value={searchQuery}
        InputProps={{
          className: classes.searchInput,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon className={classes.searchIcon} />
            </InputAdornment>
          ),
        }}
        onChange={event => onSearchQueryChange(event.target.value)}
      />
    </div>
  );
};
