import { Entity } from '@backstage/catalog-model';
import { useMetrics } from '../../../hooks/metrics/useMetrics';
import React from 'react';
import {
  EntityPresentationMetricsItem,
  EntityPresentationMetricsSkeleton,
} from '@internal/backstage-plugin-adeo-core-components-react';
import { useSubscriptionProductVersion } from '../hooks/useSubscriptionProductVersion';

const Product = ({ productVersion }: { productVersion: Entity }) => {
  const { loading: loadingMetrics, metrics } = useMetrics(productVersion, [
    'parentProduct',
  ]);

  return loadingMetrics ? (
    <EntityPresentationMetricsSkeleton />
  ) : (
    <EntityPresentationMetricsItem {...metrics[0]} hideLabel overflowEllipsis />
  );
};

export const ProductCell = ({ subscription }: { subscription: Entity }) => {
  const { data: productVersion } = useSubscriptionProductVersion(subscription);

  return productVersion && <Product productVersion={productVersion} />;
};
