import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { EntityPresentationMetricsSkeleton } from './EntityPresentationMetricsSkeleton';

import {
  type Metric,
  EntityPresentationMetricsItem,
} from '../EntityPresentationMetricsItem';

/**
 * Props for {@link EntityPresentationMetrics}.
 *
 * @public
 */
export type EntityPresentationMetricsProps = {
  metrics: Metric[];
  loading?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const useEntityPresentationStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
}));

/**
 * Displays metrics for an entity presentation.
 *
 * @remarks
 *
 * This component displays metrics for an entity presentation. Each metric is displayed as a label
 * and a value. This component is intended to be used within an {@link EntityPresentation}.
 *
 * @public
 *
 */
export const EntityPresentationMetrics = forwardRef<
  HTMLDivElement,
  EntityPresentationMetricsProps
>((props, ref) => {
  const { metrics, loading, ...restProps } = props;
  const classes = useEntityPresentationStyles();
  return (
    <div ref={ref} className={classes.root} {...restProps}>
      {loading ? (
        <EntityPresentationMetricsSkeleton />
      ) : (
        metrics.map((metric, index) => (
          <div className={classes.item} key={metric.label}>
            <EntityPresentationMetricsItem {...metric} />
            {index < metrics.length - 1 && (
              <Divider orientation="vertical" flexItem />
            )}
          </div>
        ))
      )}
    </div>
  );
});

EntityPresentationMetrics.displayName = 'EntityPresentationMetrics';
