import { makeStyles, Typography } from '@material-ui/core';
import { Entity } from '@backstage/catalog-model';
import React, { useRef } from 'react';
import { useProductVersions } from '../../useProductVersions';
import { ControlledProductVersionEntityBreadcrumb } from '../../../../../../entity-layout/entity-breadcrumbs/ProductVersionEntityBreadcrumb';
import { useProductVersionDrawerStore } from './product-version-drawer-store';
import { EntitySelector } from '../../../../../../../common/EntitySelector';
import { useEntity } from '@backstage/plugin-catalog-react';

const useDrawerBreadcrumbStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  breadcrumbDivider: {
    display: 'flex',
    margin: `0 ${theme.spacing(1)}px`,
    userSelect: 'none',
  },
  selectValue: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

interface ProductVersionDrawerBreadcrumbProps {
  entity: Entity;
}

export const ProductVersionDrawerBreadcrumb = ({
  entity,
}: ProductVersionDrawerBreadcrumbProps) => {
  const { entity: product } = useEntity();
  const initialEntity = useRef(entity);

  const classes = useDrawerBreadcrumbStyles();
  const { entities: productVersions } = useProductVersions(product);

  const setDrawerEntity = useProductVersionDrawerStore(
    state => state.changeEntity,
  );

  const onSelectChange = (selectedEntity: Entity) => {
    setDrawerEntity(selectedEntity);
  };

  return (
    <div className={classes.root}>
      <ControlledProductVersionEntityBreadcrumb
        entity={initialEntity.current}
        includeLast={false}
        compact
        disableNavigation
      />
      <Typography variant="body1" color="secondary">
        <span className={classes.breadcrumbDivider}>/</span>
      </Typography>
      {productVersions && productVersions?.length !== 0 && (
        <EntitySelector
          entities={productVersions}
          value={entity}
          onChange={onSelectChange}
        />
      )}
    </div>
  );
};
