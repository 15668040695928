import { makeStyles, Typography } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';

const useDeployProductDialogFormSectionStyles = makeStyles(theme => ({
  sectionIndex: {
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    marginRight: theme.spacing(1),
  },
  sectionTitle: {
    fontWeight: 'bold',
  },
}));

interface DeployProductDialogFormSectionProps extends PropsWithChildren {
  index: number;
  title: string;
}

export const DeployProductDialogFormSection = ({
  index,
  title,
  children,
}: DeployProductDialogFormSectionProps) => {
  const classes = useDeployProductDialogFormSectionStyles();

  return (
    <>
      <div>
        <Typography
          variant="body2"
          className={classes.sectionIndex}
          display="inline"
        >
          {index}
        </Typography>
        <Typography
          variant="body2"
          className={classes.sectionTitle}
          display="inline"
        >
          {title}
        </Typography>
      </div>
      {children}
    </>
  );
};
