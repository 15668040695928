import { makeStyles, TextField } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import { DeployProductFormInputs } from '../DeployProductDialog';
import clsx from 'clsx';

const useNameFieldStyles = makeStyles(theme => ({
  input: {
    marginTop: theme.spacing(2),
  },
}));

interface NameFieldProps {
  existingDeploymentOnDev?: boolean;
}

export const NameField = ({ existingDeploymentOnDev }: NameFieldProps) => {
  const classes = useNameFieldStyles();

  const {
    control,
    formState: { errors },
  } = useFormContext<DeployProductFormInputs>();

  return (
    <Controller
      name="subscriptionName"
      control={control}
      render={({ field }) => (
        <TextField
          id="subscriptionName"
          variant="outlined"
          label="Deployment name"
          fullWidth
          className={classes.input}
          error={!!errors.subscriptionName}
          helperText={errors.subscriptionName?.message as string | undefined}
          inputProps={{
            readOnly: !!existingDeploymentOnDev,
          }}
          InputProps={{
            className: clsx(!!existingDeploymentOnDev && 'Mui-disabled'),
          }}
          InputLabelProps={{
            className: clsx(!!existingDeploymentOnDev && 'Mui-disabled'),
          }}
          {...field}
        />
      )}
    />
  );
};
