import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useRelatedEntities } from '@backstage/plugin-catalog-react';
import {
  Entity,
  RELATION_DEPENDS_ON,
  RELATION_DEPENDENCY_OF,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { Alert } from '@material-ui/lab';
import { makeStyles, Typography, Button } from '@material-ui/core';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  getEntityIcon,
  IconText,
  Table,
} from '@internal/backstage-plugin-adeo-core-components-react';

import { useGetProductManualActionsQuery } from '../../../../../../hooks/useManualActions';
import {
  COMPONENT_VERSION_ANNOTATION,
  SUBSCRIPTION_PROJECT_ANNOTATION,
} from 'custom-annotations';
import { DELETED_SUBSCRIPTION_LIFECYCLE } from '../../../../../../../../types/SubscriptionEntity';

type ProductVersionDrawerContentProps = {
  entity: Entity;
  product: Entity;
};

const useProductVersionDrawerContent = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    padding: `0 ${theme.spacing(2)}px`,
  },
  errorContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1.3)}px !important`,
  },
  tableTitle: {
    marginBottom: theme.spacing(1),
  },
  bold: {
    fontWeight: 700,
  },
}));

export const ProductVersionDrawerContent = (
  props: ProductVersionDrawerContentProps,
) => {
  const { entity, product } = props;
  const navigate = useNavigate();
  const classes = useProductVersionDrawerContent();

  const manualActions = useGetProductManualActionsQuery({
    productId: product?.metadata.name,
  });

  const { entities } = useRelatedEntities(entity, {
    type: RELATION_DEPENDENCY_OF,
    kind: 'component',
  });

  const subscription = entities?.find((ent: Entity) => {
    return (
      ent.spec?.type === 'subscription' &&
      ent.spec?.lifecycle !== DELETED_SUBSCRIPTION_LIFECYCLE
    );
  });

  const nonResolvedManualActionsForSubscription =
    manualActions.data?.data?.filter(
      action =>
        action.subscriptionId === subscription?.metadata.name &&
        action.status === 'INITIALIZED',
    );

  const redirectToManualActions = () => {
    if (!subscription) return;
    navigate(
      `/catalog/${product.metadata.namespace}/component/${product.metadata.name}/deployments?manual-actions=${encodeURIComponent(stringifyEntityRef(subscription))}`,
    );
  };

  const { entities: componentEntities, error: componentError } =
    useRelatedEntities(entity, {
      kind: 'component',
      type: RELATION_DEPENDS_ON,
    });

  const { entities: resourceEntities, error: resourceError } =
    useRelatedEntities(entity, {
      kind: 'resource',
      type: RELATION_DEPENDS_ON,
    });

  const errors = componentError ?? resourceError;

  const componentColumns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'kind',
        headerName: 'Kind',
        width: 180,
        renderCell: (params: GridRenderCellParams) => (
          <IconText
            Icon={getEntityIcon(params.row.entity)}
            text={params.row.kind}
          />
        ),
      },
      { field: 'title', headerName: 'Name', flex: 1 },
      {
        field: 'version',
        headerName: 'Version',
        flex: 0.5,
        sortable: false,
      },
    ],
    [],
  );

  const resourceColumns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'kind',
        headerName: 'Kind',
        width: 180,
        renderCell: (params: GridRenderCellParams) => (
          <IconText
            Icon={getEntityIcon(params.row.entity)}
            text={params.row.kind}
          />
        ),
      },
      { field: 'title', headerName: 'Name', flex: 1 },
    ],
    [],
  );

  const componentRows = useMemo(
    () =>
      (componentEntities ?? [])?.map(componentEntity => ({
        id: componentEntity.metadata.uid,
        title: componentEntity.metadata.title,
        kind: (componentEntity.spec as { type: string })?.type,
        version:
          componentEntity.metadata.annotations?.[COMPONENT_VERSION_ANNOTATION],
        entity: componentEntity,
      })) ?? [],
    [componentEntities],
  );

  const resourcesRows = useMemo(
    () =>
      (resourceEntities ?? [])?.map(resourceEntity => ({
        id: resourceEntity.metadata.uid,
        title: resourceEntity.metadata.title,
        kind: (resourceEntity.spec as { type: string })?.type,
        entity: resourceEntity,
      })) ?? [],
    [resourceEntities],
  );

  const getCellClassName = useCallback(
    () => classes.tableCell,
    [classes.tableCell],
  );

  return (
    <div className={classes.root}>
      {nonResolvedManualActionsForSubscription &&
        nonResolvedManualActionsForSubscription.length > 0 && (
          <Alert
            className={classes.errorContainer}
            severity="error"
            action={
              <Button
                color="inherit"
                size="small"
                onClick={redirectToManualActions}
              >
                Resolve
              </Button>
            }
          >
            <Typography variant="body2">
              deployment of{' '}
              <span className={classes.bold}>{entity.metadata.title}</span> in
              project{' '}
              <span className={classes.bold}>
                {subscription?.metadata.annotations?.[
                  SUBSCRIPTION_PROJECT_ANNOTATION
                ] ?? 'Unknown project'}
              </span>{' '}
              requires{' '}
              <span className={classes.bold}>
                {nonResolvedManualActionsForSubscription.length === 1
                  ? '1 manual action.'
                  : `${nonResolvedManualActionsForSubscription.length} manual actions.`}
              </span>
            </Typography>
          </Alert>
        )}
      {componentEntities && componentEntities.length > 0 && (
        <div className={classes.tableContainer}>
          <Typography variant="body2" className={classes.tableTitle}>
            <b>Components</b>
          </Typography>
          <Table
            columns={componentColumns}
            rows={componentRows}
            rowHeight={70}
            autoHeight
            getCellClassName={getCellClassName}
            disableColumnMenu
            disableRowSelectionOnClick
          />
        </div>
      )}
      {resourceEntities && resourceEntities.length > 0 && (
        <div className={classes.tableContainer}>
          <Typography variant="body2" className={classes.tableTitle}>
            <b>Resources</b>
          </Typography>
          <Table
            columns={resourceColumns}
            rows={resourcesRows}
            rowHeight={70}
            autoHeight
            getCellClassName={getCellClassName}
            disableColumnMenu
            disableRowSelectionOnClick
          />
        </div>
      )}
      {errors && (
        <Alert severity="error" className={classes.errorContainer}>
          {errors.message}
        </Alert>
      )}
    </div>
  );
};
