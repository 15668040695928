import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

const useRenderBaseCellStyles = makeStyles(theme => ({
  verticalCenter: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  bold: {
    fontWeight: 'bold',
  },
}));

type RenderBaseCellProps = {
  value: string;
  isBold: boolean;
};

export const RenderBaseCell = ({ value, isBold }: RenderBaseCellProps) => {
  const classes = useRenderBaseCellStyles();

  return (
    <div className={classes.verticalCenter}>
      <Typography variant="body2" className={isBold ? classes.bold : ''}>
        {value}
      </Typography>
    </div>
  );
};
