import React, { useCallback, useEffect, useRef } from 'react';
import { IconButton, Slide, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { CustomSnackbar } from './custom-snackbar';
import { useSnackbarStore } from './stores/use-snackbar-store';

type SnackbarFactoryProps = {
  hideDuration?: number;
};

const MAX_DISPLAYED_SNACKS = 20;

const useSnackbarFactoryStyles = makeStyles(theme => ({
  snackbarContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    bottom: 0,
    right: 0,
    padding: theme.spacing(2),
    zIndex: theme.zIndex.snackbar,
    gap: theme.spacing(2),
    overflow: 'hidden',
  },
}));

export const SnackbarFactory = ({
  hideDuration = 4000,
}: SnackbarFactoryProps) => {
  const classes = useSnackbarFactoryStyles();
  const snackPack = useSnackbarStore(state => state.snackPack);
  const removeSnackbar = useSnackbarStore(state => state.removeSnackbar);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const handleClose = useCallback(
    (key: string) => () => {
      removeSnackbar(key);
    },
    [removeSnackbar],
  );

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (snackPack.length > 0) {
      timerRef.current = setTimeout(() => {
        removeSnackbar(snackPack[snackPack.length - 1].key);
      }, hideDuration);
    }
  }, [snackPack, removeSnackbar, hideDuration]);

  return (
    <div className={classes.snackbarContainer}>
      {snackPack.slice(-MAX_DISPLAYED_SNACKS).map(snack => (
        <CustomSnackbar
          key={snack.key}
          open
          message={snack.message}
          onClose={handleClose(snack.key)}
          TransitionComponent={Slide}
          severity={snack.severity}
          style={{
            position: 'relative',
            top: 0,
            right: 0,
          }}
          action={
            <IconButton
              aria-label="close"
              size="small"
              color="inherit"
              onClick={handleClose(snack.key)}
            >
              <CloseIcon />
            </IconButton>
          }
        />
      ))}
    </div>
  );
};
