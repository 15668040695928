import { useQuery } from '@tanstack/react-query';
import {
  Entity,
  RELATION_DEPENDS_ON,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import {
  catalogApiRef,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';

export const useSubscriptionProduct = (subscription: Entity) => {
  const catalogApi = useApi(catalogApiRef);

  return useQuery({
    queryKey: ['subscription', stringifyEntityRef(subscription), 'product'],
    queryFn: async () => {
      const relatedEntitiesRefs = getEntityRelations(
        subscription,
        RELATION_DEPENDS_ON,
        { kind: 'Component' },
      );

      const { items } = await catalogApi.getEntitiesByRefs({
        entityRefs: relatedEntitiesRefs.map(stringifyEntityRef),
      });

      return items.find(entity => entity?.spec?.type === 'product');
    },
  });
};
