import { type MetricsMapFunction } from '../useMetrics';
import { createMetrics } from '../../../utils/createMetrics';
import { SubscriptionEntity } from '../../../types/SubscriptionEntity';
import { Entity } from '@backstage/catalog-model';
import { useDeploymentsStore } from '../../../components/catalog/pages/component/product/ref/deployments/store/deployments-store';
import { getComponentOrResourceAppliedManifest } from '../../../components/catalog/pages/component/product/ref/deployments/DeploymentDrawer/common/manifest-utils';
import { KafkaTopicManifest } from '../../../types/applied-product-manifest/KafkaTopicManifest';
import React from 'react';

const getKafkaTopicConfidentiality = (
  entity: Entity,
  relatedSubscription?: SubscriptionEntity,
  relatedProductVersion?: Entity,
) => {
  if (!relatedSubscription || !relatedProductVersion)
    return {
      error: new Error(
        `Subscription or ProductVersion not found for component ${entity.metadata.title}`,
      ),
    };

  const appliedManifestOrError =
    getComponentOrResourceAppliedManifest<KafkaTopicManifest>(
      entity,
      relatedSubscription,
      relatedProductVersion,
    );

  if ('error' in appliedManifestOrError) return appliedManifestOrError;

  return appliedManifestOrError.confidentiality;
};

export const getKafkaTopicConfidentialityMetrics: MetricsMapFunction = (
  entity,
  _relatedEntities,
  _getEntityRoute,
) => {
  const confidentialityOrError = getKafkaTopicConfidentiality(
    entity,
    useDeploymentsStore.getState().subscription,
    useDeploymentsStore.getState().productVersion,
  );

  if (typeof confidentialityOrError === 'object')
    return { metrics: [], error: confidentialityOrError.error };

  if (!confidentialityOrError)
    return {
      metrics: [],
      error: new Error(
        `Confidentiality level not found for ${entity.metadata.title}`,
      ),
    };

  return {
    metrics: [
      createMetrics(confidentialityOrError, 'Confidentiality', {
        tooltipContent: (
          <span>
            You can find more information about confidentiality level{' '}
            <a
              href="https://github.com/adeo/adeo-data-streaming-platform/blob/master/docs/Governance/1-Kafka-Topics-Naming-Convention.md#confidentiality-and-privacy-levels"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              here
            </a>
          </span>
        ),
      }),
    ],
  };
};
