import React, { useMemo } from 'react';
import { type Entity } from '@backstage/catalog-model';
import { Divider, IconButton, Card, makeStyles } from '@material-ui/core';
import {
  type Tag,
  EntityPresentation,
  EntityPresentationDescription,
  EntityPresentationHeader,
  EntityPresentationMetrics,
} from '@internal/backstage-plugin-adeo-core-components-react';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { Alert } from '@material-ui/lab';

import { getLifecycleTag } from '../../../../../../utils/getLifecycleTag';
import { useEntityRoute } from '../../../../../../hooks/useEntityRoute';
import { useMetrics } from '../../../../../../hooks/metrics/useMetrics';
import { useNavigate } from 'react-router';
import { ManualAction } from '../../../../hooks/useManualActions';
import { ManualActionsPopup } from '../../../../../common/ManualActionsPopup';
import { useProductSubscriptionsAndVersions } from '../../../../hooks/useProductSubscriptionsAndVersions';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { SUBSCRIPTION_PROJECT_ANNOTATION } from 'custom-annotations';

export type PackageEntityProductCardProps = {
  entity: Entity;
  manualActions: ManualAction[];
};

const usePackageEntityProductCardStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  errorContainer: {
    margin: theme.spacing(2),
  },
  manualActionMessage: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
}));

export const PackageEntityProductCard = (
  props: PackageEntityProductCardProps,
) => {
  const { entity, manualActions } = props;
  const entityRoute = useEntityRoute(entity);
  const classes = usePackageEntityProductCardStyles();
  const navigate = useNavigate();

  const subscriptionsAndVersions = useProductSubscriptionsAndVersions(entity);

  const rows = useMemo(() => {
    const nonResolvedManualActionsForProduct = manualActions.filter(
      action =>
        action.productId === entity.metadata.name &&
        action.status === 'INITIALIZED',
    );

    const groupedBySubscriptionManualAction =
      nonResolvedManualActionsForProduct.reduce(
        (acc, action) => {
          if (!acc[action.subscriptionId]) {
            acc[action.subscriptionId] = [];
          }
          acc[action.subscriptionId].push(action);
          return acc;
        },
        {} as Record<string, ManualAction[]>,
      );

    return Object.entries(groupedBySubscriptionManualAction)
      .map(([subscriptionId, actions]) => {
        const subscriptionAndVersion = subscriptionsAndVersions.find(
          subAndVer =>
            subAndVer.subscription?.metadata?.name === subscriptionId,
        );

        return {
          title: subscriptionAndVersion?.productVersion?.metadata?.title ?? '',
          manualActions: actions.length,
          project:
            subscriptionAndVersion?.subscription?.metadata?.annotations?.[
              SUBSCRIPTION_PROJECT_ANNOTATION
            ] ?? 'Unknown project',
          route: subscriptionAndVersion?.subscription
            ? `/catalog/${entity.metadata.namespace}/component/${entity.metadata.name}/deployments?manual-actions=${encodeURIComponent(stringifyEntityRef(subscriptionAndVersion.subscription))}`
            : '',
        };
      })
      .filter(Boolean);
  }, [manualActions, entity, subscriptionsAndVersions]);

  const { metrics, loading, error } = useMetrics(entity, [
    'owner',
    'namespace',
  ]);

  const nonResolvedManualActionsForProduct = manualActions.filter(
    action =>
      action.productId === entity.metadata.name &&
      action.status === 'INITIALIZED',
  );

  const type = useMemo(() => entity.spec?.type?.toString() ?? '', [entity]);

  const lifecycle = entity.spec?.lifecycle?.toString() ?? '';
  const tags: Tag[] = [getLifecycleTag(lifecycle)];

  if (!entityRoute) {
    throw new Error('Entity route not found');
  }

  const handleClick = () => {
    navigate(entityRoute);
  };

  const button = (
    <IconButton onClick={handleClick}>
      <ArrowForward />
    </IconButton>
  );

  return (
    <Card>
      <EntityPresentation size="small">
        <div className={classes.content}>
          <EntityPresentationHeader
            title={entity.metadata.title ?? 'No title'}
            type={type}
            tags={tags}
            actionButton={button}
            targetLink={entityRoute}
          />
          {nonResolvedManualActionsForProduct.length > 0 && (
            <div className={classes.manualActionMessage}>
              <ManualActionsPopup rows={rows} />
            </div>
          )}
          <EntityPresentationDescription
            description={entity.metadata.description ?? 'No description'}
          />
        </div>
        <Divider />
        {error ? (
          <Alert severity="error" className={classes.errorContainer}>
            {error.message}
          </Alert>
        ) : (
          <EntityPresentationMetrics metrics={metrics} loading={loading} />
        )}
      </EntityPresentation>
    </Card>
  );
};

PackageEntityProductCard.displayName = 'PackageEntityProductCard';
