import { configApiRef, fetchApiRef, useApi } from '@backstage/core-plugin-api';
import { useMutation } from '@tanstack/react-query';
import { ZodError } from 'zod';

interface UseUpsertSubscriptionMutationOptions {
  httpMethod: 'PUT' | 'POST';
  onError: (error: Error) => void;
  onSuccess: (data: any) => void;
}

export const useUpsertSubscriptionMutation = ({
  httpMethod,
  onError,
  onSuccess,
}: UseUpsertSubscriptionMutationOptions) => {
  const configApi = useApi(configApiRef);
  const { fetch } = useApi(fetchApiRef);

  return useMutation({
    mutationFn: async (body: Record<string, any>) => {
      const response = await fetch(
        `${configApi.getString('backend.baseUrl')}/api/custom-endpoints/subscriptions`,
        {
          method: httpMethod,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
      );

      if (!response.ok) {
        const responseBody = await response.json();

        if (
          typeof responseBody.error === 'object' &&
          responseBody.error.name === 'ZodError'
        ) {
          throw new Error(
            `Validation error: ${(responseBody.error as ZodError).issues.map(issue => issue.message).join(', ')}`,
          );
        } else {
          throw new Error(responseBody.error);
        }
      }

      return;
    },
    onError,
    onSuccess,
  });
};
