import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ArgoIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24" titleAccess="ArgoIcon">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 12C2.5 6.75329 6.75329 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12C21.5 15.1629 19.9544 17.9654 17.5758 19.6925L16.8 13.2789C17.5174 12.864 18 12.0883 18 11.2C18 10.3117 17.5174 9.53605 16.8 9.12108V8.8C16.8 6.14903 14.651 4 12 4C9.34903 4 7.2 6.14903 7.2 8.8V9.12108C6.48264 9.53605 6 10.3117 6 11.2C6 12.0883 6.48264 12.864 7.2 13.2789L6.42424 19.6925C4.04558 17.9654 2.5 15.1629 2.5 12ZM6.2888 20.8123C3.40726 18.941 1.5 15.6936 1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12C22.5 15.6936 20.5927 18.941 17.7112 20.8123L18 23.2L15.288 18.4L15.6 23.2L13.092 18.4L12 23.2H11.994L10.896 18.4L8.4 23.2L8.7 18.4L6 23.2L6.2888 20.8123ZM17.4 11.2C17.4 10.6669 17.1683 10.1879 16.8 9.85834C16.4815 9.57331 16.061 9.4 15.6 9.4C14.6059 9.4 13.8 10.2059 13.8 11.2C13.8 12.1941 14.6059 13 15.6 13C16.061 13 16.4815 12.8267 16.8 12.5417C17.1682 12.2121 17.4 11.7331 17.4 11.2ZM7.2 9.85834C6.83175 10.1879 6.6 10.6669 6.6 11.2C6.6 11.7331 6.83175 12.2121 7.2 12.5417C7.51845 12.8267 7.93899 13 8.4 13C9.39411 13 10.2 12.1941 10.2 11.2C10.2 10.2059 9.39411 9.4 8.4 9.4C7.93899 9.4 7.51845 9.57331 7.2 9.85834ZM8.4 11.56C8.73137 11.56 9 11.2914 9 10.96C9 10.6286 8.73137 10.36 8.4 10.36C8.06863 10.36 7.8 10.6286 7.8 10.96C7.8 11.2914 8.06863 11.56 8.4 11.56ZM16.2 10.96C16.2 11.2914 15.9314 11.56 15.6 11.56C15.2686 11.56 15 11.2914 15 10.96C15 10.6286 15.2686 10.36 15.6 10.36C15.9314 10.36 16.2 10.6286 16.2 10.96Z"
      />
    </svg>
  </SvgIcon>
);
