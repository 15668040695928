import noDeploymentsLight from '../../../assets/empty-states/no-deployments-light.svg';
import noDeploymentsDark from '../../../assets/empty-states/no-deployments-dark.svg';
import React from 'react';
import { OwnershipSection } from './OwnershipSection';
import { Box, makeStyles, Typography } from '@material-ui/core';
import {
  getClassForStatus,
  getStatusColorStyles,
  StatusBadge,
} from '../../catalog/pages/component/product/ref/deployments/StatusBadge';
import { Skeleton } from '@material-ui/lab';
import { useMyDeploymentsStatusesCount } from '../hooks/useMyDeploymentsStatusesCount';
import { useMyDeployments } from '../hooks/useMyDeployments';
import clsx from 'clsx';
import { useThemeMode } from '@internal/backstage-plugin-adeo-core-components-react';

const useMyDeploymentsSectionStyles = makeStyles(theme => ({
  coloredStatusDistribution: {
    width: '100%',
    marginTop: theme.spacing(1),
    display: 'flex',
    gap: '2px',
  },
  coloredStatusDistributionPart: {
    height: '6px',
    ...getStatusColorStyles(theme),
    '&:first-child': {
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
    },
    '&:last-child': {
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
    },
  },
  contentContainer: {
    marginTop: 'auto',
  },
  statusesCount: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  counterContainer: {
    gap: theme.spacing(2),
  },
  counter: {
    display: 'flex',
    alignItems: 'center',
  },
  counterHovered: {
    border: '1px solid black',
  },
  count: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(18),
    marginRight: theme.spacing(0.5),
  },
  badge: {
    marginRight: theme.spacing(0.5),
  },
}));

const statusesOrder = [
  'Degraded',
  'Suspended',
  'Missing',
  'Progressing',
  'Healthy',
  'Unknown',
];

const StatusesSkeleton = () => (
  <>
    <Skeleton variant="text" width={100} />
    <Skeleton variant="text" width={100} />
    <Skeleton variant="text" width={100} />
  </>
);
const StatusesCountSkeleton = () => (
  <Box display="flex">
    <Typography>/</Typography>
    <Skeleton variant="text" width={30} />
  </Box>
);

export const MyDeploymentsSection = () => {
  const theme = useThemeMode();
  const classes = useMyDeploymentsSectionStyles();

  const { data: deployments, isFetching: isDeploymentsLoading } =
    useMyDeployments();

  const deploymentStatusesCount = useMyDeploymentsStatusesCount(deployments);

  const renderStatusesCount = () => {
    if (deploymentStatusesCount.isLoading) {
      return <StatusesSkeleton />;
    }

    if (deployments?.length === 0) {
      return (
        <div className={classes.counter}>
          <StatusBadge status="Unknown" className={classes.badge} />
          <Typography className={classes.count}>0</Typography>
        </div>
      );
    }

    return statusesOrder.map(status => {
      const statusValue = deploymentStatusesCount.data[status];

      return statusValue ? (
        <div className={classes.counter} key={status}>
          <StatusBadge status={status} className={classes.badge} />
          <Typography className={classes.count}>{statusValue}</Typography>
          <Typography variant="body1">{status}</Typography>
        </div>
      ) : null;
    });
  };

  return (
    <OwnershipSection
      illustration={
        <img
          src={theme === 'light' ? noDeploymentsLight : noDeploymentsDark}
          alt="Deployment illustration"
        />
      }
      title="My deployments"
    >
      <div className={classes.contentContainer}>
        <div className={classes.statusesCount}>
          <Box
            display="flex"
            flexWrap="wrap"
            className={classes.counterContainer}
          >
            {renderStatusesCount()}
          </Box>
          {isDeploymentsLoading ? (
            <StatusesCountSkeleton />
          ) : (
            <Typography className={classes.count}>
              /{deployments?.length || 0}
            </Typography>
          )}
        </div>
        <div className={classes.coloredStatusDistribution}>
          {deployments?.length ? (
            statusesOrder.map(status => {
              const statusValue = deploymentStatusesCount.data[status];

              return statusValue ? (
                <Box
                  flex={statusValue}
                  className={clsx(
                    classes.coloredStatusDistributionPart,
                    getClassForStatus(status),
                  )}
                  key={status}
                  data-testid={`status-distribution-part-${status}`}
                />
              ) : null;
            })
          ) : (
            <Box
              flex="1"
              className={clsx(
                classes.coloredStatusDistributionPart,
                getClassForStatus('Unknown'),
              )}
              data-testid="status-distribution-part-Unknown"
            />
          )}
        </div>
      </div>
    </OwnershipSection>
  );
};
