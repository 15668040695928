import { type IconComponent } from '@backstage/core-plugin-api';
import { type JsonValue } from '@backstage/types';
import { type Metric } from '@internal/backstage-plugin-adeo-core-components-react';
import React from 'react';

interface CreateMetricsOptions {
  Icon?: IconComponent;
  link?: string | null;
  tooltipContent?: string | React.ReactNode;
}

export const createMetrics = (
  value: string | number | JsonValue,
  label: string,
  { Icon, link, tooltipContent }: CreateMetricsOptions = {},
): Metric => {
  let valueString: string = '';
  if (typeof value === 'number') {
    valueString = value.toString();
  }
  if (typeof value === 'string') {
    valueString = value;
  }
  if (typeof value === 'object') {
    valueString = JSON.stringify(value);
  }
  return {
    value: valueString,
    label,
    link,
    Icon,
    tooltipContent,
  };
};
