import React from 'react';
import { makeStyles } from '@material-ui/core';
import { KafkaSecrets } from './KafkaSecrets';

const useKafkaTopicsDeploymentContentStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
  },
}));

export const KafkaTopicsDeploymentContent = () => {
  const classes = useKafkaTopicsDeploymentContentStyles();

  return (
    <div className={classes.root} data-testid="kafka-topic-drawer-content">
      <KafkaSecrets />
    </div>
  );
};
