import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container, debounce, makeStyles } from '@material-ui/core';
import { Header } from '../header/Header';
import { HomeIcon, Inventory2SearchIcon } from 'backstage-plugin-icons-react';
import {
  Breadcrumbs,
  BreadcrumbsItems,
} from '@internal/backstage-plugin-adeo-core-components-react';
import { usePackages } from './usePackages';

import { PackageDiscoveryContent } from './PackageDiscoveryContent';
import { useViewMode } from '../../hooks/useViewMode';
import { useSearchParams } from 'react-router-dom';
import { Entity } from '@backstage/catalog-model';
import ResourceSearchAndViewSwitcher from '../common/ResourceSearchAndViewSwitcher';

const usePackageDiscoveryStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    gap: theme.spacing(2),
    height: '100%',
    marginTop: theme.spacing(5),
  },
  entityPresentation: {
    position: 'relative',
  },
  actionButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
}));

export const PackageDiscovery = () => {
  const [viewMode, setViewMode] = useViewMode();

  const classes = usePackageDiscoveryStyles({ viewMode });

  const [titleFilter, setTitleFilter] = useState<string>('');
  const [filteredPackages, setFilteredPackages] = useState<Entity[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [hasReadUrl, setHasReadUrl] = useState(false);

  // https://github.com/remix-run/react-router/issues/9991#issuecomment-1550086638
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateSearchParams = useCallback(setSearchParams, []);

  const debouncedUrlUpdate = useMemo(
    () =>
      debounce((filter: string) => {
        updateSearchParams(new URLSearchParams({ query: filter, page: '1' }));
      }, 300),
    [updateSearchParams],
  );

  const { data: packages } = usePackages();

  useEffect(() => {
    if (!packages.length || hasReadUrl) return;

    const filter = searchParams.get('query') || '';

    if (filter) {
      setTitleFilter(filter);
    }

    setHasReadUrl(true);
  }, [hasReadUrl, searchParams, packages]);

  useEffect(() => {
    setFilteredPackages(
      packages?.filter(entity =>
        entity.metadata.title
          ?.toLowerCase()
          .includes(titleFilter.toLowerCase()),
      ),
    );
  }, [titleFilter, packages]);

  const onTitleFilterChange = (filter: string) => {
    setTitleFilter(filter);
    debouncedUrlUpdate(filter);
  };

  return (
    <>
      <Header withBottomBorder>
        <Breadcrumbs>
          <BreadcrumbsItems label="Home" to="/" Icon={HomeIcon} isCompact />
          <BreadcrumbsItems
            label="Package discovery"
            Icon={Inventory2SearchIcon}
            isLast
          />
        </Breadcrumbs>
      </Header>

      <Container maxWidth="lg" className={classes.root}>
        <ResourceSearchAndViewSwitcher
          resourceType="package"
          searchQuery={titleFilter}
          setSearchQuery={onTitleFilterChange}
          viewMode={viewMode}
          setViewMode={setViewMode}
        />

        <PackageDiscoveryContent
          packages={filteredPackages}
          viewMode={viewMode}
        />
      </Container>
    </>
  );
};
