import React from 'react';
import { type GridColDef } from '@mui/x-data-grid';
import { Table } from '@internal/backstage-plugin-adeo-core-components-react';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';

const useProductVersionsTableSkeletonStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
}));

export const ProductVersionsTableSkeleton = () => {
  const classes = useProductVersionsTableSkeletonStyles();
  const columns: GridColDef[] = [
    {
      field: 'version',
      headerName: 'Product version',
      flex: 3,
      renderCell: () => <Skeleton variant="text" />,
    },
    {
      field: 'components',
      headerName: 'Components',
      flex: 1,
      renderCell: () => <Skeleton variant="text" />,
    },
    {
      field: 'resources',
      headerName: 'Resources',
      flex: 1,
      renderCell: () => <Skeleton variant="text" />,
    },
    {
      field: 'deployments',
      headerName: 'Deployments',
      flex: 2,
      renderCell: () => <Skeleton variant="text" />,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      headerAlign: 'right',
      align: 'right',
      type: 'actions',
      getActions: params => [
        <Skeleton
          key={params.id}
          variant="circle"
          width="32px"
          height="32px"
        />,
      ],
    },
  ];
  return (
    <div className={classes.root}>
      <Table
        columns={columns}
        rows={[{ id: '1' }, { id: '2' }]}
        rowHeight={70}
        autoHeight
        disableColumnMenu
        disableRowSelectionOnClick
      />
    </div>
  );
};
