import React, { useState, MouseEvent } from 'react';

import {
  Popper,
  Zoom,
  Typography,
  Chip,
  Button,
  makeStyles,
} from '@material-ui/core';
import { HelpIcon } from 'backstage-plugin-icons-react';
import { useNavigate } from 'react-router-dom';

type ManualActionsPopupProps = {
  rows: {
    title: string;
    project: string;
    manualActions: number;
    route: string;
  }[];
};

const useManualActionsPopupStyles = makeStyles(theme => ({
  popup: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
  },

  popupItem: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(6),
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  chip: {
    margin: theme.spacing(0),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  bold: {
    fontWeight: 700,
  },

  underline: {
    textDecoration: 'underline',
  },
  manualAction: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
}));

export const ManualActionsPopup = (props: ManualActionsPopupProps) => {
  const { rows } = props;
  const classes = useManualActionsPopupStyles();
  const navigate = useNavigate();

  const redirectToManualActions = (route: string) => () => {
    navigate(route);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      role="button"
      className={classes.manualAction}
      aria-owns={open ? 'mouse-over-popover' : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      tabIndex={0}
    >
      <Typography className={classes.underline} variant="body2" color="error">
        <Typography className={classes.bold} component="span" variant="body2">
          {rows.length}
        </Typography>{' '}
        deployment require{' '}
        <Typography className={classes.bold} component="span" variant="body2">
          manual actions
        </Typography>
      </Typography>
      <HelpIcon fontSize="small" color="error" />
      <Popper
        id="manual-actions-popover"
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
      >
        {({ TransitionProps }) => (
          <Zoom {...TransitionProps} timeout={150}>
            <div className={classes.popup}>
              {rows.map(row => (
                <div
                  key={`${row.title}-${row.project}`}
                  className={classes.popupItem}
                >
                  <div>
                    <div className={classes.row}>
                      <Typography variant="body2" className={classes.bold}>
                        {row.title}
                      </Typography>

                      <Chip
                        className={classes.chip}
                        key={row.project}
                        label={row.project}
                        color="primary"
                        variant="outlined"
                        size="small"
                      />
                    </div>
                    <Typography variant="body2" color="error">
                      <Typography
                        variant="body2"
                        component="span"
                        className={classes.bold}
                      >
                        {row.manualActions} manual actions
                      </Typography>{' '}
                      required
                    </Typography>
                  </div>
                  <Button
                    variant="text"
                    color="default"
                    size="small"
                    onClick={redirectToManualActions(row.route)}
                  >
                    View manual actions
                  </Button>
                </div>
              ))}
            </div>
          </Zoom>
        )}
      </Popper>
    </div>
  );
};
