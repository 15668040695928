import {
  DialogTitle as MuiDialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { Theme } from '@material-ui/core/styles';

const useDeployProductDialogTitleStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  subtitle: {
    color: theme.palette.text.secondary,
  },
  bold: {
    fontWeight: 'bold',
  },
}));

export interface DialogTitleProps {
  id: string;
  title: string;
  onClose: () => void;
}

export const DeployProductDialogTitle = ({
  title,
  onClose,
  ...other
}: DialogTitleProps) => {
  const classes = useDeployProductDialogTitleStyles();
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{title}</Typography>
      <Typography variant="body2">
        <span className={classes.subtitle}>
          On project
          <span className={classes.bold}> development</span>
        </span>
      </Typography>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
        data-testid="close-dialog"
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
};
